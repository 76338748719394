import React, { useState, useRef, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import { setAuthToken } from "../../../../../services/axios";
import { getCookie } from "../../../../../services/helpers";
import { Toast } from "primereact/toast";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useUser } from "../../../../../zustand/common";
import { LoadingOverlayContext } from "../../../../../components/LoadingOverlay";

const LoginSupermarket = ({
  isOpen,
  onClose,
  setUserAutomatikisht,
  perdorues,
}) => {
  const { user, login } = useUser();
  const nipt = user.nipt;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);

  const toast = useRef(null);
  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      position: "top-center",
      detail: options.detail || "",
      life: options.life || 1000,
      closable: options.closable || false,
      ...options,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      showLoading();
      const res = await login({ username, nipt, password });

      if (res.success) {
        setAuthToken(getCookie("access_token"));
        const { automatikisht } = res;
        setUserAutomatikisht(automatikisht);
        onClose();
      } else {
        showToast("Te dhenat jane gabim");
      }
    } catch (error) {
      console.error("Login failed:", error);
      showToast("Te dhenat gabim");
    } finally {
      setPassword("");
      hideLoading();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle
        style={{
          height: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2>Logohu si user tjeter</h2>

        <DialogActions>
          <Button variant="outlined" onClick={onClose} style={{ color: "red" }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            margin="normal"
            fullWidth
            id="outlined-select-currency"
            select
            label="Select User"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            helperText="Ju lutem zgjidhni userin"
            autoComplete="username"
          >
            {perdorues.map((option) => (
              <MenuItem key={option.Id} value={option.Operator}>
                {option.Operator}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            margin="normal"
            required
            fullWidth
            type={showPassword ? "text" : "password"}
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            autoFocus
            helperText="Ju lutem vendosni passwordin"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{
              width: "50%",
              margin: "20px auto",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            Login
          </Button>
        </form>
      </DialogContent>

      <Toast ref={toast} />
    </Dialog>
  );
};

export default LoginSupermarket;
