import React from "react";
import "./print80Skontrino.scss";

function SkontrinoSupermarket({
  state,
  nipt,
  posSupermarketListaFaturave,
  menyra,
  operator,
  konfigurimeRedux,
}) {
  // Ensure konfigurimeRedux is not undefined or null
  const konfigurimeDescription = konfigurimeRedux
    ? konfigurimeRedux.Pershkrim
    : "";
  // Find the Menyra Pageses entry by ID and return its description
  let pagesaPershkrim = "";
  if (menyra) {
    const menyraPershkrim = menyra.find(
      (menyraP) => menyraP.Id === state.Menyra_Pageses_Id
    );
    pagesaPershkrim = menyraPershkrim ? menyraPershkrim.Pershkrim : "";
  }

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "";
    return dateTimeString.replace("T", " ");
  };

  return (
    <table className="hide-border">
      <thead>
        <tr className="flex " style={{ flexDirection: "column" }}>
          <td className="title1" colSpan={4}>
            <h1>Fature Porosi</h1>
          </td>
          {/* <td className="tds-footer" colSpan={4}>
            Ndermarrje: {selectedUserData && selectedUserData.Ndermarrje_Sel}
          </td> */}

          {/* <td className="tds-footer" colSpan={4}>
            {posSupermarketListaFaturave ? nipt : state.Nipt}
          </td> */}

          <td className="tds-footer" colSpan={4}>
            {konfigurimeDescription}
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>
          </td>
        </tr>
        <tr>
          <td className="columnsSkontrino"> Fatura: {state.Kodi}</td>
        </tr>
        <tr>
          <td className="columnsSkontrino">
            Data dhe Ora :{" "}
            {formatDateTime(
              state.UPDATED ||
                state.Updated ||
                state.Hapur ||
                state.DATA ||
                state.Data
                ? (
                    state.UPDATED ||
                    state.Updated ||
                    state.Hapur ||
                    state.DATA ||
                    state.Data
                  ).substring(0, 19) // Extract the time part (after T) with 6 numbers
                : null
            )}
          </td>
        </tr>
        <tr>
          <td className="columnsSkontrino"> Operator: {operator}</td>
        </tr>
        {state.Tavolina && (
          <tr>
            <td className="columnsSkontrino">Tavolina: {state.Tavolina}</td>
          </tr>
        )}
        <tr>
          <td className="columnsSkontrino">
            Menyra e Pageses: {pagesaPershkrim}
          </td>
        </tr>
      </thead>
      <hr />
      <tbody>
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default SkontrinoSupermarket;
