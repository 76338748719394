export const formatDate = (dateString, addTime = false) => {
  let date;

  if (dateString instanceof Date) {
    date = dateString;
  } else if (typeof dateString === "string") {
    date = new Date(dateString);

    if (isNaN(date.getTime())) {
      date = new Date();
    }
  } else {
    date = new Date();
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  if (addTime) {
    return `${formattedDate}T00:00:00`;
  }

  return formattedDate;
};
