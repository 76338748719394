import mainAxios from "../../../../services/axios"; // make sure to import your axios instance

const handleKlientiType = async (
  event,
  value,
  state,
  setSearchString,
  klientiRedux,
  setState
) => {
  setSearchString(value ? value.Kodi : "");
  if (value && value.Kodi) {
    try {
      const selectedKlienti = klientiRedux.find(
        (item) => item.Kodi === value.Kodi
      );

      if (selectedKlienti) {
        setState((state) => ({
          ...state,
          KLFU_Kodi: selectedKlienti.Kodi,
          KLFU_Pershkrim: selectedKlienti.Pershkrim,
          NIPT: selectedKlienti.Nipt,
          Qyteti_Kodi: selectedKlienti.Qyteti_Kodi,
        }));
        const newState = {
          KLFU_Kodi: selectedKlienti.Kodi,
          KLFU_Pershkrim: selectedKlienti.Pershkrim,
          NIPT: selectedKlienti.Nipt,
          Qyteti_Kodi: selectedKlienti.Qyteti_Kodi,
        };

        // Send PUT request after updating state
        mainAxios
          .put(`/fature/shitje/${state.Id}`, newState)
          .catch((error) => console.error("Error during PUT request:", error));

        return newState;
      } else {
        const newState = {
          KLFU_Kodi: value,
          KLFU_Pershkrim: "",
          NIPT: "",
          Qyteti_Kodi: "",
        };
        setState((state) => ({
          ...state,
          KLFU_Kodi: value,
          KLFU_Pershkrim: "",
          NIPT: "",
          Qyteti_Kodi: "",
        }));

        mainAxios
          .put(`/fature/shitje/${state.Id}`, newState)
          .catch((error) => console.error("Error during PUT request:", error));

        return newState;
      }
    } catch (err) {
      console.error("Error selecting klient:", err);
    }
  } else {
    const newState = {
      KLFU_Kodi: "",
      KLFU_Pershkrim: "",
      NIPT: "",
      Qyteti_Kodi: "",
    };

    setState((state) => ({
      ...state,
      KLFU_Kodi: "",
      KLFU_Pershkrim: "",
      NIPT: "",
      Qyteti_Kodi: "",
    }));

    mainAxios
      .put(`/fature/shitje/${state.Id}`, newState)
      .catch((error) => console.error("Error during PUT request:", error));

    return newState;
  }
};

export { handleKlientiType };
