import React from "react";
import Form from "react-bootstrap/Form";
import Magazina from "../../minimodal/Magazina";
import Paper from "@mui/material/Paper";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { useSelector } from "react-redux";
import "./MainTab.scss";
const divStyle = {
  display: "inline-flex",
  alignItems: "center",
  padding: "0 14px",
  height: "40px",
  fontSize: "16px",
  lineHeight: "1.4375em",
  letterSpacing: "0.00938em",
  borderRadius: "4px",
  backgroundColor: "transparent",
  color: "rgba(0, 0, 0, 0.87)",
};

const disabledDivStyle = {
  ...divStyle,
  color: "rgba(0, 0, 0, 0.38)",
};
const MainTab = ({
  disabled,
  magazina,
  checkDisabled,
  state,
  handleChange,
  handleKursiType,
  includeArkaBanka,
  includeDateField,
  includeNrOrigjinesField,
  includeKursiField,
  includeMonedhaSelect,
  includeKodiField,
  includeVleraSelectArka,
  includeSerialNumerField,
  includeMagazina,
  includeTotal,
  formatDate,
  numMag,
  fatureShitje,
  fleteHyrje,
}) => {
  const monedhat = useSelector((state) => state.globalSlice.monedha);
  const banka = useSelector((state) => state.globalSlice.arkabanka);

  let filterOptions = null;
  if (includeMagazina) {
    filterOptions = createFilterOptions({
      matchFrom: "any",
      stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
    });
  }
  return (
    <div className="grid gap-2 grid-cols-2" style={{ height: "125px" }}>
      {includeArkaBanka && (
        /* Button Arke/Banke */
        <div className="drop_down_list">
          <Autocomplete
            disabled={disabled}
            id="combo-box-demo14"
            options={banka}
            getOptionLabel={(option) => option.Pershkrim && option.Kodi}
            value={
              banka.find((ArkaP) => ArkaP.Kodi === state?.Arka_Banka_Kodi) ||
              null
            }
            onChange={(_, newValue) => {
              handleChange("Arka_Banka_Kodi", newValue?.Kodi || "");
            }}
            sx={{ width: "100%" }}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Arke/Banke" />
            )}
            renderOption={(props, option) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <div style={{ textAlign: "start" }}>{option.Pershkrim}</div>
                <div style={{ textAlign: "end" }}>{option.Kodi}</div>
              </div>
            )}
            PaperComponent={(props) => (
              <Paper {...props} /> // Adjust the width as needed
            )}
          />
          {/* <div className=" hover:scale-110 transition-all">
            <Monedhat fetchMonedhat={fetchPost} />
          </div> */}
        </div>
      )}
      {includeDateField && (
        <TextField
          fullWidth
          disabled={disabled}
          type="date"
          label="Date"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={formatDate(state?.Data)}
          onChange={(e) => handleChange("Data", e.target.value)}
          size="small"
        />
      )}
      {fatureShitje && (
        <TextField
          disabled={disabled}
          value={state?.Kodi_Org || ""}
          type="number"
          variant="outlined"
          label="Nr Origjines"
          onChange={(e) => {
            handleChange("Kodi_Org", e.target.value);
          }}
          size="small"
          className="relative"
        />
      )}
      {includeNrOrigjinesField && (
        <TextField
          disabled={disabled}
          value={state?.Kodi_ORG || ""}
          type="number"
          variant="outlined"
          label="Nr Origjines"
          onChange={(e) => {
            handleChange("Kodi_ORG", e.target.value);
          }}
          size="small"
          className="relative"
        />
      )}
      {includeMonedhaSelect && (
        <div className="drop_down_list">
          <Form.Select
            style={{ backgroundColor: "#fff" }}
            disabled={disabled}
            value={state?.Mon || ""}
            onChange={(e) => {
              handleKursiType(e);
            }}
          >
            <option label="Monedha"></option>
            {monedhat.map((monedha) => {
              return (
                <option key={monedha.Kodi} value={monedha.Kodi}>
                  {monedha.Pershkrim}
                </option>
              );
            })}
          </Form.Select>
        </div>
      )}
      {includeKursiField && (
        <TextField
          disabled={disabled || state?.Mon === "ALL"}
          type="number"
          label="Kursi"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: { min: 0 },
          }}
          value={state?.Kursi || ""}
          onChange={(e) =>
            e.target.value < 0
              ? (e.target.value = 0)
              : handleChange("Kursi", e.target.value)
          }
          variant="outlined"
          size="small"
          className="relative"
        />
      )}
      {includeTotal && (
        <TextField
          disabled={disabled}
          value={state.Kursi * state.Monedha}
          type="number"
          label="Total"
          variant="outlined"
          onChange={(e) => handleChange("Total", e.target.value)}
          size="small"
        />
      )}

      {includeKodiField && (
        <TextField
          disabled={disabled}
          value={state?.Kodi || ""}
          type="number"
          label="Nr"
          variant="outlined"
          onChange={(e) => handleChange("Kodi", e.target.value)}
          size="small"
        />
      )}
      {includeMagazina && (
        <div className="drop_down_list">
          {/* //////////// */}
          <Autocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-demo13"
            options={magazina}
            filterOptions={filterOptions}
            getOptionLabel={(option) => (option ? option.Kodi : "")}
            onChange={(e, newValue) =>
              handleChange("Magazina_Kodi", newValue ? newValue.Kodi : "")
            }
            value={
              magazina.find((qytet) => qytet.Kodi === state?.Magazina_Kodi) ||
              null
            }
            size="small"
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Magazina" />}
            renderOption={(props, option) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
              </div>
            )}
          />
          {/* ///////// MiniModal */}
          <div className="hover:scale-110 transition-all">
            <Magazina numMag={numMag} magazina={magazina} />
          </div>
        </div>
      )}
      {fleteHyrje && <div style={disabled ? disabledDivStyle : divStyle}></div>}
      {includeVleraSelectArka && (
        <div className="drop_down_list">
          <TextField
            disabled={disabled}
            value={state.Vlera || ""}
            type="number"
            label="Vlera"
            variant="outlined"
            onChange={(e) => handleChange("Vlera", e.target.value)}
            size="small"
          />
        </div>
      )}
      {includeSerialNumerField && (
        <TextField
          disabled={disabled}
          value={state?.NrSerik || ""}
          type="number"
          label="Serial numer"
          onChange={(e) => handleChange("NrSerik", e.target.value)}
          variant="outlined"
          size="small"
        />
      )}
      {fleteHyrje && <div></div>}
    </div>
  );
};

export default MainTab;
