import mainAxios from "../../../services/axios";
import { formatDate } from "./formatDate";

// Kur Klikohet butoni i "SHTIM"
const handleAdd = async (
  showLoading,
  state,
  username,
  setState,
  defaultState,
  setDisabled,
  setButtonClicked,
  setSearchString,
  showToast,
  rows,
  tipiDTL,
  tipiArkes,
  fetchFShitje,
  setCurrentAction,
  toast,
  hideLoading
) => {
  const getItemsForSecondRequest = (rows, newEntryID) => {
    return rows.map((row) => {
      let item = {
        Master_Id: newEntryID,
        Pershkrim: row.Pershkrim,
        Vlera: row.Vlera,
        Sasia: row.Sasia,
        Tipi: row.Tipi,
        SkontoAgjent: row.SkontoAgjent,
        Cmimi: row.Cmimi,
        Cmimi_Pa_Tvsh: row.Cmimi_Pa_Tvsh,
        Operator: row.Operator,
        Vlera_Pa_Tvsh: row.Vlera_Pa_Tvsh,
        Kodi: row.Kodi,
        KLFU_Kodi: row.KLFU_Kodi,
        Tvsh: row.Tvsh,
        DateGarancie: row.DateGarancie,
        Rimbursim_Cmimi: row.Rimbursim_Cmimi,
        NrLL: row.NrLL,
        Shenim2: row.Shenim2,
        IdOld: row.IdOld,
        Skonto_VleraAgjent: row.Skonto_VleraAgjent,
        Art_ID: row.Art_ID,
        Punonjes_Id: row.Punonjes_Id,
        BarKod: row.BarKod,
        Njesi_Kodi: row.Njesi_Kodi,
        Skonto_Vlera: row.Skonto_Vlera,
        Total: row.Total,
        Magazina_Kodi: row.Magazina_Kodi,
        TipVlere_ID: row.TipVlere_ID,
        Tvsh_Vlera: row.Tvsh_Vlera,
        NrSerik: row.NrSerik,
        SasiaPak: row.SasiaPak,
        Cmimi_Kosto: row.Cmimi_Kosto,
        Skonto: row.Skonto,
      };
      return item;
    });
  };
  try {
    showLoading();

    // if (!isOnline) {
    //   storeOfflineRequest({
    //     url: "/fature/shitje",
    //     body: {
    //       ...state,
    //       Data: formatDate(state.Data, true),
    //       Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
    //       Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
    //       Flete_Dalje_KODI: Number(state.Kodi),
    //       Menyra_Pageses_ID: Number(state.Menyra_Pageses_ID),
    //       Kodi: Number(state.Kodi),
    //       Eksport: Number(state.Eksport),
    //       Afati_PagesesDite: Number(state.Afati_PagesesDite),
    //       Kursi: Number(state.Kursi),
    //       Paguar: Number(state.Paguar),
    //       Arka_Banka_Kodi: String(state.Arka_Banka_Kodi),
    //       Paguar_ne: String(state.Menyra_Pageses_ID),
    //       Operator: String(username),
    //       fisPaymentMethodTypeID:
    //         state.fisPaymentMethodTypeID === null
    //           ? null
    //           : Number(state.fisPaymentMethodTypeID),
    //       FisInvoiceType: Number(state.FisInvoiceType),
    //       fisIsEinvoice: Number(state.fisIsEinvoice),
    //       fisProcessKodi: state.fisProcessKodi,
    //       fisIsCorrectiveInvoice: Number(state.fisIsCorrectiveInvoice),
    // subsequentDelivery: Number(state.subsequentDelivery),
    //       fisCorrectiveInvTypeID:
    // state.fisCorrectiveInvTypeID === null
    // ? null
    // : Number(state.fisCorrectiveInvTypeID),
    //       fisIsReverseCharge: Number(state.fisIsReverseCharge),
    //       fisTypeOfSelfIssID:
    //         state.fisTypeOfSelfIssID === null
    //           ? null
    //           : Number(state.fisTypeOfSelfIssID),

    //       subsequentDeliveryType:
    //         state.subsequentDeliveryType === null
    //           ? null
    //           : Number(state.subsequentDeliveryType),
    //     },
    //     rows: rows.map((row) => ({
    //       Pershkrim: row.Pershkrim,
    //       Vlera: row.Vlera,
    //       Sasia: row.Sasia,
    //       Tipi: row.Tipi,
    //       SkontoAgjent: row.SkontoAgjent,
    //       Cmimi: row.Cmimi,
    //       Cmimi_Pa_Tvsh: row.Cmimi_Pa_Tvsh,
    //       Operator: row.Operator,
    //       Vlera_Pa_Tvsh: row.Vlera_Pa_Tvsh,
    //       Kodi: row.Kodi,
    //       KLFU_Kodi: row.KLFU_Kodi,
    //       Tvsh: row.Tvsh,
    //       DateGarancie: row.DateGarancie,
    //       Rimbursim_Cmimi: row.Rimbursim_Cmimi,
    //       NrLL: row.NrLL,
    //       Shenim2: row.Shenim2,
    //       IdOld: row.IdOld,
    //       Skonto_VleraAgjent: row.Skonto_VleraAgjent,
    //       Art_ID: row.Art_ID,
    //       Punonjes_Id: row.Punonjes_Id,
    //       BarKod: row.BarKod,
    //       Njesi_Kodi: row.Njesi_Kodi,
    //       Skonto_Vlera: row.Skonto_Vlera,
    //       Total: row.Total,
    //       Magazina_Kodi: row.Magazina_Kodi,
    //       TipVlere_ID: row.TipVlere_ID,
    //       Tvsh_Vlera: row.Tvsh_Vlera,
    //       NrSerik: row.NrSerik,
    //       SasiaPak: row.SasiaPak,
    //       Cmimi_Kosto: row.Cmimi_Kosto,
    //       Skonto: row.Skonto,
    //     })),
    //   });
    //   // Inform the user that the request will be sent when online
    //   showToast("Request will be sent when online.");
    //   setState(defaultState);
    //   setRows([]);
    //   setDisabled(true);
    //   setButtonClicked(false);
    //   return;
    // }
    //  POST request i 1 per FATURE SHITJE
    const response = await mainAxios.post(`/fature/shitje`, {
      ...state,
      Kodi_Org: state.Kodi_Org,
      Tip_Cmimi: state.Tip_Cmimi,
      Flete_Dalje_KODI: Number(state.Kodi),
      Menyra_Pageses_ID: Number(state.Menyra_Pageses_ID),
      NrSerik: state.NrSerik,
      Veprimi: state.Veprimi,
      // Klasifikim4_ID: Number(state.Klasifikim4_ID),
      Shenim: state.Shenim,
      Kodi: Number(state.Kodi),
      Eksport: Number(state.Eksport),
      Magazina_Kodi: state.Magazina_Kodi,
      NIPT: state.NIPT,
      Afati_PagesesDite: Number(state.Afati_PagesesDite),
      // Klasifikim1_ID: Number(state.Klasifikim1_ID),
      Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
      Data: formatDate(state.Data, true),
      Mon: state.Mon,
      Kursi: Number(state.Kursi),
      Dep_Kodi: state.Dep_Kodi,
      KLFU_Kodi: state.KLFU_Kodi,
      Paguar: Number(state.Paguar),
      Arka_Banka_Kodi: String(state.Arka_Banka_Kodi),
      Paguar_ne: String(state.Menyra_Pageses_ID),
      Operator: String(username),
      // Klasifikim2_ID: Number(state.Klasifikim2_ID),
      Qyteti_Kodi: state.Qyteti_Kodi,
      // Klasifikim3_ID: Number(state.Klasifikim3_ID),
      Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
      KLFU_Pershkrim: state.KLFU_Pershkrim,
      Transportuesi_Nipt: state.Transportuesi_Nipt,
      Targa_e_Mjetit: state.Targa_e_Mjetit,
      Transportuesi_Adresa: state.Transportuesi_Adresa,
      Transportuesi_Pershkrim: state.Transportuesi_Pershkrim,
      Transport_Shenim: state.Transport_Shenim,
      Transport_Data: formatDate(state.Transport_Data, true),
      fisDocumentTypeKodi: state.fisDocumentTypeKodi,
      fisPaymentMethodTypeID:
        state.fisPaymentMethodTypeID === null
          ? null
          : Number(state.fisPaymentMethodTypeID),
      FisInvoiceType: Number(state.FisInvoiceType),
      fisIsEinvoice: Number(state.fisIsEinvoice),
      fisProcessKodi: state.fisProcessKodi,
      fisIsCorrectiveInvoice: Number(state.fisIsCorrectiveInvoice),
      subsequentDelivery: Number(state.subsequentDelivery),
      fisCorrectiveInvTypeID:
        state.fisCorrectiveInvTypeID === null
          ? null
          : Number(state.fisCorrectiveInvTypeID),
      fisIsReverseCharge: Number(state.fisIsReverseCharge),
      fisTypeOfSelfIssID:
        state.fisTypeOfSelfIssID === null
          ? null
          : Number(state.fisTypeOfSelfIssID),

      subsequentDeliveryType:
        state.subsequentDeliveryType === null
          ? null
          : Number(state.subsequentDeliveryType),
    });
    if (response.status === 200) {
      setState(defaultState);
      setDisabled(true);
      setButtonClicked(false);
      setSearchString("");

      const newEntryID = response.data.Id;

      showToast("Fatura u rregjistrua.", { severity: "success" });

      // POST request i 2 per DTL
      const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
      const responseDtl = await mainAxios.post(
        `/fature/shitje/dtl/bulk`,
        dtlDataArray
      );

      if (responseDtl.status === 200 && tipiDTL === "AR") {
        // POST request i 3 per gjenerimin e Flete Daljes
        const responseFD = await mainAxios.post(
          `/fature/shitje/f_insert/${newEntryID}`,
          {}
        );
        if (responseFD.status === 200) {
          if (state.Arka_Banka_Kodi) {
            if (tipiArkes === "ARKA") {
              await mainAxios.post(`/ftsh/mandat/${newEntryID}`);
            } else if (tipiArkes === "BANKA") {
              await mainAxios.post(`/ftsh/mandat/banka/${newEntryID}`);
            }
          }

          fetchFShitje(1);
          setCurrentAction(null);
          showToast("Flete Dalja u gjenerua.", { severity: "success" });
        } else {
          showToast("Nuk u gjenerua flete dalje.", { severity: "error" });
        }
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Gabim gjatë regjistrimit të artikujve",
      });
    }
  } catch (error) {
    console.error("Error in POST request:", error);

    toast.current.show({
      severity: "error",
      summary: "Gabim gjatë regjistrimit të faturës.",
    });
  } finally {
    hideLoading();
  }
};
let hasArObject;

// Kur Klikohet butoni i "MODIFIKO"
const handleModify = async (
  showLoading,
  state,
  username,
  setState,
  defaultState,
  setDisabled,
  setButtonClicked,
  setSearchString,
  showToast,
  rows,
  tipiDTL,
  tipiArkes,
  fetchFShitje,
  setCurrentAction,
  toast,
  hideLoading,
  number,
  currentId
) => {
  const getItemsForSecondRequest = (rows, newEntryID) => {
    hasArObject = rows.some((row) => row.Tipi === "AR");
    return rows.map((row) => {
      let item = {
        Master_Id: newEntryID,
        Id: row.Id,
        Pershkrim: row.Pershkrim,
        Vlera: row.Vlera,
        Sasia: row.Sasia,
        Tipi: row.Tipi,
        SkontoAgjent: row.SkontoAgjent,
        Cmimi: row.Cmimi,
        Operator: row.Operator,
        Vlera_Pa_Tvsh: row.Vlera_Pa_Tvsh,
        Kodi: row.Kodi,
        KLFU_Kodi: row.KLFU_Kodi,
        Tvsh: row.Tvsh,
        DateGarancie: row.DateGarancie,
        Rimbursim_Cmimi: row.Rimbursim_Cmimi,
        NrLL: row.NrLL,
        Shenim2: row.Shenim2,
        IdOld: row.IdOld,
        Skonto_VleraAgjent: row.Skonto_VleraAgjent,
        Art_ID: row.Art_ID,
        Punonjes_Id: row.Punonjes_Id,
        BarKod: row.BarKod,
        Njesi_Kodi: row.Njesi_Kodi,
        Skonto_Vlera: row.Skonto_Vlera,
        Cmimi_Pa_Tvsh: row.Cmimi_Pa_Tvsh,
        Total: row.Total,
        Magazina_Kodi: row.Magazina_Kodi,
        TipVlere_ID: row.TipVlere_ID,
        Tvsh_Vlera: row.Tvsh_Vlera,
        NrSerik: row.NrSerik,
        SasiaPak: row.SasiaPak,
        Cmimi_Kosto: row.Cmimi_Kosto,
        Skonto: row.Skonto,
      };
      return item;
    });
  };
  try {
    showLoading();
    // Requesti i 1 PUT per Faturen e shitjes
    const response = await mainAxios.put(`/fature/shitje/${currentId}`, {
      // ...state,
      Kodi_Org: state.Kodi_Org,
      Tip_Cmimi: state.Tip_Cmimi,
      Flete_Dalje_KODI: Number(state.Kodi),
      Menyra_Pageses_ID: Number(state.Menyra_Pageses_ID),
      NrSerik: state.NrSerik,
      Veprimi: state.Veprimi,
      // Klasifikim4_ID: Number(state.Klasifikim4_ID),
      Shenim: state.Shenim,
      Kodi: Number(state.Kodi),
      Eksport: Number(state.Eksport),
      Magazina_Kodi: state.Magazina_Kodi,
      NIPT: state.NIPT,
      Afati_PagesesDite: Number(state.Afati_PagesesDite),
      // Klasifikim1_ID: Number(state.Klasifikim1_ID),
      Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
      Data: formatDate(state.Data, true),
      Mon: state.Mon,
      Kursi: Number(state.Kursi),
      Dep_Kodi: state.Dep_Kodi,
      KLFU_Kodi: state.KLFU_Kodi,
      Paguar: Number(state.Paguar),
      Arka_Banka_Kodi: String(state.Arka_Banka_Kodi),
      Paguar_ne: String(state.Menyra_Pageses_ID),
      Operator: String(username),
      // Klasifikim2_ID: Number(state.Klasifikim2_ID),
      Qyteti_Kodi: state.Qyteti_Kodi,
      // Klasifikim3_ID: Number(state.Klasifikim3_ID),
      Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
      KLFU_Pershkrim: state.KLFU_Pershkrim,
      Transportuesi_Nipt: state.Transportuesi_Nipt,
      Targa_e_Mjetit: state.Targa_e_Mjetit,
      Transportuesi_Adresa: state.Transportuesi_Adresa,
      Transportuesi_Pershkrim: state.Transportuesi_Pershkrim,
      Transport_Shenim: state.Transport_Shenim,
      Transport_Data: formatDate(state.Transport_Data, true),
      fisDocumentTypeKodi: state.fisDocumentTypeKodi,
      fisPaymentMethodTypeID:
        state.fisPaymentMethodTypeID === null
          ? null
          : Number(state.fisPaymentMethodTypeID),
      FisInvoiceType: Number(state.FisInvoiceType),
      fisIsEinvoice: Number(state.fisIsEinvoice),
      fisProcessKodi: state.fisProcessKodi,
      fisTypeOfSelfIssID:
        state.fisTypeOfSelfIssID === null
          ? null
          : Number(state.fisTypeOfSelfIssID),
      fisIsCorrectiveInvoice: Number(state.fisIsCorrectiveInvoice),
      subsequentDelivery: Number(state.subsequentDelivery),
      fisCorrectiveInvTypeID:
        state.fisCorrectiveInvTypeID === null
          ? null
          : Number(state.fisCorrectiveInvTypeID),
      subsequentDeliveryType:
        state.subsequentDeliveryType === null
          ? null
          : Number(state.subsequentDeliveryType),
      fisIsReverseCharge: Number(state.fisIsReverseCharge),
    });
    if (response.status === 200) {
      setState(defaultState);
      setDisabled(true);
      setButtonClicked(false);
      setSearchString("");

      const newEntryID = response.data.Id;

      showToast("Fatura u modifikua.", {
        severity: "success",
      });

      //  PUT request i 2 per modifikimin e DTL
      const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
      const responseDtl = await mainAxios.put(
        `/fature/shitje/dtl/bulk`,
        dtlDataArray
      );

      if (responseDtl.status === 200 && hasArObject) {
        // PUT request i 3 per gjenerimin e Flete Daljes
        const responseFD = await mainAxios.post(
          `/fature/shitje/f_insert/${newEntryID}`,
          {}
        );
        if (responseFD.status === 200) {
          if (state.Arka_Banka_Kodi) {
            if (tipiArkes === "ARKA") {
              await mainAxios.post(`/ftsh/mandat/${newEntryID}`);
            } else if (tipiArkes === "BANKA") {
              await mainAxios.post(`/ftsh/mandat/banka/${newEntryID}`);
            }
          }

          fetchFShitje(number);
          setCurrentAction(null);
          showToast("Flete Dalja e re u gjenerua.", {
            severity: "success",
          });
        } else {
          showToast("Nuk u gjenerua flete dalje.", {
            severity: "error",
          });
        }
      }
    }
  } catch (error) {
    console.error("Error in POST request:", error);
    showToast("Gabim gjatë modifikimit të faturës.", {
      severity: "error",
    });
  } finally {
    hideLoading();
  }
};

export { handleAdd, handleModify };
