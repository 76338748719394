import React, { useState, useRef, useEffect } from "react";
import { Button, ButtonGroup } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import mainAxios from "../../services/axios";
import "./Artikuj.scss";
import { getArtikujPag } from "../../store/globalSlice";
import { useDispatch } from "react-redux";
import { Toast } from "primereact";
import { useUser } from "../../zustand/common";
import ArticleHandler from "./functions/ArtikujHandler";
import DeleteConfirmationArtikuj from "../fature-blerje/minimodal/DeleteArtikuj";

const PaneliTabeve = ({
  incrementKodi,
  defaultState,
  onAddClick,
  setDisabled,
  setCheckModify,
  state,
  setState,
  setButtonClickedModifiko,
  setButtonClicked,
  buttonClicked,
  isModifying,
  isMobile,
  disabledBtnDelete,
  anulloFature,
  disabled,
  checkModify,
  setHidePupUp,
  setCurrentAction,
  currentPage,
  currentId,
  numPerPage,
  currentAction,
  handleRowClick,
  showLoading,
  hideLoading,
}) => {
  const { user } = useUser();
  const nipt = user.nipt;
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const cancelDelete = () => {
    setShowConfirmationPopup(false);
  };

  const deleteFature = async (Id) => {
    try {
      const response = await mainAxios.delete(`/art/${state.Id}`);
      if (response.status === 200) {
        try {
          setShowConfirmationPopup(false);

          dispatch(
            getArtikujPag(
              `/artikull/pag?page=${currentPage}&&per_page=${numPerPage}`
            )
          );

          toast.current.show({
            severity: "info",
            summary: "Rreshti u fshi me sukses.",
          });
        } catch (error) {
          console.error(error);
          toast.current.show({
            severity: "error",
            summary: "Rreshti nuk mund te fshihet.",
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk mund te fshihet.",
      });
    }
  };

  const confirmDelete = async () => {
    await deleteFature(currentId);
  };

  const handleDeleteClick = async () => {
    try {
      const response = await mainAxios.get(
        `/fshirje/check?db_name=${nipt}&table_name=ART&kodi=${state.Kodi}`
      );

      if (response.data.Status !== 0) {
        toast.current.show({
          severity: "warn",
          summary: "Ka te dhena nuk mund te fshihet",
        });
      } else {
        setShowConfirmationPopup(true);
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };

  const handleRegister = () => {
    if (state.Kodi === "" || state.Kodi === null) {
      toast.current.show({
        severity: "info",
        summary: "Shtoni Kodin ose anulloje.",
      });

      return;
    }
    if (state.Pershkrim === "" || state.Pershkrim === null) {
      toast.current.show({
        severity: "info",
        summary: "Shtoni pershkrimin ose anulloje.",
      });

      return;
    }

    if (currentAction === "add") {
      handleAdd();
    }
  };

  //thirrja e userit aktual
  const [username, setUsername] = useState("");

  useEffect(() => {
    const storedData = localStorage.getItem("loginData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUsername(parsedData.username);
    }
  }, []);

  const { handleAdd } = ArticleHandler({
    state,
    handleRowClick,
    setDisabled,
    setButtonClicked,
    setButtonClickedModifiko,
    currentPage,
    numPerPage,
    username,
    showLoading,
    hideLoading,
  });

  return (
    <>
      <Toast ref={toast} />
      {showConfirmationPopup && (
        <DeleteConfirmationArtikuj
          contexDelete={`artikullin ${state.Pershkrim}`}
          currentId={currentId}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          setDisabled={setDisabled}
          defaultState={defaultState}
          setState={setState}
          setButtonClicked={setButtonClicked}
        />
      )}

      <ButtonGroup className="mb-2 mt-1">
        <Button
          onClick={async (e) => {
            const updatedData = await incrementKodi();

            const updatedState = {
              ...defaultState,
              Kodi: updatedData.Kodi,
              Art_Kls01_Kodi: updatedData.Art_Kls01_Kodi,
              Tarifa_Kodi: updatedData.Tarifa_Kodi,
              Skema_Kodi: updatedData.Skema_Kodi,
              Njesi_Kodi: updatedData.Njesi_Kodi,
              Tvsh: updatedData.Tvsh,
            };

            e.preventDefault();
            setDisabled(false);
            setCheckModify(false);
            setState(updatedState);
            setButtonClickedModifiko(true);
            // addRow();
            onAddClick();
            setButtonClicked(true);
          }}
          disabled={buttonClicked || isModifying}
        >
          <PostAddIcon />
          {isMobile ? null : "Shtim"}
        </Button>

        <Button
          className="p-1"
          disabled={disabledBtnDelete}
          onClick={(e) => {
            e.preventDefault();
            handleDeleteClick();
            // setButtonClicked(false);
          }}
        >
          <DeleteIcon />
          {isMobile ? null : "Fshije"}
        </Button>

        <Button
          onClick={(e) => {
            e.preventDefault();
            anulloFature();
          }}
          disabled={disabled || checkModify}
        >
          <ClearIcon />
          {isMobile ? null : "Anullim"}
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            setHidePupUp(false);
            setCurrentAction(null);
            setButtonClicked(false);
            setDisabled(true);
            // setState("");
            handleRegister();
          }}
          disabled={disabled || checkModify}
          type="submit"
        >
          <AppRegistrationIcon />
          {isMobile ? null : "Regjistro"}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default PaneliTabeve;
