// Retry utility function
export const retryRequest = async (axiosRequest, retries = 2, delay = 1000) => {
  let attempts = 0;

  while (attempts < retries) {
    try {
      // Attempt to make the request
      const response = await axiosRequest();
      return response;
    } catch (error) {
      // If error status is 400, retry
      if (error.response && error.response.status === 400) {
        attempts++;
        console.log(`Attempt ${attempts} failed. Retrying...`);
        if (attempts >= retries) {
          throw new Error("Max retries reached");
        }
        // Wait for the specified delay before retrying
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        // If error is not status 400, throw the error
        throw error;
      }
    }
  }
};
