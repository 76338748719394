import React, { useRef, useContext } from "react";
import {
  Button,
  TextField,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import mainAxios from "../../../services/axios";
import { Toast } from "primereact/toast";
import QRCode from "qrcode.react";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";

const label = { inputProps: { "aria-label": "Switch demo" } };
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#DDE6ED",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Fiskalizimi = ({
  disabled,
  currentId,
  state,
  pdfDisabled,
  fetchFShitje,
  fisVerificationUrl,
  setState,
}) => {
  const [referencat, setReferencat] = useState([]);
  const [correctType, setCorrectType] = useState([]);
  const [typeSelfIss, setTypeSelfIss] = useState([]);
  const [subDeliveryT, setSubDeliveryT] = useState([]);
  const [isSwitchOn1, setIsSwitchOn1] = useState(false);
  const [isSwitchOn2, setIsSwitchOn2] = useState(false);
  const [isSwitchOn4, setIsSwitchOn4] = useState(false);
  const [isSwitchOn5, setIsSwitchOn5] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  const handleChangeFisIsCorrectiveInvoice = (fieldName, value) => {
    if (value === "") {
      return value === null;
    }

    setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const procesiRedux = useSelector((state) => state.globalSlice.procesi);
  const proceset = procesiRedux;

  const docTypeRedux = useSelector((state) => state.globalSlice.docType);
  const documentType = docTypeRedux;

  const referencesAll = useSelector((state) => state.globalSlice.references);

  const fetchFisReferences = async () => {
    try {
      const filteredDataByWtnType = referencesAll.filter(
        (item) => item.Referenca === "wtnType"
      );
      const filteredDataByTransaction = referencesAll.filter(
        (item) => item.Referenca === "transaction"
      );
      const filteredDataByVehicleOwnershipType = referencesAll.filter(
        (item) => item.Referenca === "vehicleOwnershipType"
      );
      const filteredDataBystartPoint = referencesAll.filter(
        (item) => item.Referenca === "startPoint"
      );
      const filteredDataBydestinationPoint = referencesAll.filter(
        (item) => item.Referenca === "destinationPoint"
      );

      const filteredDataBysubsequentDeliveryType = referencesAll.filter(
        (item) => item.Referenca === "subsequentDeliveryType"
      );
      const filteredDataByPayMethod = referencesAll.filter(
        (item) => item.Referenca === "PayMethod"
      );
      const filteredDataByTypeOfSelfIss = referencesAll.filter(
        (item) => item.Referenca === "TypeOfSelfIss"
      );
      const filteredDataByCashDepositOperation = referencesAll.filter(
        (item) => item.Referenca === "CashDepositOperation"
      );
      const filteredDataByIDType = referencesAll.filter(
        (item) => item.Referenca === "IDType"
      );
      const filteredDataByCorrectiveInvType = referencesAll.filter(
        (item) => item.Referenca === "CorrectiveInvType"
      );

      const filteredData = {
        wtnType: filteredDataByWtnType,
        transaction: filteredDataByTransaction,
        vehicleOwnershipType: filteredDataByVehicleOwnershipType,
        startPoint: filteredDataBystartPoint,
        destinationPoint: filteredDataBydestinationPoint,
        subsequentDeliveryType: filteredDataBysubsequentDeliveryType,
        PayMethod: filteredDataByPayMethod,
        TypeOfSelfIss: filteredDataByTypeOfSelfIss,
        CashDepositOperation: filteredDataByCashDepositOperation,
        IDType: filteredDataByIDType,
        CorrectiveInvType: filteredDataByCorrectiveInvType,
      };

      setCorrectType(filteredData.CorrectiveInvType);
      setSubDeliveryT(filteredData.subsequentDeliveryType);
      setTypeSelfIss(filteredData.TypeOfSelfIss);
      setReferencat(filteredData.PayMethod);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchFisReferences();
  }, []);

  const Menyra = referencat;
  const VeteFaturimiInv = typeSelfIss;
  const Corrective = correctType;

  const subsequentDelivery = subDeliveryT;

  const toast = useRef(null);
  const fatureKorigjuese = async () => {
    try {
      const response = await mainAxios(`/fature/shitje/pftsh/${currentId}`);

      if (response.status === 200) {
        const firstItem = response.data;
        const invoiceNumber = firstItem["Id e fatures "];
        const responseFis = await mainAxios(
          `/fature/shitje/fis/${invoiceNumber}`
        );

        if (responseFis.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Fatura korigjuese u shtua dhe u fiskalizua !",
          });

          await fetchFShitje(1);
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Nuk mund te korigjohet.",
        });
      }
    } catch (error) {
      console.error("Error fetching new_kodi_fsh data:", error);
    }
  };

  // QR code to fis URL
  const doubleClick = async (fisVerificationUrl) => {
    try {
      if (fisVerificationUrl === null) {
        return;
      } else {
        window.open(fisVerificationUrl, "_blank");
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  // Gjenero Fature Korigjuese button
  const generateInvoiceButton = (
    <Button
      size="small"
      variant="contained"
      style={{ width: "100%", height: 50, marginLeft: 10 }}
      disabled={state.NIVF === "" || state.NIVF === null}
      onClick={fatureKorigjuese}
    >
      Gjenero Fature Korigjuese
    </Button>
  );

  const pdfFunciton = async () => {
    try {
      showLoading();
      const response = await mainAxios.get(`/getpdf/1432`, {
        responseType: "arraybuffer",
      });
      const data = response.data; // The binary data from the response

      if (response.status === 200) {
        // Create a Blob from the binary data
        const blob = new Blob([data], { type: "application/pdf" });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.download = "file.pdf"; // Name of the file to be downloaded

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);

        // Release the object URL
        URL.revokeObjectURL(url);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Nuk mund te shkarkoni PDF.",
        });
      }
    } catch (error) {
      console.error("Error fetching new_kodi_fsh data:", error);
    } finally {
      hideLoading();
    }
  };

  // PDF button
  const pdfButton = (
    <Button
      variant="outlined"
      color="error"
      disabled={state.EIC === "" || state.EIC === null}
      style={{ width: "100%", height: 50 }}
      onClick={pdfFunciton}
    >
      PDF
      <PictureAsPdfIcon fontSize="medium" />
    </Button>
  );

  const correctiveInvoiceSwitch = (
    <div className="Item" style={{ marginBottom: "10px" }}>
      <Switch
        {...label}
        disabled={disabled}
        checked={state.fisIsCorrectiveInvoice === true || isSwitchOn4}
        onChange={(e) => {
          const isChecked = e.target.checked;
          const newValue = isChecked ? true : false;
          handleChangeFisIsCorrectiveInvoice(
            "fisIsCorrectiveInvoice",
            newValue
          );
          setIsSwitchOn4(isChecked);
        }}
      />
      <Autocomplete
        disablePortal
        disabled={!isSwitchOn4}
        id="combo-box-demo17"
        sx={{ width: "100%" }}
        options={Corrective}
        filterOptions={filterOptions}
        getOptionLabel={(option) => (option ? option.Pershkrim : "")}
        onChange={(e, newValue) =>
          handleChangeFisIsCorrectiveInvoice(
            "fisCorrectiveInvTypeID",
            newValue ? newValue.Kodi : null
          )
        }
        value={
          state?.fisCorrectiveInvTypeID === null
            ? null
            : Corrective.find(
                (qytet) =>
                  Number(qytet.Kodi) === Number(state?.fisCorrectiveInvTypeID)
              ) || null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Fature Korigjuese"
            variant="outlined"
            size="small"
          />
        )}
        renderOption={(props, option) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            {...props}
          >
            <div style={{ textAlign: "start" }}>{option.Kodi}</div>
            <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
          </div>
        )}
      />
    </div>
  );

  const subsequentDeliverySwitch = (
    <div className="Item" style={{ marginBottom: "5px" }}>
      <Switch
        {...label}
        disabled={disabled}
        checked={state.subsequentDelivery === true || isSwitchOn5}
        onChange={(e) => {
          const isChecked = e.target.checked;
          const newValue = isChecked ? true : false;
          handleChangeFisIsCorrectiveInvoice("subsequentDelivery", newValue);
          setIsSwitchOn5(isChecked);
        }}
      />

      <Autocomplete
        disablePortal
        disabled={!isSwitchOn5}
        id="combo-box-demo18"
        sx={{ width: "100%" }}
        options={subsequentDelivery}
        filterOptions={filterOptions}
        getOptionLabel={(option) => (option ? option.Pershkrim : "")}
        onChange={(e, newValue) =>
          handleChangeFisIsCorrectiveInvoice(
            "subsequentDeliveryType",
            newValue ? newValue.Kodi : null
          )
        }
        value={
          state?.subsequentDeliveryType === null
            ? null
            : subsequentDelivery.find(
                (qytet) =>
                  Number(qytet.Kodi) === Number(state?.subsequentDeliveryType)
              ) || null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="E deglaruar me vonese"
            variant="outlined"
            size="small"
          />
        )}
        renderOption={(props, option) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            {...props}
          >
            <div style={{ textAlign: "start" }}>{option.Kodi}</div>
            <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
          </div>
        )}
      />
    </div>
  );

  const selfIssuedInvoiceAutocomplete = (
    <div className="Item">
      <Autocomplete
        disablePortal
        disabled={disabled}
        id="combo-box-demo19"
        sx={{ width: "100%" }}
        options={VeteFaturimiInv}
        filterOptions={filterOptions}
        getOptionLabel={(option) => (option ? option.Pershkrim : "")}
        onChange={(e, newValue) =>
          handleChangeFisIsCorrectiveInvoice(
            "fisTypeOfSelfIssID",
            newValue ? newValue.Kodi : null
          )
        }
        value={
          state?.fisTypeOfSelfIssID === null
            ? null
            : VeteFaturimiInv.find(
                (qytet) =>
                  Number(qytet.Kodi) === Number(state?.fisTypeOfSelfIssID)
              ) || null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Vetefaturim"
            variant="outlined"
            size="small"
          />
        )}
        renderOption={(props, option) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            {...props}
          >
            <div style={{ textAlign: "start" }}>{option.Kodi}</div>
            <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
          </div>
        )}
      />
    </div>
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1200);
    };
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", height: "100%" }}
        rowSpacing={isMobile ? 1 : 7}
        columnSpacing={1}
      >
        <Toast ref={toast} />
        <Grid container item xs={12} rowSpacing={1} columnSpacing={1}>
          <Grid item xs={5}>
            <Item>
              <div className="Item">
                <strong>Jo KESH</strong>
                <Switch
                  disabled={disabled}
                  {...label}
                  checked={state.FisInvoiceType === true || isSwitchOn1}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    const newValue = isChecked ? true : false;
                    handleChangeFisIsCorrectiveInvoice(
                      "FisInvoiceType",
                      newValue
                    );
                    setIsSwitchOn1(isChecked);
                  }}
                />
              </div>
            </Item>
          </Grid>

          <Grid item xs={7}>
            <Item>
              <div className="Item">
                <strong>Fature elektronike ?</strong>
                <Switch
                  disabled={!isSwitchOn1}
                  {...label}
                  checked={state.fisIsEinvoice === true || isSwitchOn2}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    const newValue = isChecked ? true : false;
                    handleChangeFisIsCorrectiveInvoice(
                      "fisIsEinvoice",
                      newValue
                    );
                    setIsSwitchOn2(isChecked);
                  }}
                />
              </div>
            </Item>
          </Grid>
        </Grid>
        <Grid container item xs={12} rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disablePortal
                disabled={disabled}
                id="combo-box-demo20"
                sx={{ width: "100%" }}
                options={Menyra}
                filterOptions={filterOptions}
                getOptionLabel={(option) => (option ? option.Pershkrim : "")}
                onChange={(e, newValue) =>
                  handleChangeFisIsCorrectiveInvoice(
                    "fisPaymentMethodTypeID",
                    newValue ? newValue.Kodi : null
                  )
                }
                value={
                  state?.fisPaymentMethodTypeID === null
                    ? null
                    : Menyra.find(
                        (qytet) =>
                          Number(qytet.Kodi) ===
                          Number(state?.fisPaymentMethodTypeID)
                      ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Menyra e pageses"
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "hidden", // Ensure the text doesn't overflow
                      whiteSpace: "nowrap", // Prevent line breaks
                      textOverflow: "ellipsis", // Add ellipsis for overflowed text
                    }}
                    {...props}
                  >
                    <div
                      style={{
                        textAlign: "start",
                        flex: 1,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {option.Pershkrim}
                    </div>
                    <div style={{ textAlign: "end" }}>{option.Kodi}</div>
                  </div>
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disablePortal
                disabled={disabled}
                id="combo-box-demo21"
                sx={{ width: "100%" }}
                options={documentType}
                filterOptions={filterOptions}
                getOptionLabel={(option) => (option ? option.Pershkrim : "")}
                onChange={(e, newValue) =>
                  handleChangeFisIsCorrectiveInvoice(
                    "fisDocumentTypeKodi",
                    newValue ? newValue.Kodi : null
                  )
                }
                value={
                  documentType.find(
                    (qytet) => qytet.Kodi === state.fisDocumentTypeKodi
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Lloj i dokumentit"
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "hidden", // Ensure the text doesn't overflow
                      whiteSpace: "nowrap", // Prevent line breaks
                      textOverflow: "ellipsis", // Add ellipsis for overflowed text
                    }}
                    {...props}
                  >
                    <div
                      style={{
                        textAlign: "start",
                        flex: 1,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {option.Pershkrim}
                    </div>
                    <div style={{ textAlign: "end" }}>{option.Kodi}</div>
                  </div>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disablePortal
                disabled={disabled}
                id="combo-box-demo22"
                sx={{ width: "100%" }}
                options={proceset}
                filterOptions={filterOptions}
                getOptionLabel={(option) => (option ? option.Pershkrim : "")}
                onChange={(e, newValue) =>
                  handleChangeFisIsCorrectiveInvoice(
                    "fisProcessKodi",
                    newValue ? newValue.Kodi : null
                  )
                }
                value={
                  proceset.find(
                    (qytet) => qytet.Kodi === state.fisProcessKodi
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Procesi"
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "hidden", // Ensure the text doesn't overflow
                      whiteSpace: "nowrap", // Prevent line breaks
                      textOverflow: "ellipsis", // Add ellipsis for overflowed text
                    }}
                    {...props}
                  >
                    <div
                      style={{
                        textAlign: "start",
                        flex: 1,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {option.Pershkrim}
                    </div>
                    <div style={{ textAlign: "end" }}>{option.Kodi}</div>
                  </div>
                )}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container item xs={12} rowSpacing={1} columnSpacing={1}>
          <Grid container direction="column" justifyContent="flex-end" xs={12}>
            <Grid item xs={4}>
              {correctiveInvoiceSwitch}
            </Grid>
            <Grid item xs={4}>
              {subsequentDeliverySwitch}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {selfIssuedInvoiceAutocomplete}
          </Grid>
        </Grid>

        <Grid container item xs={12} rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disabled
                disablePortal
                freeSolo
                value={state?.NSLF || ""}
                id="combo-box-demo23"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="NSLF"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disabled
                disablePortal
                freeSolo
                value={state?.NIVF || ""}
                id="combo-box-demo24"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="NIVF"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disabled
                disablePortal
                freeSolo
                value={state?.EIC || ""}
                id="combo-box-demo25"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="EIC"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid
            container
            item
            xs={12}
            rowSpacing={1}
            columnSpacing={1}
            direction="row"
          >
            {/* Second Column */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              item
              xs={8}
              rowSpacing={1}
              columnSpacing={1}
            >
              <Box
                sx={{
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    width: 130,
                    height: 130,
                  },
                }}
              >
                <Paper onClick={() => doubleClick(fisVerificationUrl)}>
                  <QRCode value={fisVerificationUrl} />
                </Paper>
              </Box>
            </Grid>
          </Grid>

          {/* First Column */}

          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={3}
            columnSpacing={1}
          >
            <Grid item xs={7}>
              {generateInvoiceButton}
            </Grid>
            <Grid item xs={5}>
              {pdfButton}
            </Grid>
          </Grid>
        </Grid>

        {/* Buttoni i fiskalizimit */}
        {/* <Box sx={{ flexGrow: -1 }}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              paddingRight={5}
              spacing={2}
            >
              <Grid item xs={2}>
                <div className="Item"></div>
              </Grid>
            </Grid>
          </Box> */}
      </Grid>
    </>
  );
};

export default Fiskalizimi;
