import React, { useRef } from "react";
import { Button, Typography } from "@mui/material";
import { PrintSharp } from "@mui/icons-material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import { useReactToPrint } from "react-to-print";
import "./print80Skontrino.scss";

import DynamicTableSkontrino80 from "./dynamicSkontrinoTable80";
import Skontrino80 from "./skontrino80";

import DynamicTable from "./dynamicTable";
import SimpleTableSH from "./simpletable";

import Skontrino80Tatimore from "./Sontrino80Tatimore";

import DynamicUrdhriPunes from "./dynamicUrdhriPunes";
import SkontrinoUrdhriPunes from "./skontrinoUrdhriPunes";
import DynamicTableSkontrino from "./dynamicSkontrinoTable";

const PrintoPdf1 = ({
  fromFShitje,
  rowsData,
  handlePostAllItems,
  handleUpdateAllItems,
  rows,
  rowsPrint1,
  state,
  fisVerificationUrl,
  posBar,
  operator,
  menyrapageses,
  nipt,
  showToast,
  username,
  konfigurimeRedux,
  totalBtnDisabled,
}) => {
  const componentsRef = useRef(null);
  const componentsRef2 = useRef(null);
  const componentsRef3 = useRef(null);
  const componentsRef4 = useRef(null);
  const componentsRef5 = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentsRef.current,
  });

  const handlePrint2 = useReactToPrint({
    content: () => componentsRef2.current,
    documentTitle: "Multi-Page Document",
  });

  const handlePrint3 = useReactToPrint({
    content: () => componentsRef3.current,
  });

  const handlePrint4 = useReactToPrint({
    content: () => componentsRef4.current,
  });

  const handlePrint5 = useReactToPrint({
    content: () => componentsRef5.current,
  });

  const updateState = async (handlePrint4) => {
    try {
      await handleUpdateAllItems(handlePrint4, { fromRegister: false });
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  const regjistro = async (handlePrint4) => {
    try {
      await handleUpdateAllItems(handlePrint4, { fromRegister: true });
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            {fromFShitje && (
              <Button {...bindTrigger(popupState)}>
                <PrintSharp />
              </Button>
            )}
            {totalBtnDisabled === true && (
              <Button
                variant="contained"
                className="btn_fiskal flex"
                onClick={() => {
                  regjistro(handlePrint4);
                }}
                style={{
                  borderRadius: "20px",
                }}
              >
                Regjistro
              </Button>
            )}
            {posBar === true && !totalBtnDisabled && (
              <Button
                variant="contained"
                className="btn_fiskal flex"
                onClick={() => {
                  if (rowsPrint1.length === 0) {
                    showToast("Shtoni artikull", {
                      severity: "warn",
                    });
                    return;
                  } else {
                    updateState(handlePrint4);
                  }
                }}
                style={{
                  justifyContent: "space-between",
                  borderRadius: "20px",
                }}
              >
                Ndaj Punet
              </Button>
            )}

            {posBar && !totalBtnDisabled && (
              <Button
                onClick={() => {
                  handlePostAllItems(handlePrint2);
                }}
                style={{
                  color: "#fff",
                }}
              >
                Mbylle
              </Button>
            )}
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography
                sx={{
                  padding: 1,
                  display: "grid",
                  flexDirection: "column",
                }}
              >
                <Button
                  onClick={() => {
                    if (rowsData.length === 0) {
                      showToast("Nuk keni asnje artikull", {
                        severity: "warn",
                      });
                    } else {
                      handlePrint();
                    }
                  }}
                  color="primary"
                >
                  <PrintSharp /> Printo Fature Shitje
                </Button>

                <Button
                  variant="text"
                  className="btn_fiskal flex"
                  onClick={() => {
                    if (rowsData.length === 0) {
                      showToast("Nuk keni asnje artikull", {
                        severity: "warn",
                      });
                    } else {
                      handlePrint3();
                    }
                  }}
                  style={{
                    justifyContent: "space-between",
                    borderRadius: "20px",
                  }}
                >
                  <PrintSharp /> Printo Skotrino 80
                </Button>

                <Button
                  variant="text"
                  className="btn_fiskal flex"
                  onClick={() => {
                    if (rowsData.length === 0) {
                      showToast("Nuk keni asnje artikull", {
                        severity: "warn",
                      });
                    } else {
                      handlePrint5();
                    }
                  }}
                  style={{
                    justifyContent: "space-between",
                    borderRadius: "20px",
                  }}
                >
                  <PrintSharp /> Printo Skotrino 58
                </Button>
              </Typography>
            </Popover>
          </>
        )}
      </PopupState>

      {fromFShitje && (
        <div ref={componentsRef5} className="print">
          <Skontrino80Tatimore
            fromftsh={true}
            konfigurimeRedux={konfigurimeRedux}
            operator={operator}
            state={state}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
          />
          <DynamicTableSkontrino
            rows={rowsData}
            state={state}
            fatureThjesht={true}
          />
        </div>
      )}

      {fromFShitje && (
        <div ref={componentsRef3} className="print">
          <Skontrino80Tatimore
            fromftsh={true}
            konfigurimeRedux={konfigurimeRedux}
            operator={operator}
            state={state}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
          />
          <DynamicTableSkontrino80
            rows={rowsData}
            state={state}
            fatureThjesht={true}
          />
        </div>
      )}

      {fromFShitje && (
        <div ref={componentsRef} className="print">
          <SimpleTableSH
            state={state}
            konfigurimeRedux={konfigurimeRedux}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
            username={username}
          />
          <DynamicTable rows={rowsData} state={state} />
        </div>
      )}

      {posBar && (
        <div ref={componentsRef2} className="print">
          <Skontrino80
            konfigurimeRedux={konfigurimeRedux}
            operator={operator}
            state={state}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
          />
          {konfigurimeRedux[0].PrintAccent ? (
            // <DynamicTableSkontrino rows={rows} state={state} />
            <DynamicTableSkontrino80 rows={rows} state={state} />
          ) : (
            <DynamicTableSkontrino80 rows={rows} state={state} />
          )}

          {rowsPrint1.length !== 0 && !konfigurimeRedux[0].PrintAccent && (
            <>
              <div className="page-break" />
              <div className="page">
                <SkontrinoUrdhriPunes
                  konfigurimeRedux={konfigurimeRedux}
                  state={state}
                />
                <DynamicUrdhriPunes rows={rowsPrint1} state={state} />
              </div>
            </>
          )}
        </div>
      )}

      {posBar && (
        <div ref={componentsRef4} className="print">
          <SkontrinoUrdhriPunes
            konfigurimeRedux={konfigurimeRedux}
            operator={operator}
            state={state}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
          />
          <DynamicUrdhriPunes rows={rowsPrint1} state={state} />
        </div>
      )}
    </>
  );
};

export default PrintoPdf1;
