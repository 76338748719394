import React, { useRef, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { PrintSharp } from "@mui/icons-material";
import PopupState from "material-ui-popup-state";
import { useReactToPrint } from "react-to-print";

import SkontrinoSupermarket from "./SkontrinoSupermarket";
import DynamicSupermarket80 from "./DynamicSupermarket80";
import mainAxios from "../../services/axios";
import { formatDate } from "../../pages/POS/Restaurant/Main/DateUtils";

const PrintoSupermarket = ({
  rows,
  state,
  selectedUserData,
  posSupermarketListaFaturave,
  nipt,
  selectedId,
  showToast,
  menyra,
  operator,
  konfigurimeRedux,
  fetchDataByOperator,
  hideLoading,
  showLoading,
}) => {
  const componentsRef2 = useRef(null);
  const [triggerRender, setTriggerRender] = useState(0); // State to force re-render

  const handlePrint2 = useReactToPrint({
    content: () => componentsRef2.current,
  });

  const makePutRequest = async () => {
    try {
      showLoading();
      const postDataWithoutId = {
        Aktiv: 0,
        Mbyllur: formatDate(state.Mbyllur, true),
        E_Paguar: 1,
      };
      const response = await mainAxios.put(
        `/pos/${selectedId}`,
        postDataWithoutId,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        handlePrint2();
        fetchDataByOperator();
        setTriggerRender((prev) => prev + 1); // Force re-render after the request
      }
    } catch (error) {
      console.error(`Error updating item with ID ${selectedId}:`, error);
    } finally {
      hideLoading();
    }
  };

  // Use useEffect to listen for F9 keypress
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "F9") {
        if (rows.length === 0) {
          showToast("Nuk ka artikuj.");
          return;
        }
        if (!selectedId) {
          showToast("Nuk ka fature te selektuar.");
          return;
        } else {
          makePutRequest();
        }
      }
    };

    // Add the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Remove the event listener on cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [rows, selectedId, triggerRender]); // Add triggerRender to re-run effect after action

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {() => (
          <>
            {posSupermarketListaFaturave ? (
              <Button
                variant="contained"
                className="btn_fiskal flex"
                onClick={() => {
                  if (rows.length === 0) {
                    showToast("Nuk ka artikuj.");
                    return;
                  }
                  if (!selectedId) {
                    showToast("Nuk ka fature te selektuar.");
                    return;
                  } else {
                    handlePrint2();
                  }
                }}
                style={{
                  justifyContent: "space-between",
                  margin: "5px",
                }}
              >
                <PrintSharp /> Riprint Faturen
              </Button>
            ) : (
              ""
              // <Button
              //   variant="contained"
              //   className="btn_fiskal flex"
              //   onClick={() => {
              //     if (rows.length === 0) {
              //       showToast("Shtoni artikull", {
              //         severity: "warn",
              //       });
              //       return;
              //     }
              //     if (!selectedId) {
              //       showToast("Nuk ka fature te selektuar.");
              //       return;
              //     } else {
              //       makePutRequest();
              //     }
              //   }}
              //   style={{
              //     justifyContent: "space-between",
              //   }}
              // >
              //   <PrintSharp />
              //   Printo Faturen
              // </Button>
            )}
          </>
        )}
      </PopupState>

      <div ref={componentsRef2} className="print">
        <SkontrinoSupermarket
          selectedUserData={selectedUserData}
          state={state}
          posSupermarketListaFaturave={posSupermarketListaFaturave}
          nipt={nipt}
          menyra={menyra}
          operator={operator}
          konfigurimeRedux={konfigurimeRedux}
        />
        <DynamicSupermarket80 rows={rows} state={state} />
      </div>
    </>
  );
};

export default PrintoSupermarket;
