import React, { useEffect, useRef, useState } from "react";
import useStorage from "../../hooks/useStorage";
import { Button, InputText, MultiSelect, Dropdown } from "primereact";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import PrintoBarcode from "../../components/PrintoBarcode";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./Artikuj.scss";

const ArtikujTable = ({
  searchTerm,
  handleSearchInputChange,
  handleSearchClick,
  showHideColumns,
  isEditingAllowed,
  artikujTotal,
  firstnum,
  lastnum,
  numPerPage,
  currentPage,
  setFirstNum,
  setLastNum,
  setNumPerPage,
  setCurrentPage,
  numDataLength,
  first2,
  rows2,
  onCustomPage2,
  selectedRowData,
  handleRowClick,
  data,
  disabled,
  selectedProduct,
  setSelectedProduct,
  updateRowData,
  setState,
  state,
}) => {
  const cm = useRef(null);

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const defaultColumnsStorage = [
    "Kodi",
    "Pershkrim",
    "Barkod",
    "Cmimi_Bleres",
    "Cmimi",
    "Njesi_Kodi",
  ];

  const [columns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim", editor: textEditor },
    { field: "Njesi_Kodi", title: "Njesi_Kodi" },
    { field: "Cmimi_Bleres", title: "Cmimi Bleres", editor: textEditor },
    { field: "Cmimi", title: "Cmimi Shites", editor: textEditor },
    { field: "Barkod", title: "Barkod", editor: textEditor },
    { field: "Aktiv", title: "Aktiv" },
    { field: "Perbere", title: "Perbere", editor: textEditor },
    { field: "Peshore", title: "Peshore" },
  ]);

  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonatArtikuj"
  );

  const filterdColumns = showHideColumns
    ? columns?.filter((column) =>
        selectedColumns?.some((field) => field === column?.field)
      )
    : columns;

  const handleChangeOnCell = (key, value, rowData) => {
    if (isEditingAllowed) {
      let updatedRowData;

      if (value === "" || value === undefined || value === null) {
        updatedRowData = { [key]: null };
      } else {
        updatedRowData = { [key]: value };
      }

      // Check if the new value is different from the current value
      if (value !== rowData[key]) {
        if (state[key] === updatedRowData[key]) {
          updateRowData(updatedRowData);
        } else {
          updateRowData(updatedRowData);
        }
      }
    }
    // Update local state regardless of whether the value changed
    setState((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const onCellEditComplete = (e) => {
    const { rowData, newValue, field } = e;
    handleChangeOnCell(field, newValue, rowData);
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns
      .filter((col) => selectedColumns.some((sCol) => sCol.title === col.title))
      .map((col) => col.field);
    setselectedcolumns(orderedSelectedColumns);
  };
  const header = (
    <>
      <div className="header_artikuj flex justify-between">
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              style={{ height: "2em" }}
              placeholder="Kerko"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
          </span>
          <Button
            style={{ marginLeft: "1em", height: "2em" }}
            variant="contained"
            onClick={handleSearchClick} // Use the search handler
          >
            Kerko
          </Button>
        </div>

        <div
          style={{
            textAlign: "left",
          }}
        >
          {showHideColumns && (
            <>
              <MultiSelect
                className="multiselect_artikuj"
                value={filterdColumns}
                options={columns}
                optionLabel="title"
                onChange={onColumnToggle}
                style={{
                  width: "3em",
                  height: "2em",
                  border: "1px solid #a8a9aa54",
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );

  const dynamicColumns = filterdColumns?.map((col) => {
    const uniqueKey = `${col?.field}`;
    const columnStyle = {
      height: "2.6rem",
      ...(col.field === "Pershkrim" ? { minWidth: "200px" } : {}),
      ...(col.field === "Njesi_Kodi" ? { maxWidth: "120px" } : {}),
      ...(col.field === "Kodi" ? { maxWidth: "120px" } : {}),
      ...(col.field === "Aktiv" ? { maxWidth: "50px" } : {}),
      ...(col.field === "Perbere" ? { maxWidth: "70px" } : {}),
      ...(col.field === "Peshore" ? { maxWidth: "70px" } : {}),
    };
    return (
      <Column
        key={uniqueKey}
        style={columnStyle}
        field={col?.field}
        header={col?.title}
        editor={isEditingAllowed ? col.editor : null}
        onCellEditComplete={onCellEditComplete}
        decimalPlaces={col?.decimalPlaces || 0}
        body={(rowData) => {
          const value = rowData[col?.field];
          let formattedValue;
          if (typeof value === "number") {
            formattedValue = Number.isInteger(value)
              ? value.toLocaleString()
              : value.toFixed(2);
          } else {
            formattedValue = value;
          }
          return (
            <div
              style={{
                width: "100%",
                textAlign: typeof value === "number" ? "right" : "left",
                justifyContent: typeof value === "number" ? "right" : "left",
              }}
            >
              {formattedValue}
            </div>
          );
        }}
      />
    );
  });

  // Add the barcode button column
  dynamicColumns.push(
    <Column
      style={{ height: "2.6rem", maxWidth: "50px" }}
      key="barcodeButton"
      body={(rowData) => <PrintoBarcode rowData={rowData} />}
    />
  );

  // new update 19/09/2024
  const template2 = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",

    RowsPerPageDropdown: (options) => {
      // Calculate page-related data
      const totalPages = Math.ceil(artikujTotal.total_rows / options.value);
      const totalArt = artikujTotal.total_rows;

      let firstnumb, lastnumb;
      if (currentPage === 1) {
        firstnumb = currentPage;
      } else {
        firstnumb = currentPage * options.value - (options.value - 1);
      }

      if (currentPage === totalPages) {
        lastnumb = totalArt;
      } else {
        lastnumb = currentPage * options.value;
      }

      // Update state when options.value changes
      useEffect(() => {
        setFirstNum(firstnumb);
        setLastNum(lastnumb);
      }, [numPerPage, currentPage ?? 1]); // Run when options.value or currentPage changes

      useEffect(() => {
        setNumPerPage(options.value);
        setCurrentPage(1);
      }, [options.value]);

      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Rrjeshta
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },

    CurrentPageReport: () => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {firstnum} - {lastnum} of {artikujTotal.total_rows}
        </span>
      );
    },

    PrevPageLink: () => {
      const handlePrevClick = () => {
        setCurrentPage((prevPage) => prevPage - 1);
      };

      return (
        <Button
          variant="outlined"
          style={{ cursor: "pointer", marginRight: "10px", marginLeft: "10px" }}
          onClick={handlePrevClick}
          disabled={currentPage === 1}
        >
          <KeyboardArrowLeftIcon />
        </Button>
      );
    },

    NextPageLink: () => {
      const handleNextClick = () => {
        setCurrentPage((prevPage) => prevPage + 1);
      };

      return (
        <Button
          variant="outlined"
          style={{ cursor: "pointer" }}
          onClick={handleNextClick}
          disabled={numPerPage > numDataLength}
        >
          <ChevronRightIcon />
        </Button>
      );
    },
  };

  return (
    <div className="card_artikuj1" style={{ height: "67%" }}>
      <DataTable
        disabled={disabled}
        editMode="cell"
        className="datatable_primegrid artikuj"
        columnclassname="datatable-column-border"
        paginator
        paginatorTemplate={template2}
        first={first2}
        rows={rows2}
        size="small"
        rowClassName={(rowData) =>
          selectedRowData && selectedRowData.Id === rowData.Id
            ? "selected-row"
            : ""
        }
        onPage={onCustomPage2}
        paginatorClassName="footer_datatable_artikuj justify-content-end"
        reorderableColumns
        cellSelection
        scrollable
        resizableColumns
        onRowEditComplete={onCellEditComplete}
        onSelectionChange={(e) => {
          const selectedRowData =
            e.value.length > 0 ? e.value[0].rowData : null;
          handleRowClick(selectedRowData);
        }}
        contextMenuSelection={selectedProduct}
        contextselection={selectedProduct}
        onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
        onContextMenu={(e) => cm.current.show(e.originalEvent)}
        value={data}
        showGridlines
        stripedRows
        dataKey="Id"
        header={header}
        globalFilterFields={[
          "BarKod",
          "Pershkrim",
          "Njesi_Kodi",
          "Sasia_Print",
          "Sasia",
        ]}
        filterDisplay="menu"
        emptyMessage="Nuk ka te dhena"
      >
        {dynamicColumns}
      </DataTable>
    </div>
  );
};

export default ArtikujTable;
