// DynamicTableSkontrino80.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./print80Skontrino.scss";
import QRCode from "qrcode.react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const formatNumber = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    return (
      number % 1 === 0 ? number.toFixed(0) : number.toFixed(2),
      number.toLocaleString()
    );
  }
  return "N/A";
};

const DynamicTableSkontrino80 = ({ rows, state }) => {
  const DisplayData = rows.map((info, index) => {
    const rowNumber = index + 1;
    return (
      <tr key={rowNumber}>
        <td className="columnsPershkrim">{info.Pershkrim}</td>
        <td className="columnsPershkrim">{info.Sasia} x</td>
        <td className="columnsPershkrim">{formatNumber(info.Cmimi)}</td>
        <td className="columnsVlera">{formatNumber(info.Vlera)}</td>
      </tr>
    );
  });

  // const sums = {
  //   Vlera: formatNumber(rows.reduce((total, info) => total + info.Vlera, 0)),
  // };

  const groupedRows = rows.reduce((acc, info) => {
    const key = info.Tvsh ?? 0;
    if (!acc[key]) {
      acc[key] = { totalVleraPaTvsh: 0, totalVlera: 0 };
    }
    acc[key].totalVleraPaTvsh += info.Vlera_Pa_Tvsh;
    acc[key].totalVlera += info.Vlera;
    return acc;
  }, {});

  const DisplayDataTable2 = Object.entries(groupedRows).map(
    ([tvsh, totals]) => {
      let tipi = "";
      if (tvsh === "6") {
        tipi = "D";
      } else if (tvsh === "20") {
        tipi = "B";
      } else if (tvsh === "10") {
        tipi = "C";
      } else {
        tipi = "A";
      }

      return (
        <tr key={tvsh}>
          <td>{tipi}</td>
          <td>{tvsh}</td>
          <td>{formatNumber(totals.totalVleraPaTvsh)}</td>
          <td className="columnsvleracenterXhiroTurniPOS">
            {formatNumber(totals.totalVlera)}
          </td>
        </tr>
      );
    }
  );

  const sums = {
    Vlera_pa_Skonto: formatNumber(
      rows.reduce(
        (total, info) => total + info.Vlera * state.Kursi + info.Skonto_Vlera,
        0
      )
    ),

    Vlera_e_Skontos: formatNumber(
      rows.reduce((total, info) => total + info.Skonto_Vlera, 0)
    ),
    Tvsh: formatNumber(rows.reduce((total, info) => info.Tvsh, 0)),
    Tvsh_Vlera: formatNumber(
      rows.reduce((total, info) => total + info.Tvsh_Vlera * state.Kursi, 0)
    ),
    Vlera_Pa_Tvsh: formatNumber(
      rows.reduce((total, info) => total + info.Vlera_Pa_Tvsh, 0)
    ),
    Vlera: formatNumber(rows.reduce((total, info) => total + info.Vlera, 0)),
    Total: formatNumber(rows.reduce((total, info) => total + info.Total, 0)),
  };
  return (
    <table className="hide-border">
      <thead>
        <tr
          className="table-head"
          style={{
            textAlign: "center",
          }}
        >
          <th className="th">Artikull</th>
          <th className="th">Sasia</th>
          <th className="th">Cmimi</th>
          <th className="th">Vlera</th>
        </tr>
      </thead>
      <tr>
        <td colSpan={5}>
          <div className="div_line"></div>
        </td>
      </tr>

      <tbody>{DisplayData}</tbody>
      <tr>
        <td colSpan={5}>
          <div className="div_line"></div>
        </td>
      </tr>
      <tr>
        <td className="columnsTotal flex-col" colSpan={1}>
          <h2>Totali</h2>
        </td>
        <td colSpan={2}></td>
        <td className="columnsTotal columnsvleracenter" colSpan={3}>
          <h1>{sums.Vlera}</h1>
        </td>
        <td colSpan={4}></td>
      </tr>
      <tr>
        <td colSpan={5}>
          <table className="table bordered">
            <thead>
              <tr>
                <th>Tipi</th>
                <th>TVSH</th>
                <th>Vlera pa TVSH</th>
                <th>Vlera me TVSH</th>
              </tr>
            </thead>
            <tbody>{DisplayDataTable2}</tbody>
          </table>
        </td>
      </tr>

      <tr>
        <td colSpan={5}>
          <div className="div_line"></div>
        </td>
      </tr>
      <tfoot>
        <tr>
          <td colSpan={5} className="columnsFis">
            NSLF: <br /> {state?.NSLF}
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="columnsFis">
            NIVF:
            <br />
            {state?.NIVF}
          </td>
        </tr>

        <tr>
          <td
            colSpan={4}
            style={{
              textAlign: "center",
              paddingTop: "5px",
              paddingBottom: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Paper>
                <QRCode
                  style={{ width: 110, height: 110 }}
                  value={state.fisVerificationUrl}
                />
              </Paper>
            </Box>
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>
          </td>
        </tr>
        <tr>
          <td colSpan={5}>Gjeneruar nga Parid Smart Solution</td>
        </tr>
      </tfoot>
    </table>
  );
};

export default DynamicTableSkontrino80;
