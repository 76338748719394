import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./print.scss";
import TotalFooter from "../FaqetEprintimit/TotalFooter";

const formatNumber = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    return number.toFixed(2);
  }
  return "N/A";
};
const DynamicTableB = ({ rows, state, currentDateTime }) => {
  const DisplayData = rows.map((info, index) => {
    const rowNumber = index + 1;
    return (
      <tr key={rowNumber}>
        <td className="solid-border">{rowNumber}</td>
        <td className="pershkrimArtikull">{info.Kodi}</td>
        <td className="pershkrimArtikull">{info.Pershkrim}</td>
        <td>{info.Sasia}</td>
        <td className="pershkrimArtikull">{info.Njesi_Kodi}</td>
        <td>{formatNumber(info.Skonto)}</td>
        <td>{formatNumber(info.Cmimi_Pa_Tvsh * state.Kursi)}</td>
        <td>{formatNumber(info.Tvsh)}</td>
        <td>
          {formatNumber(
            info.Cmimi_Pa_Tvsh * state.Kursi +
              info.Cmimi_Pa_Tvsh * (info.Tvsh / 100)
          )}
        </td>
        <td>{formatNumber(info.Vlera * state.Kursi)}</td>
      </tr>
    );
  });

  const sums = {
    Vlera_pa_Skonto: formatNumber(
      rows.reduce(
        (total, info) => total + info.Vlera * state.Kursi + info.Skonto_Vlera,
        0
      )
    ),

    Vlera_e_Skontos: formatNumber(
      rows.reduce((total, info) => total + info.Skonto_Vlera, 0)
    ),
    Tvsh: formatNumber(rows.reduce((total, info) => total + info.Tvsh, 0)),
    Tvsh_Vlera: formatNumber(
      rows.reduce((total, info) => total + info.Tvsh_Vlera * state.Kursi, 0)
    ),
    Vlera_Pa_Tvsh: formatNumber(
      rows.reduce((total, info) => total + info.Vlera_Pa_Tvsh * state.Kursi, 0)
    ),
    Total: formatNumber(rows.reduce((total, info) => total + info.Total, 0)),
  };
  return (
    <table className="HeaderTable border">
      <thead className="title_printuar">
        <tr>
          <td className="title" colSpan={8}>
            <td>Printuar me {currentDateTime}</td>
          </td>
        </tr>
      </thead>
      <table className="table table-striped bordered table-body">
        <thead className="thead thead-striped ">
          <tr>
            <th>Nr</th>
            <th>Kodi</th>
            <th>Artikulli</th>
            <th>Sasia</th>
            <th>Njesi</th>
            <th>Skonto%</th>
            <th>Cmimi pa TVSH</th>
            <th>TVSH %</th>
            <th>Cmimi me TVSH</th>
            <th>Vlera</th>
          </tr>
        </thead>

        <tbody className="tbody-print">{DisplayData}</tbody>
      </table>

      <table className="table_footer_blerje">
        <thead>
          <tr>
            <th>Vlera pa Skonto</th>
            <th>Vlera e skontos</th>
            <th>Vlera pa TVSH</th>
            <th>Vlera e TVSH-se</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{sums.Vlera_pa_Skonto}</td>
            <td>{sums.Vlera_e_Skontos}</td>
            <td>{sums.Vlera_Pa_Tvsh}</td>
            <td>{sums.Tvsh_Vlera}</td>
            <td>{sums.Total}</td>
          </tr>
        </tbody>
      </table>
      <TotalFooter state={state} className="m-2" />
      <tfoot>
        <tr>
          <td className="footer-gjeneruar" colSpan={8}>
            Gjeneruar nga Parid Smart Solution
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default DynamicTableB;
