import React, { useState } from "react";
import ModalListSupermarket from "./ModalListSupermarket";
import "./ArtikujSuperMarket.scss";
import ShtoKlient from "../../../Restaurant/Main/ShtoKlient/ShtoKlient";

export default function SearchArtikuj({
  shtoArtikull,
  art,
  setArt,
  artikujPos,
}) {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div className="tabela_artikuj">
      <div className="search_supermarket">
        <div style={{ position: "relative" }}>
          <ModalListSupermarket
            shtoArtikull={shtoArtikull}
            gridKey={"PosSupermarket"}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            art={art}
            setArt={setArt}
            artikujPos={artikujPos}
          />
        </div>
        <div className="footer_keyboard_supermarket">
          <h1>Zgjidhni Klient</h1>
          <ShtoKlient />
        </div>
      </div>
    </div>
  );
}
