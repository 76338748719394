import QRCode from "qrcode.react";
import "./printFdShoqeruese.scss";

function SimpleTableFdShoqeruese({ state, fisVerificationUrl }) {
  const lloji = [
    {
      Kodi: "0",
      Pershkrim: "Fature Shoqeruese Pa ndryshuar pronesine",
    },
    { Kodi: "1", Pershkrim: "Fature Shoqeruese me ndryshime pronesie" },
  ];

  const transaksioni = [
    {
      Kodi: "0",
      Pershkrim: "Shitje",
    },
    { Kodi: "1", Pershkrim: "Ekzaminim" },
    { Kodi: "2", Pershkrim: "Transferim" },
    { Kodi: "3", Pershkrim: "Shitje dere me dere" },
  ];

  const startPoint = [
    {
      Kodi: "0",
      Pershkrim: "Magazine",
    },
    { Kodi: "1", Pershkrim: "Ekspozite" },
    { Kodi: "2", Pershkrim: "Dyqan" },
    { Kodi: "3", Pershkrim: "Pika e shitjes" },
    { Kodi: "4", Pershkrim: "Magazina e nje personi tjeter" },
    { Kodi: "5", Pershkrim: "Magazina doganore" },
    { Kodi: "6", Pershkrim: "Tjetër" },
  ];

  const destPoint = [
    {
      Kodi: "0",
      Pershkrim: "Magazinë",
    },
    { Kodi: "1", Pershkrim: "Ekspozitë" },
    { Kodi: "2", Pershkrim: "Dyqan" },
    { Kodi: "3", Pershkrim: "Pika e shitjes" },
    { Kodi: "4", Pershkrim: "Tjetër" },
  ];

  return (
    <table className="HeaderTable border">
      <thead>
        <tr className="funtior_table_head">
          <tr className="funtior_table_head_below">
            <td className="title1" colSpan={3}>
              <b>FATURË SHOQËRUESE</b>
            </td>
            <tr className="first_flex_kutiz">
              <tr className="funtior_table_head_below">
                <tr className="kutiza_madhe_dy">
                  {/* Operator */}
                  <td className="border-true kutiza_par">
                    <div className="solid-border">Data: {state.Data}</div>
                    <div className="solid-border">NR: {state.Kodi}</div>
                    <div className="solid-border">
                      Operator: {state.Operator}
                    </div>
                  </td>
                  <td className="border-true kutiza_par_2">
                    <div className="solid-border">NSLFSH: {state.fisWtnic}</div>
                    <div className="solid-border">
                      NIVFSH: {state.fisFwtnic}
                    </div>
                    <div className="solid-border">
                      Vendi i ushtrimit te biznesit:
                    </div>
                  </td>
                </tr>
                <tr className="border-true kutiza_madhe_tre">
                  <div className="solid-border">Subjekti: {state.Operator}</div>
                  <div className="solid-border">Adresa:</div>
                  <div className="solid-border">NIPT: {state.fisWtnic}</div>
                  <div className="solid-border">Telefon: {state.fisFwtnic}</div>
                </tr>
              </tr>
              <div className="kutiza_qr_code">
                <QRCode value={fisVerificationUrl} />
              </div>
            </tr>
          </tr>
        </tr>

        <tr className="table table-striped bordered border kutiza_e_katert">
          {/* Shitesi */}
          <td className="borderi_par border-true">
            <div className="solid-border border-true">
              Lloji i Fatures Shoqeruese:
            </div>
            <div className="solid-border border-true">
              {" "}
              Lloji i pronesise se automjetit:
            </div>
            <div className="solid-border border-true">
              Targa e automjetit transportues:
            </div>
            <div className="solid-border border-true">
              Lloji i transaksionit:
            </div>
          </td>
          {/* Klienti */}
          <td className="borderi_dyt border-true">
            <div className="solid-border">
              {" "}
              {state?.fisWtnType === null
                ? null
                : lloji[state.fisWtnType]?.Pershkrim || null}
            </div>
            <div className="solid-border">
              Lëshuesi është pronar i automjetit
            </div>
            <div className="solid-border">
              {state.Targa_e_Mjetit ? state.Targa_e_Mjetit : "N/A"}
            </div>
            <div className="solid-border">
              {state?.fisTransaction === null
                ? null
                : transaksioni[state.fisTransaction]?.Pershkrim || null}{" "}
            </div>
          </td>
        </tr>

        <tr className="table table-striped bordered border kutiza_e_katert">
          <td className="borderi_comment border-true">
            <div className="comment_value">PIKA E FILLIMIT</div>
          </td>

          <td className="borderi_comment_dyt border-true">
            <div className="comment_value">PIKA E DESTINACIONIT</div>
          </td>
        </tr>

        <tr className="table table-striped bordered border kutiza_e_katert">
          {/* Shitesi */}
          <td className="borderi_comment border-true">
            <div className="solid-border border-true">
              Lloji i pikes:{" "}
              {state?.fisStartPoint === null
                ? null
                : startPoint[state.fisStartPoint]?.Pershkrim || null}
            </div>
            <div className="solid-border border-true">Adresa:</div>
            <div className="solid-border border-true">
              Qyteti: {state.Qyteti_Kodi}
            </div>
            <div className="solid-border border-true">
              Data dhe Ora: {state.fisStartDateTime}
            </div>
          </td>
          {/* Klienti */}
          <td className="borderi_comment_dyt border-true">
            <div className="solid-border">
              Lloji i pikes:{" "}
              {state?.fisDestinationPoint === null
                ? null
                : destPoint[state.fisDestinationPoint]?.Pershkrim || null}
            </div>
            <div className="solid-border">
              Adresa: {state.fisDestinationAddress}
            </div>
            <div className="solid-border">
              Qyteti: {state.fisDestinationCity}
            </div>
            <div className="solid-border">
              Data dhe Ora:{state.fisDestinationDateTime}
            </div>
          </td>
        </tr>

        <tr className="table table-striped bordered border">
          <td className="borderi_comment border-true">
            <div className="comment_value">
              A kerkohet shqoqerim ? <br />{" "}
              {state.fisIsEscortRequired === 1 ? "PO" : "JO"}
            </div>
          </td>

          <td className="borderi_comment_dyt border-true">
            <div className="comment_value">
              A jane mallra te ndezshme ?<br />{" "}
              {state.fisIsGoodsFlammable === 1 ? "PO" : "JO"}
            </div>
          </td>
        </tr>
      </thead>
    </table>
  );
}

export default SimpleTableFdShoqeruese;
