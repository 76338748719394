import React from "react";
import Button from "@mui/material/Button";
import SplitButton from "../../../../components/ShikoFaturat/ButtonGroup"; // Adjust the import path as necessary
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import mainAxios from "../../../../services/axios";

const ButtonGroup = ({
  fatureshitje,
  nmrPage,
  properties,
  setNmrPage,
  setProperties,
  setRows,
  showLoading,
  hideLoading,
  toast,
  contextKey,
  dataNgaOn,
  dataDeriOn,
}) => {
  const nextNum = async (page) => {
    showLoading();
    try {
      const response = await mainAxios(
        `/${contextKey}/${dataNgaOn}/${dataDeriOn}?page=${page}&&per_page=20`
      );

      const modifiedDataAR = response.data.map((item) => ({
        ...item,
        Data: item.Data ? item.Data.split("T")[0] : "",
      }));
      setProperties(response.data);
      setRows(modifiedDataAR);
    } catch (err) {
      console.error(err);
      setRows([]);
      toast.current.show({
        severity: "error",
        summary: "Kerkimi deshtoi.",
      });
    } finally {
      hideLoading();
    }
  };

  const handleButton1Click = () => {
    const nextPage = nmrPage + 1;
    setNmrPage(nextPage);
    nextNum(nextPage);
  };

  const handleButton2Click = () => {
    if (nmrPage > 1) {
      const prevPage = nmrPage - 1;
      setNmrPage(prevPage);
      nextNum(prevPage);
    }
  };

  const handleSplitButtonOptionClick = async (index) => {
    switch (index) {
      case 0:
        break;
      case 1:
        await allFisIvc();
        break;
      case 2:
        await allNonFisIvc();
        break;
      case 3:
        await allFis();
        break;
      default:
        break;
    }
  };

  const allFisIvc = async () => {
    showLoading();
    try {
      const response = await mainAxios(`/${contextKey}/fiskalizuaraEic`);

      const modifiedDataAR = response.data.map((item) => ({
        ...item,
        Data: item.Data ? item.Data.split("T")[0] : "",
      }));

      setRows(modifiedDataAR);
    } catch (err) {
      console.error(err);
      setRows([]);
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
      });
    } finally {
      hideLoading();
    }
  };

  const allNonFisIvc = async () => {
    showLoading();
    try {
      const response = await mainAxios(`/${contextKey}/pafiskalizuara`);

      const modifiedDataAR = response.data.map((item) => ({
        ...item,
        Data: item.Data ? item.Data.split("T")[0] : "",
      }));

      setRows(modifiedDataAR);
    } catch (err) {
      console.error(err);
      setRows([]);
      toast.current.show({
        severity: "error",
        summary: "Kerkimi deshtoi.",
      });
    } finally {
      hideLoading();
    }
  };

  const allFis = async () => {
    showLoading();
    try {
      const response = await mainAxios(`/${contextKey}/fiskalizuara`);

      const modifiedDataAR = response.data.map((item) => ({
        ...item,
        Data: item.Data ? item.Data.split("T")[0] : "",
      }));

      setRows(modifiedDataAR);
    } catch (err) {
      console.error(err);
      setRows([]);
      toast.current.show({
        severity: "error",
        summary: "Kerkimi deshtoi.",
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <div className="buttonsFaturat">
      <div className="btn_fis">
        {fatureshitje && (
          <SplitButton
            options={[
              "Zgjidhni: ",
              "Te fiskalizuara EIC",
              "Te pafiskalizuara",
              "Te fiskalizuara",
            ]}
            onSelect={handleSplitButtonOptionClick}
          />
        )}
      </div>

      <div className="btn_fis">
        <Button
          label="Button 2"
          onClick={handleButton2Click}
          disabled={nmrPage <= 1}
          style={{ marginRight: 5 }}
        >
          <KeyboardDoubleArrowLeftIcon />
        </Button>

        <Button
          label="Button 1"
          onClick={handleButton1Click}
          disabled={properties.length < 20}
        >
          <KeyboardDoubleArrowRightIcon />
        </Button>
      </div>
    </div>
  );
};

export default ButtonGroup;
