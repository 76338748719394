import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300], // Adjust for dark mode
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function SettingsMenu({
  openSettings,
  setOpenSettings,
  isMobile,
}) {
  const [shfaqArtikujt, setArtikuj] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // Load the checkbox value from localStorage when the component mounts
  React.useEffect(() => {
    const savedValue = localStorage.getItem("shfaqArtikujt");
    if (savedValue !== null) {
      setArtikuj(JSON.parse(savedValue)); // Parse string value back to boolean
    }
  }, []);

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setArtikuj(isChecked);
    localStorage.setItem("shfaqArtikujt", JSON.stringify(isChecked)); // Save to localStorage
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenSettings(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSettings(false);
  };

  return (
    <div>
      {isMobile ? (
        ""
      ) : (
        <IconButton
          onClick={(event) => {
            handleClick(event);
          }}
        >
          {!openSettings ? (
            <SettingsIcon
              sx={{
                width: 25,
                height: 25,
                color: "#000",
                backgroundColor: "#dbdbdbaf",
                borderRadius: "10%",
              }}
            />
          ) : (
            <SettingsOutlinedIcon
              sx={{
                width: 25,
                height: 25,
                color: "#000",
                backgroundColor: "#dbdbdbaf",
                borderRadius: "10%",
              }}
            />
          )}
        </IconButton>
      )}
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disableRipple>
          <FormControlLabel
            control={
              <Checkbox
                type="checkbox"
                size="small"
                checked={shfaqArtikujt}
                onChange={handleCheckboxChange} // Handle change and save to localStorage
              />
            }
            label="Shfaq Artikujt"
          />
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
