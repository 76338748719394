import mainAxios from "../../../services/axios";

const incrementString = (str) => {
  const match = str.match(/\d+$/);
  const number = match ? parseInt(match[0]) : 0;
  const total = number + 1;
  const length = match ? match[0].length : 0;

  return str.replace(/\d+$/, total.toString().padStart(length, "0"));
};

const incrementKodi = async () => {
  try {
    // Fetch the latest data from the endpoint
    const response = await mainAxios.get("/latest_kodi");
    const { Kodi, Art_Kls01_Kodi, Tarifa_Kodi, Skema_Kodi, Njesi_Kodi, Tvsh } =
      response.data;

    // Increment the Kodi value
    const newKodi = incrementString(Kodi);

    // Return all the updated fields along with the incremented Kodi
    return {
      Kodi: newKodi,
      Art_Kls01_Kodi,
      Tarifa_Kodi,
      Skema_Kodi,
      Njesi_Kodi,
      Tvsh,
    };
  } catch (error) {
    console.error("Error fetching and updating Kodi:", error);
    // Handle errors as necessary, possibly return a default value or throw an error
    return {
      Kodi: "A01",
      Art_Kls01_Kodi: "",
      Tarifa_Kodi: "",
      Skema_Kodi: "",
      Njesi_Kodi: "",
      Tvsh: null,
    };
  }
};

export default incrementKodi;
