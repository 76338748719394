import React from "react";
import {
  TextField,
  Autocomplete,
  createFilterOptions,
  Button,
} from "@mui/material";
import { Checkbox } from "primereact/checkbox";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

const TabMainCelje = ({
  disabled,
  state,
  handleChange,
  setState,
  TextId,
  TextKodi,
  TextLlogari,
  DocId,
  TextNIPT,
  TextNipt,
  TextTarga,
  TextSkonto,
  TextGrupi,
  TextEmri,
  TextQyteti,
  TextPerdorues,
  TextFjalkalim,
  fromAgjenti,
  isFromAgent,
  btnGjenero,
  handleGenerateClick,
  fromPerdorues,
}) => {
  // redux llogari (get requesti i llogaris)
  const furnitorRedux = useSelector((state) => state.globalSlice.llogari);
  const llogari = furnitorRedux;

  const hapAutomatikisht = [
    { Pershkrim: "Administrator", Id: "1" },
    // { Pershkrim: "Operator Magazine", Id: "2" },
    // { Pershkrim: "Account", Id: "3" },
    // { Pershkrim: "Menaxher", Id: "4" },
    { Pershkrim: "Kasier", Id: "10" },
    { Pershkrim: "Banak", Id: "9" },
    // { Pershkrim: "Prodhim", Id: "7" },
    // { Pershkrim: "Raportues", Id: "8" },
  ];

  // Grupi
  const grupiPerdorues = [
    { Pershkrim: "Administrator", ID: "1" },
    { Pershkrim: "Operator Magazine", ID: "2" },
    { Pershkrim: "Account", ID: "3" },
    { Pershkrim: "Menaxher", ID: "4" },
    { Pershkrim: "Kasier", ID: "5" },
    { Pershkrim: "Banak", ID: "6" },
    { Pershkrim: "Prodhim", ID: "7" },
    { Pershkrim: "Raportues", ID: "8" },
  ];

  // mjetiID redux
  const mjetiID = [
    { Pershkrim: "NUIS / NIPT", Kodi: "0" },
    { Pershkrim: "ID Personale", Kodi: "1" },
    { Pershkrim: "Numri i Pashaportes", Kodi: "2" },
    { Pershkrim: "Numri i TVSH", Kodi: "3" },
    { Pershkrim: "Numri i tatimit", Kodi: "4" },
    { Pershkrim: "Numri i Sigurimeve Shoqerore", Kodi: "5" },
  ];

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  const handleChangeAktiv = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: key === "Aktiv" ? value : value,
      };
    });
  };

  return (
    <div className="grid gap-2 grid-cols-2 ">
      {TextKodi ? (
        <TextField
          disabled={disabled}
          onChange={(e) => handleChange("Kodi", e.target.value)}
          label="Kodi"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.Kodi || ""}
        />
      ) : (
        ""
      )}
      {TextGrupi ? (
        <Form.Select
          disabled={disabled}
          size="sm"
          value={state.Users_Grup_Id || ""}
          onChange={(e) => {
            handleChange("Users_Grup_Id", e.target.value);
          }}
          style={{ backgroundColor: "#fff" }}
        >
          <option label="Grupi" />
          {grupiPerdorues?.map((item) => {
            return (
              <option className="selekt" value={item.ID}>
                {item.Pershkrim}
              </option>
            );
          })}
        </Form.Select>
      ) : (
        ""
      )}

      {TextEmri ? (
        <TextField
          disabled={disabled}
          onChange={(e) => handleChange("Pershkrim", e.target.value)}
          label="Pershkrim"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.Pershkrim || ""}
        />
      ) : (
        ""
      )}

      {TextQyteti ? (
        <TextField
          disabled={disabled}
          onChange={(e) => handleChange("Qyteti", e.target.value)}
          label="Qyteti"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.Qyteti || ""}
        />
      ) : (
        ""
      )}
      {TextPerdorues ? (
        <TextField
          disabled={disabled}
          onChange={(e) => handleChange("Operator", e.target.value)}
          label="Perdorues"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.Operator || ""}
        />
      ) : (
        ""
      )}
      {TextFjalkalim ? (
        <TextField
          disabled={disabled}
          onChange={(e) => handleChange("Password", e.target.value)}
          label="Fjalekalim"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.Password || ""}
        />
      ) : (
        ""
      )}
      {TextId ? (
        <TextField
          disabled={true}
          onChange={(e) => handleChange("Id", e.target.value)}
          label="ID"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.Id || ""}
        />
      ) : (
        ""
      )}

      {btnGjenero && (
        <Button
          disabled={disabled || (state.Nipt && state.Nipt.length < 10)}
          onClick={handleGenerateClick}
          variant="contained"
        >
          Gjenero
        </Button>
      )}
      {TextNipt ? (
        <TextField
          disabled={disabled}
          type="text"
          inputProps={{ maxLength: "10" }}
          onChange={(e) => handleChange("Nipt", e.target.value)}
          label="Nipt"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.Nipt || ""}
        />
      ) : (
        ""
      )}

      {DocId ? (
        <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
          <Autocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-mjetiID"
            options={mjetiID}
            filterOptions={filterOptions}
            getOptionLabel={(option) => (option ? option.Pershkrim : "")}
            onChange={(e, newValue) =>
              handleChange("TipFirme_Kodi", newValue ? newValue.Kodi : "")
            }
            value={
              mjetiID.find((qytet) => qytet.Kodi === state?.TipFirme_Kodi) ||
              null
            }
            size="small"
            style={{ width: "100%", backgroundColor: "#fff" }}
            renderInput={(params) => (
              <TextField {...params} label="Dokumenti" />
            )}
            renderOption={(props, option) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <div style={{ textAlign: "start" }}>{option.Pershkrim}</div>
              </div>
            )}
          />
        </div>
      ) : (
        ""
      )}

      {TextNIPT ? (
        <TextField
          disabled={disabled}
          type="text"
          inputProps={{ maxLength: "10" }}
          onChange={(e) => handleChange("NIPT", e.target.value)}
          label="Nipt"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.NIPT || ""}
        />
      ) : (
        ""
      )}
      {TextSkonto ? (
        <TextField
          disabled={disabled}
          onChange={(e) => handleChange("Skonto", e.target.value)}
          label="Apliko Skonto"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.Skonto || ""}
        />
      ) : (
        ""
      )}

      {TextTarga && (
        <TextField
          disabled={disabled}
          type="text"
          onChange={(e) => handleChange("Targa_e_Mjetit", e.target.value)}
          label="Targa Mjetit"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.Targa_e_Mjetit || ""}
        />
      )}

      {isFromAgent && (
        <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
          <Autocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-llogari"
            options={llogari}
            filterOptions={filterOptions}
            getOptionLabel={(option) =>
              option ? option.Kodi + "-" + option.Pershkrim : ""
            }
            onChange={(e, newValue) =>
              handleChange("Nrll", newValue ? newValue.Kodi : "")
            }
            value={llogari.find((qytet) => qytet.Kodi === state?.Nrll) || ""}
            size="small"
            style={{ width: "100%", backgroundColor: "#fff" }}
            renderInput={(params) => <TextField {...params} label="Llogari" />}
            renderOption={(props, option) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
              </div>
            )}
          />
        </div>
      )}

      {TextLlogari && (
        <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
          <Autocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-llogari"
            options={llogari}
            filterOptions={filterOptions}
            getOptionLabel={(option) =>
              option ? option.Kodi + "-" + option.Pershkrim : ""
            }
            onChange={(e, newValue) =>
              handleChange("NrLL", newValue ? newValue.Kodi : "")
            }
            value={llogari.find((qytet) => qytet.Kodi === state?.NrLL) || ""}
            size="small"
            style={{ width: "100%", backgroundColor: "#fff" }}
            renderInput={(params) => <TextField {...params} label="Llogari" />}
            renderOption={(props, option) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
              </div>
            )}
          />
        </div>
      )}

      {fromPerdorues && (
        <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
          <Autocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-llogari"
            options={hapAutomatikisht}
            filterOptions={filterOptions}
            getOptionLabel={(option) =>
              option ? option.Pershkrim + "-" + option.Id : ""
            }
            onChange={(e, newValue) =>
              handleChange("HAPAUTOMATIKISHT", newValue ? newValue.Id : "")
            }
            value={
              hapAutomatikisht.find(
                (qytet) =>
                  parseInt(qytet.Id) === parseInt(state?.HAPAUTOMATIKISHT)
              ) || ""
            }
            size="small"
            style={{ width: "100%", backgroundColor: "#fff" }}
            renderInput={(params) => <TextField {...params} label="Hap Auto" />}
            renderOption={(props, option) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <div style={{ textAlign: "start" }}>{option.Pershkrim}</div>
                <div style={{ textAlign: "end" }}>{option.Id}</div>
              </div>
            )}
          />
        </div>
      )}

      <div className="flex">
        <Checkbox
          disabled={disabled}
          checked={state?.Aktiv === 1}
          onChange={(e) => {
            handleChangeAktiv("Aktiv", e.target.checked ? 1 : 0);
          }}
        ></Checkbox>
        <label
          style={{ color: "#1971c2", marginLeft: "5px" }}
          htmlFor="cb2"
          className="p-checkbox-label"
        >
          Aktiv
        </label>
      </div>
    </div>
  );
};

export default TabMainCelje;
