import React, { useState, useEffect, useRef } from "react";
import "../FatureThjeshtuar.scss";
import "./Artikuj.scss";
import mainAxios from "../../../../services/axios";
import { shtoArtikull } from "../shtoArtikull/CalcDataThjeshtuar";
import { useFtshContext } from "../../../../components/context/FtshState";
import { useUser } from "../../../../zustand/common";
import { Toast } from "primereact/toast";

const Artikuj = ({
  setRows,
  selectedKodi,
  dataArt,
  showLoading,
  hideLoading,
  rows,
}) => {
  const toast = useRef(null);
  const { user } = useUser();
  const operator = user.username;
  const { state } = useFtshContext();
  const [artikuj, setArtikuj] = useState([]);

  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      position: "top-center",
      detail: options.detail || "",
      life: options.life || 1000,
      closable: options.closable || false,
      ...options,
    });
  };

  useEffect(() => {
    if (dataArt.length > 0) {
      try {
        showLoading();
        setArtikuj(dataArt);
      } catch (error) {
        console.error(error);
      } finally {
        hideLoading();
      }

      return;
    }
    const fetchData = async () => {
      try {
        showLoading();
        const response = await mainAxios.get(
          `/art/by/klasifikim/${selectedKodi}`
        );
        setArtikuj(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        hideLoading();
      }
    };

    fetchData();
  }, [selectedKodi, dataArt]);

  const handleShtoArtikull = (artikull) => {
    shtoArtikull(
      artikull,
      rows,
      setRows,
      state,
      showToast,
      operator,
      showLoading,
      hideLoading
    );
  };

  return (
    <div className="artikuj_fature_thjeshtuar">
      <Toast ref={toast} position="top-center" />
      {artikuj && artikuj.length > 0 ? (
        <div className="artikuj_flex_row">
          {artikuj.map((item) => (
            <div
              key={item.Kodi}
              className="grupi-item"
              onClick={() => handleShtoArtikull(item)} // Passing the whole item here
            >
              {item.Pershkrim}
            </div>
          ))}
        </div>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default Artikuj;
