import React from "react";
import "./print80Skontrino.scss";

function FooterXhiro() {
  return (
    <tfoot>
      <tr>
        <td colSpan={5}>Gjeneruar nga Parid Smart Solution</td>
      </tr>
    </tfoot>
  );
}

export default FooterXhiro;
