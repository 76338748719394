import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Kerko from "../Kerko/Kerko";
import "../FatureThjeshtuar.scss";
import Pagesa from "../Pagesa/Pagesa";
import { formatDate } from "../../Function/formatDate";
import { useFtshContext } from "../../../../components/context/FtshState";
import { handleKlientiType } from "../Pagesa/handleKlientiType";
import { calculateData } from "../shtoArtikull/CalcDataThjeshtuar";
import { useUser } from "../../../../zustand/common";
import mainAxios from "../../../../services/axios";
import TabKlienti from "../Pagesa/Klient";
import TabPaguar from "../Pagesa/Paguar";

const Grid = ({
  rows,
  setRows,
  setDataArt,
  rowsArtikuj,
  showToast,
  hideLoading,
  showLoading,
}) => {
  const [tipiArkes, setTipiArkes] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [toggleState, setToggleState] = useState(1);
  const {
    state,
    setState,
    klienti,
    klientiRedux,
    menyrapageses,
    arka,
    qytetet,
    shtetet,
  } = useFtshContext();
  const { user } = useUser();
  const [paguar, setPaguar] = useState(false);

  const onCellEditComplete = async (e) => {
    const { rowData, newValue, rowIndex, field } = e;

    // Parse the new value if necessary
    const cellData =
      typeof rowData[field] === "number" ? parseFloat(newValue) : newValue;
    let updatedRow;

    // If editing "Pershkrim" column, skip calculateData and only update Pershkrim
    if (field === "Pershkrim") {
      updatedRow = { ...rowData, Pershkrim: cellData };
    } else {
      // Calculate new data based on the changed field for other fields
      updatedRow = calculateData({ [field]: cellData }, { ...rowData });
    }

    // Update the rows state with the new calculated values
    const updatedRows = [...rows];
    updatedRows[rowIndex] = updatedRow;
    setRows(updatedRows);

    try {
      showLoading();
      // Prepare the data for the PUT request based on the updated row
      const itemForRequest = {
        Master_Id: updatedRow.Master_Id,
        Pershkrim: updatedRow.Pershkrim,
        Vlera: updatedRow.Vlera,
        Sasia: updatedRow.Sasia,
        Tipi: updatedRow.Tipi,
        SkontoAgjent: updatedRow.SkontoAgjent,
        Cmimi: updatedRow.Cmimi,
        Operator: user.username,
        Vlera_Pa_Tvsh: updatedRow.Vlera_Pa_Tvsh,
        Kodi: updatedRow.Kodi,
        Tvsh: updatedRow.Tvsh,
        DateGarancie: updatedRow.DateGarancie,
        Cmimi_Pa_Tvsh: updatedRow.Cmimi_Pa_Tvsh,
        NrLL: updatedRow.NrLL,
        Shenim2: updatedRow.Shenim2,
        IdOld: updatedRow.IdOld,
        Skonto_VleraAgjent: updatedRow.Skonto_VleraAgjent,
        Art_ID: updatedRow.Art_ID,
        Punonjes_Id: updatedRow.Punonjes_Id,
        BarKod: updatedRow.BarKod,
        Njesi_Kodi: updatedRow.Njesi_Kodi,
        Skonto_Vlera: updatedRow.Skonto_Vlera,
        Total: updatedRow.Total,
        Magazina_Kodi: updatedRow.Magazina_Kodi,
        TipVlere_ID: updatedRow.TipVlere_ID,
        Tvsh_Vlera: updatedRow.Tvsh_Vlera,
        NrSerik: updatedRow.NrSerik,
        Cmimi_Kosto: updatedRow.Cmimi_Kosto,
        Skonto: updatedRow.Skonto,
      };

      // Send the PUT request to update the server data
      const response = await mainAxios.put(
        `/fature/shitje/dtl/${rowData.Id}`,
        itemForRequest
      );
      if (response.status !== 200) {
        showToast("Artikulli nuk u modifikua", { severity: "error" });
      }
    } catch (error) {
      console.error("Error updating row:", error);
    } finally {
      hideLoading();
    }
  };

  // Updated editable cell template to trigger onCellEditComplete onChange
  const editableCellTemplate = (rowData, field) => {
    return (
      <InputText
        value={rowData[field]}
        onChange={(e) =>
          onCellEditComplete({
            rowData,
            newValue: e.target.value,
            field,
            rowIndex: rows.indexOf(rowData), // Use indexOf to get row index
          })
        }
      />
    );
  };

  // Delete row function
  const deleteRow = async (rowData) => {
    try {
      showLoading();

      // Send the DELETE request to the server
      const response = await mainAxios.delete(
        `/fature/shitje/dtl/${rowData.Id}`
      );

      if (response.status === 200) {
        // If deletion is successful, update the local state
        const newData = rows.filter((item) => item.Id !== rowData.Id);
        setRows(newData);
      }
    } catch (error) {
      console.error("Error deleting row:", error);
      showToast("Gabim gjatë fshirjes së artikullit", { severity: "error" });
    } finally {
      hideLoading();
    }
  };

  // Action buttons template
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        label="Fshi"
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => deleteRow(rowData)}
      />
    );
  };

  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <>
        <Kerko setDataArt={setDataArt} rowsArtikuj={rowsArtikuj} />
        <Pagesa
          paguar={paguar}
          setPaguar={setPaguar}
          setToggleState={setToggleState}
        />
      </>
    </div>
  );

  const handleFunctionKlienti = (event, newValue) => {
    handleKlientiType(
      event,
      newValue,
      state,
      setSearchString,
      klientiRedux,
      setState
    );
  };

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  return (
    <div className="grid_fature_thjeshtuar">
      {paguar ? (
        <>
          <div className="header_paguar_fature_thjeshtuar"> {header}</div>
          <div className="flex">
            <div className="paguar_fature_thjeshtuar">
              <TabPaguar
                fatureType="Fature_Shitje_Id"
                setTipiArkes={setTipiArkes}
                setState={setState}
                showToast={showToast}
                popupContextKey="FatureShitje"
                formatDate={formatDate}
                toggleState={toggleState === 2}
                disabled={false}
                handleChange={handleChange}
                state={state}
                rows={rows}
                menyrapageses={menyrapageses}
                tipiArkes={tipiArkes}
                buttonClicked={false}
              />
            </div>

            <div className="paguar_fature_thjeshtuar">
              <TabKlienti
                qytetKlienti={true}
                nipt={true}
                shenim={true}
                disabled={false}
                state={state}
                toggleState={toggleState === 2}
                handleChange={handleChange}
                setSearchString={setSearchString}
                searchString={searchString}
                handleKlientiType={handleFunctionKlienti}
                qytetet={qytetet}
                shtetet={shtetet}
                klienti={klienti}
              />
            </div>
          </div>
        </>
      ) : (
        <DataTable
          className="datatable_fature_thjeshtuar"
          header={header}
          value={Array.isArray(rows) ? rows : []}
          responsiveLayout="scroll"
          scrollable
          scrollHeight="70%"
          footer={
            <div>
              <span
                style={{ fontSize: "1.6em", fontWeight: "bold", color: "#000" }}
              >
                Total:{" "}
              </span>
              <span
                style={{ fontSize: "1.4em", fontWeight: "bold", color: "red" }}
              >
                {rows
                  .reduce((sum, row) => sum + (row.Vlera || 0), 0)
                  .toFixed(0)}{" "}
                Lek{" "}
              </span>
            </div>
          }
        >
          <Column
            field="Pershkrim"
            header="Pershkrim"
            body={(rowData) => editableCellTemplate(rowData, "Pershkrim")}
            sortable
          />
          <Column
            field="Sasia"
            header="Sasia"
            body={(rowData) => editableCellTemplate(rowData, "Sasia")}
            sortable
          />
          <Column
            field="Cmimi"
            header="Cmimi"
            body={(rowData) => editableCellTemplate(rowData, "Cmimi")}
            sortable
          />
          <Column
            field="Vlera"
            header="Vlera"
            body={(rowData) => editableCellTemplate(rowData, "Vlera")}
            sortable
          />
          <Column body={actionBodyTemplate} header="Actions" />
        </DataTable>
      )}
    </div>
  );
};

export default Grid;
