import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import IconShopping from "./IconShopping";
import "../Main/MainComponent/MainComponent.scss";
import LeftBar from "../Main/LeftBar/LeftBar";
import ShtoKlient from "../Main/ShtoKlient/ShtoKlient";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SideBarPos({
  handleShtoArtikull,
  numTav,
  newId,
  menyra,
  setCurrentPorosia,
  printPorosia,
  isMobile,
  clickedItems,
  toggleDataTable,
  isDataTableOpen,
  showLoading,
  hideLoading,
  messageDisplay,
  konfigurimeRedux,
  mbylleTav,
  setPrintPorosia,
  rows,
  operator,
  nipt,
  ShtoKlient,
  PrintoFisPos,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button color="inherit" variant="outlined" onClick={handleClickOpen}>
        <IconShopping clickedItems={clickedItems} />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div
          className="sidebar_phone_main"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#ececec",
          }}
        >
          <AppBar
            sx={{
              position: "relative",
              ...(isMobile && {
                position: "sticky",
                top: 0,
                zIndex: 1000,
              }),
            }}
          >
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Tavolina: {numTav}
              </Typography>

              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <div className="mobile_leftbar">
            <LeftBar
              printPorosia={printPorosia}
              setCurrentPorosia={setCurrentPorosia}
              isMobile={isMobile}
              onItemClick={handleShtoArtikull}
              toggleDataTable={toggleDataTable}
              isDataTableOpen={isDataTableOpen}
              showLoading={showLoading}
              hideLoading={hideLoading}
              messageDisplay={messageDisplay}
              currentID={newId}
              konfigurimeRedux={konfigurimeRedux}
              handleClose={handleClose}
              mbylleTav={mbylleTav}
              setPrintPorosia={setPrintPorosia}
              rows={rows}
              operator={operator}
              menyra={menyra}
              nipt={nipt}
              ShtoKlient={ShtoKlient}
              PrintoFisPos={PrintoFisPos}
            />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
