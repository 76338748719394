import React from "react";
import "./print80Skontrino.scss";
import { formatDate } from "../../pages/POS/Restaurant/Main/DateUtils";

function Skontrino80({
  state,
  menyrapageses,
  fromftsh,
  // nipt,
  konfigurimeRedux,
}) {
  // Find the Menyra Pageses entry by ID and return its description

  const pagesaPershkrim = fromftsh
    ? menyrapageses?.find((menyraP) => menyraP.Id === state.Menyra_Pageses_ID)
        ?.Pershkrim
    : menyrapageses.find((menyraP) => menyraP.Id === state.Menyra_Pageses_Id)
        ?.Pershkrim;

  // Safely access the first element of konfigurimeRedux
  const ndermarrje =
    Array.isArray(konfigurimeRedux) && konfigurimeRedux.length > 0
      ? konfigurimeRedux[0]
      : null;

  // Handle the case where ndermarrje might be null or undefined
  if (!ndermarrje) {
    console.error("konfigurimeRedux is either not an array or it is empty.");
    return null;
  }

  return (
    <table className="hide-border">
      <thead>
        <tr className="flex theadMain" style={{ flexDirection: "column" }}>
          <td className="title1" colSpan={4}>
            {state.NSLF ? <h1>Fature Tatimore</h1> : <h1>Fature Porosi</h1>}
          </td>
          <td className="title1" colSpan={4}>
            {ndermarrje.Nipt}
          </td>
          <td className="tds-footer" colSpan={4}>
            {ndermarrje.Pershkrim}
          </td>
          <td className="tds-footer" colSpan={4}>
            {ndermarrje.Adresa1}
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>
          </td>
        </tr>
        <tr>
          <td className="columnsSkontrino">
            Data: {state.Mbyllur || state.Data || state.DATA}
          </td>
        </tr>
        <tr>
          <td className="columnsSkontrino">
            Operator: {state.fisOperatorCode}
            {" ("}
            {state.Operator}
            {")"}
          </td>
        </tr>
        {state.Tavolina && (
          <tr>
            <td className="columnsSkontrino">Tavolina: {state.Tavolina}</td>
          </tr>
        )}
        <tr>
          <td className="columnsSkontrino">
            Menyra e Pageses: {pagesaPershkrim}
          </td>
        </tr>
        {state.KLFU_Pershkrim && (
          <tr>
            <td className="columnsSkontrino" colSpan={4}>
              Klienti: {state.KLFU_Pershkrim}
            </td>
          </tr>
        )}
      </thead>
      <tbody>
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Skontrino80;
