// SmallButtonGroup.js
import React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import "../fature-blerje/Function/SmallButton.scss";
import DeleteModalCelje from "../celje/ModalCelje/DeleteModalC";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
const SmallButtonGroup = ({
  setDisabled,
  disabled,
  noDeleteBtn,
  setState,
  defaultState,
  submitHandler,
  deleteMethod,
  selectedRowId,
  buttonClicked,
  setButtonClicked,
  state,
  setCurrentAction,
  isMobile,
  lastData,
}) => {
  const [openModal, setOpenModal] = React.useState(false);

  const handleButtonClick = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirm = () => {
    setOpenModal(false);
    // Add your function calls here
    setButtonClicked(false);
    setDisabled(true);
    deleteMethod(selectedRowId);
  };
  return (
    <ButtonGroup className="mb-2" style={{ width: "100%" }}>
      <Button
        className="button_tab"
        onClick={(e) => {
          e.preventDefault();
          setState(defaultState);
          setDisabled(false);
          setButtonClicked(true);
          setCurrentAction("add");
        }}
        disabled={buttonClicked}
      >
        <PostAddIcon />
        {isMobile ? "" : "Shtim"}
      </Button>
      <Button
        className="button_tab"
        onClick={(e) => {
          e.preventDefault();
          setDisabled(false);
          setButtonClicked(true);
          setCurrentAction("modify");
        }}
        disabled={typeof selectedRowId === "undefined" || buttonClicked}
      >
        <ModeEditIcon />
        {isMobile ? "" : "Modifikim"}
      </Button>

      {noDeleteBtn ? (
        ""
      ) : (
        <Button
          className="button_tab"
          onClick={handleButtonClick}
          disabled={!selectedRowId}
        >
          <DeleteIcon />
          {isMobile ? "" : "Fshije"}
        </Button>
      )}
      <DeleteModalCelje
        open={openModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
        state={state}
      />
      <Button
        className="button_tab"
        onClick={(e) => {
          e.preventDefault();
          setState(lastData);
          setButtonClicked(false);
          setDisabled(true);
          setCurrentAction(null);
        }}
        disabled={disabled}
      >
        <ClearIcon />
        {isMobile ? "" : "Anullim"}
      </Button>
      <Button
        className="button_tab"
        onClick={(e) => {
          e.preventDefault();
          submitHandler();
        }}
        disabled={disabled}
        type="submit"
      >
        <AppRegistrationIcon />
        {isMobile ? "" : "Regjistro"}
      </Button>
    </ButtonGroup>
  );
};

export default SmallButtonGroup;
