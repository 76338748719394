import React, { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useUser } from "../../zustand/common";

// Create a context
const FtbContext = createContext();

// Create a provider for the context
export const FtbProvider = ({ children }) => {
  const { user } = useUser();
  const username = user.username;

  // redux furnitor (get requesti i furnitorit)
  const furnitorRedux = useSelector((state) => state.globalSlice.furnitor);
  const furnitor = furnitorRedux;

  // qyteti redux
  const qytetetRedux = useSelector((state) => state.globalSlice.qytetet);
  const qytetet = qytetetRedux;

  // shteti redux
  const shtetetRedux = useSelector((state) => state.globalSlice.shtetet);
  const shtetet = shtetetRedux.map((item) => item.Kodi);

  // redux magazina (get requesti i Magazines)
  const magazina = useSelector((state) => state.globalSlice.magazina);
  const defaultMag = magazina.map((item) => item.Kodi);

  // monedha redux
  const monedhat = useSelector((state) => state.globalSlice.monedha);
  const defaultMon = monedhat.map((item) => item.Kodi);
  const defaultMonb = defaultMon[0];
  const defaultKursi = monedhat.map((item) => item.Kursi);

  // redux menyra (get requesti i Menyres-Pageses)
  const menyrapageses = useSelector((state) => state.globalSlice.menyraPageses);
  const defaultPagesa = menyrapageses.map((item) => item.Id);

  // redux arka/banka (get requesti i Arka/Banka)
  const arka = useSelector((state) => state.globalSlice.arkabanka);

  let defaultState = {
    KLFU_Pershkrim: (furnitor[0] && furnitor[0].Pershkrim) || "",
    KLFU_Kodi: (furnitor[0] && furnitor[0].Kodi) || "",
    Data: "",
    Mon: defaultMonb,
    NIPT: (furnitor[0] && furnitor[0].Nipt) || "",
    Qyteti_Kodi: (furnitor[0] && furnitor[0].Qyteti_Kodi) || "",
    Shenim: "",
    Operator: username,
    Menyra_Pageses_Id: defaultPagesa[0],
    Arka_Banka_Kodi: (arka[0] && arka[0].Kodi) || "",
    Paguar: "",
    Afati_PagesesData: "",
    Afati_PagesesDite: "",
    Kursi: defaultKursi[0] || "",
    Kodi_ORG: "",
    Kodi: "",
    NrSerik: "",
    Klasifikim1_ID: null,
    Klasifikim2_ID: null,
    Klasifikim3_ID: null,
    Klasifikim4_ID: null,
    Tip_Cmimi: null,
    Magazina_Kodi: defaultMag[0] || "",
    Veprimi: null,
    Data_Deklarimit: "",
    Dep_Kodi: "",
  };

  const [state, setState] = useState(defaultState);

  return (
    <FtbContext.Provider
      value={{
        state,
        setState,
        furnitorRedux,
        qytetet,
        shtetet,
        defaultState,
        menyrapageses,
        furnitor,
        monedhat,
        arka,
      }}
    >
      {children}
    </FtbContext.Provider>
  );
};

// Custom hook to use the context
export const useFtbContext = () => useContext(FtbContext);
