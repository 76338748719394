import React from "react";
import { MultiSelect } from "primereact/multiselect";

export default function MultiDemoSelect({
  columns,
  onColumnToggle,
  filterdColumns,
}) {
  return (
    <div>
      <MultiSelect
        value={filterdColumns}
        options={columns}
        optionLabel="title"
        onChange={onColumnToggle}
        style={{ width: "3em", height: "2rem", marginLeft: "2px" }}
      />
    </div>
  );
}
