// HeaderDesc.js
import React from "react";
import Button from "@mui/material/Button";
import { MultiSelect } from "primereact/multiselect";
import { TextField } from "@mui/material";
import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";

const HeaderDesc = ({
  dataNga,
  setDataNga,
  dataDeri,
  setDataDeri,
  performSearch,
  selectedColumns,
  columns,
  onColumnToggle,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div className="button_header_datatable_shikoFaturat">
        <TextField
          style={{ height: "100%" }}
          type="date"
          label="Nga Data"
          InputLabelProps={{
            shrink: true,
          }}
          value={dataNga}
          onChange={(e) => setDataNga(e.target.value)}
        />

        <TextField
          style={{ height: "100%" }}
          type="date"
          label="Deri Date"
          InputLabelProps={{
            shrink: true,
          }}
          value={dataDeri}
          onChange={(e) => setDataDeri(e.target.value)}
          // style={isMobile ? { width: "50px" } : ""}
        />

        <Button
          variant="contained"
          style={{ height: "100%" }}
          disabled={!dataNga || !dataDeri}
          onClick={(e) => {
            e.preventDefault();
            performSearch();
            // setDataNga("");
            // setDataDeri("");
          }}
        >
          <ManageSearchRoundedIcon />
        </Button>
      </div>

      <MultiSelect
        style={{ height: "100%", width: "3em" }}
        // className="selectFature"
        value={selectedColumns}
        options={columns}
        optionLabel="title"
        onChange={onColumnToggle}
      />
    </div>
  );
};

export default HeaderDesc;
