import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import TextFieldsTePergjithshme from "./TextFieldsTePergjithshme";
import "./TePergjithshme.scss";

const TePergjithshme = ({
  isMobile,
  disabled,
  state,
  setState,
  handleChangeTarifa,
  updateRowData,
  isEditingAllowed,
}) => {
  const handleChangeAktiv = (key, value) => {
    const updatedRowData = { [key]: value };
    if (isEditingAllowed) {
      updateRowData(updatedRowData);
    }

    setState((state) => {
      return {
        ...state,
        [key]: key === "Aktiv" ? (value ? 1 : 0) : value,
        [key]: key === "Shumice" ? (value ? 1 : 0) : value,
        [key]: key === "Pos_NeList" ? (value ? 1 : 0) : value,
        [key]: key === "Pakice" ? (value ? 1 : 0) : value,
        [key]: key === "Distribucion" ? (value ? 1 : 0) : value,
        [key]: key === "AAM" ? (value ? 1 : 0) : value,
      };
    });
  };

  const handleChange = (key, value) => {
    if (isEditingAllowed) {
      let updatedRowData;
      if (value === "" || value === undefined || value === null) {
        updatedRowData = { [key]: null };
      } else {
        updatedRowData = { [key]: value };
      }

      if (state[key] === updatedRowData[key]) {
        updateRowData(updatedRowData);
      } else {
        updateRowData(updatedRowData);
      }
    } else {
      setState((state) => ({
        ...state,
        [key]: value,
      }));
    }
  };

  const [initialLength, setInitialLength] = useState(null);

  return (
    <div className={`second_div flex ${isMobile ? "flex-col" : ""} `}>
      <TextFieldsTePergjithshme
        state={state}
        setState={setState}
        disabled={disabled}
        handleChange={handleChange}
        handleChangeTarifa={handleChangeTarifa}
        updateRowData={updateRowData}
        isEditingAllowed={isEditingAllowed}
        initialLength={initialLength}
        setInitialLength={setInitialLength}
      />

      <div className={`second-border-pergjithshme border flex gap-3 resizable`}>
        <div className="kodi_box relative">
          <span className="p-float-label ">
            <InputText
              disabled={disabled}
              value={state.Kodi2 || ""}
              onFocus={(e) => setInitialLength(e.target.value.length)}
              onChange={(e) => setState({ ...state, Kodi2: e.target.value })}
              onBlur={(e) => {
                if (initialLength !== e.target.value.length) {
                  handleChange("Kodi2", e.target.value);
                }
              }}
              className="input_kodi"
              id="Kodi_2"
            />
            <label>Kodi 2</label>
          </span>

          <span className="p-float-label ">
            <InputText
              disabled={disabled}
              value={state.Kodi3 || ""}
              onFocus={(e) => setInitialLength(e.target.value.length)}
              onChange={(e) => setState({ ...state, Kodi3: e.target.value })}
              onBlur={(e) => {
                if (initialLength !== e.target.value.length) {
                  handleChange("Kodi3", e.target.value);
                }
              }}
              className="input_kodi"
              id="Kodi_3"
            />
            <label>Kodi 3</label>
          </span>

          <span className="p-float-label ">
            <InputText
              disabled={disabled}
              value={state.Kodi4 || ""}
              onFocus={(e) => setInitialLength(e.target.value.length)}
              onChange={(e) => setState({ ...state, Kodi4: e.target.value })}
              onBlur={(e) => {
                if (initialLength !== e.target.value.length) {
                  handleChange("Kodi4", e.target.value);
                }
              }}
              className="input_kodi"
              id="Kodi_4"
            />
            <label>Kodi 4</label>
          </span>

          <span className="p-float-label ">
            <InputText
              disabled={disabled}
              value={state.Kodi5 || ""}
              onFocus={(e) => setInitialLength(e.target.value.length)}
              onChange={(e) => setState({ ...state, Kodi5: e.target.value })}
              onBlur={(e) => {
                if (initialLength !== e.target.value.length) {
                  handleChange("Kodi5", e.target.value);
                }
              }}
              className="input_kodi"
              id="Kodi_5"
            />
            <label>Kodi 5</label>
          </span>
        </div>

        <div className="checkbox_box border">
          <div className="flex items-center">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.Aktiv === 1}
              onChange={(e) =>
                handleChangeAktiv("Aktiv", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "10px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Aktiv
            </label>
          </div>

          <div className="flex items-center">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.Shumice === 1}
              onChange={(e) =>
                handleChangeAktiv("Shumice", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb4"
              className="p-checkbox-label"
            >
              Shumice
            </label>
          </div>

          <div className="flex items-center">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.Pakice === 1}
              onChange={(e) =>
                handleChangeAktiv("Pakice", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb6"
              className="p-checkbox-label"
            >
              Pakice
            </label>
          </div>

          <div className="flex items-center">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.Pos_NeList === 1}
              onChange={(e) =>
                handleChangeAktiv("Pos_NeList", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb8"
              className="p-checkbox-label"
            >
              Pos Bar
            </label>
          </div>

          <div className="flex items-center">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.Distribucion === 1}
              onChange={(e) =>
                handleChangeAktiv("Distribucion", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb12"
              className="p-checkbox-label"
            >
              Distribucion
            </label>
          </div>

          <div className="flex items-center mb-3">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.AAM === 1}
              onChange={(e) =>
                handleChangeAktiv("AAM", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb12"
              className="p-checkbox-label"
            >
              AAM
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TePergjithshme;
