// color.js
const colors = {
  light: {
    background: "rgb(236, 236, 236)",
  },
  dark: {
    background: "#455a64",
  },
};

export default colors;
