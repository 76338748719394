import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from "@mui/material";
import InputIcon from "@mui/icons-material/Input";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import mainAxios from "../../../../../services/axios";
import { setAuthToken } from "../../../../../services/axios";
import { getCookie } from "../../../../../services/helpers";
import { useUser } from "../../../../../zustand/common";
import { LoadingOverlayContext } from "../../../../../components/LoadingOverlay";

const DefaultPage = ({ setDefaultPage, showToast, setUserAutomatikisht }) => {
  const { login } = useUser();
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [popupOpen, setPopupOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState([]);

  // Handle keyboard input
  const handleKeyPress = (event) => {
    // Only allow numeric keys and backspace
    if (event.key >= "0" && event.key <= "9") {
      setPassword((prevPassword) => prevPassword + event.key);
    } else if (event.key === "Backspace") {
      setPassword((prevPassword) => prevPassword.slice(0, -1));
    }
  };

  useEffect(() => {
    if (popupOpen) {
      // Add event listener when dialog is open
      window.addEventListener("keydown", handleKeyPress);
    } else {
      // Clean up event listener when dialog is closed
      window.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [popupOpen]);

  const handleOpenDialog = () => {
    fetchDataPerdorues();
    setPopupOpen(true);
  };

  const fetchDataPerdorues = async () => {
    try {
      const response = await mainAxios("/get/perdoruesi");
      if (response.status === 200) {
        setUserData(response.data);
      }
    } catch (error) {
      console.error("Login failed:", error);
      showToast("Te dhenat gabim");
    }
  };

  const handlePasswordCheck = async () => {
    const user = userData?.find((user) => user.Password === password); // Find the matching user

    if (user) {
      const nipt = user.Ndermarrje_Sel;
      const password = user.Password;
      const username = user.Pershkrim;
      try {
        showLoading();

        const res = await login({ username, nipt, password });

        if (res.success) {
          setAuthToken(getCookie("access_token"));
          const { automatikisht } = res;
          setUserAutomatikisht(automatikisht);
          setPassword("");
          setDefaultPage(false);
          setPopupOpen(false);
        } else {
          showToast("Te dhenat jane gabim");
        }
      } catch (error) {
        showToast("Incorrect password. Please try again.");
        setPassword("");
      } finally {
        hideLoading();
      }
    } else {
      showToast("Incorrect password. Please try again.");
      setPassword(""); // Clear password input after incorrect entry
    }
  };

  const handleEnterPress = (event) => {
    // Check for Enter key press
    if (event.key === "Enter") {
      handlePasswordCheck(); // Submit on Enter key
    }
  };

  const handleNumberClick = (num) => {
    setPassword((prevPassword) => prevPassword + num); // Append number to the password
  };

  const handleBackspace = () => {
    setPassword((prevPassword) => prevPassword.slice(0, -1)); // Remove last character
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState); // Toggle password visibility state
  };

  return (
    <div className="center-content">
      <Button
        startIcon={<InputIcon />}
        size="large"
        onClick={handleOpenDialog} // Open dialog on click
        style={{
          backgroundColor: "#11999E",
          color: "#fff",
          padding: "16px 32px",
          fontSize: "1.25rem",
          height: "64px",
          borderRadius: "8px",
        }}
      >
        Hyrje
      </Button>

      {/* Dialog for Password */}
      <Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
        <DialogTitle>Venosni Password-in</DialogTitle>
        <DialogContent>
          <div className="flex">
            <TextField
              style={{ marginTop: "10px" }}
              type={showPassword ? "text" : "password"} // Toggle between text and password type
              label="Password"
              value={password}
              fullWidth
              onKeyDown={handleEnterPress}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </Button>
                ),
              }}
            />
            <Button
              fullWidth
              variant="outlined"
              onClick={handleBackspace}
              style={{
                fontSize: "1.5rem",
                height: "52px",
                width: "50px",
                marginTop: "10px",
                marginLeft: "10px",
                backgroundColor: "#f44336",
              }}
            >
              <strong style={{ color: "#fff" }}>←</strong>
            </Button>
          </div>

          {/* Numeric keypad */}
          <Grid container spacing={1} style={{ marginTop: "20px" }}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
              <Grid item xs={4} key={num}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => handleNumberClick(num)}
                  style={{ fontSize: "1.5rem", height: "50px" }}
                >
                  {num}
                </Button>
              </Grid>
            ))}

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setPopupOpen(false)}
                style={{
                  fontSize: "1.5rem",
                  height: "50px",
                  backgroundColor: "red",
                  color: "#fff",
                }}
              >
                Anullim
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => handleNumberClick(0)}
                style={{ fontSize: "1.5rem", height: "50px" }}
              >
                0
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handlePasswordCheck}
                style={{
                  fontSize: "1.5rem",
                  height: "50px",
                  backgroundColor: "green",
                  color: "#fff",
                }}
              >
                Hyrje
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DefaultPage;
