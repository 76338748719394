import React from "react";
import "./print80Skontrino.scss";

function Xhiro({ selectedOperator }) {
  return (
    <table className="hide-border">
      <thead>
        <tr className="flex " style={{ flexDirection: "column" }}>
          <td className="title1" colSpan={4}>
            <b>Xhiroja ditore sipas Artikujve</b>
          </td>
        </tr>
        <tr className="flex " style={{ flexDirection: "column" }}>
          <td className="title1" colSpan={4}>
            <b>Operator: {selectedOperator}</b>
          </td>
        </tr>
      </thead>
    </table>
  );
}

export default Xhiro;
