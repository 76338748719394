const shtoArtikull = async (
  artikull,
  showLoading,
  dtlState,
  setDtlState,
  newId,
  currentPorosia,
  operator,
  showToast,
  hideLoading,
  state
) => {
  // Calculate the initial item
  const initialCmimi = artikull.Cmimi;
  const initialTotal = initialCmimi * state.Kursi;

  const newItem = {
    ...artikull,
    Sasia: 1,
    Tvsh: artikull.Tvsh ?? 20,
    Cmimi: initialCmimi,
    BarKod: artikull.Barkod,
    Skonto: 0,
    Cmimi_me_skonto: 0,
    Cmimi_Pa_Tvsh: 0,
    Vlera: 0,
    Tvsh_Vlera: 0,
    Operator: `${operator}`,
    Skonto_Vlera: 0,
    Vlera_Pa_Tvsh: 0,
    Total: initialTotal,
  };

  // Check if the item already exists in dtlState
  const itemIndex = dtlState.findIndex((item) => item.Id === artikull.Id);

  if (itemIndex !== -1) {
    // If the item exists, increment the Sasia and recalculate the item
    const updatedItem = {
      ...dtlState[itemIndex],
      Sasia: dtlState[itemIndex].Sasia + 1, // Increment Sasia
    };

    // Recalculate item values
    const calculatedItem = calculateData(
      { Sasia: updatedItem.Sasia },
      updatedItem
    );

    // Update the state with the new values
    setDtlState((prevRows) =>
      prevRows.map((item, index) =>
        index === itemIndex ? calculatedItem : item
      )
    );
  } else {
    // If the item doesn't exist, calculate it as a new item
    const calculatedNewItem = calculateData({ Cmimi: newItem.Cmimi }, newItem);

    // Add the new item to the state
    setDtlState((prevRows) => [calculatedNewItem, ...prevRows]);
  }
};
// -----------------------------------------------------------------------------------------------------------------------------------------

const calculateData = (field, item) => {
  let {
    Tvsh,
    Cmimi_Pa_Tvsh,
    Sasia,
    Skonto,
    Cmimi,
    Tvsh_Vlera,
    Skonto_Vlera,
    Vlera_Pa_Tvsh,
    Cmimi_me_skonto,
    Vlera,
    Total,
  } = item;

  let tvsh, VLSKONTO, cm_paTVSH, sasia;

  tvsh = (Tvsh + 100) / 100;

  const [[cell, cellVal]] = Object.entries(field);
  // Formulat e kontabilitetit
  // ka nevoje per modifikim shto handle change e.g item.Skonto
  try {
    let CmimiPrv = Number(cell === "Cmimi" ? cellVal : Cmimi);
    let SkontoPrv = Number(cell === "Skonto" ? cellVal : Skonto);
    let SasiaPrv = Number(cell === "Sasia" ? cellVal : Sasia);
    cm_paTVSH = CmimiPrv / tvsh;
    sasia = SasiaPrv;
    VLSKONTO = (sasia * cm_paTVSH * SkontoPrv) / 100;

    if (cell === "Skonto") {
      Skonto = cellVal;
      Vlera = (sasia * cm_paTVSH - VLSKONTO) * tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Skonto_Vlera = VLSKONTO;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    }
    if (cell === "Cmimi") {
      Cmimi = cellVal;
      Vlera = (sasia * cm_paTVSH - VLSKONTO) * tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = CmimiPrv * (1 - Skonto / 100);
      Skonto_Vlera = VLSKONTO;
    }
    if (cell === "Sasia") {
      Sasia = cellVal;
      Vlera = (sasia * cm_paTVSH - VLSKONTO) * tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
      Skonto_Vlera = VLSKONTO;
    }
    if (cell === "Vlera") {
      Vlera = cellVal;
      Skonto = 0;
      Skonto_Vlera = 0;
      Cmimi = Vlera / Math.abs(sasia);
      cm_paTVSH = Cmimi / tvsh;
      VLSKONTO = 0;
      Tvsh_Vlera = Vlera - Vlera / tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    }
    if (cell === "Skonto_Vlera") {
      Skonto_Vlera = cellVal;
      VLSKONTO = Skonto_Vlera;
      Skonto = (VLSKONTO * 100) / (sasia * cm_paTVSH);
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Vlera = Vlera_Pa_Tvsh * tvsh;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    }
    if (cell === "Cmimi_Pa_Tvsh") {
      Cmimi_Pa_Tvsh = cellVal;
      tvsh = Tvsh / 100; //=0.2
      VLSKONTO = (Sasia * Cmimi_Pa_Tvsh * Skonto) / 100;
      Vlera_Pa_Tvsh = Cmimi_Pa_Tvsh * Sasia;
      Vlera = (Vlera_Pa_Tvsh - VLSKONTO) * (1 + tvsh);
      if (sasia !== 0) {
        Cmimi = Math.abs(Vlera / Sasia);
      }
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
      Skonto_Vlera = VLSKONTO;
      Tvsh_Vlera = (Vlera_Pa_Tvsh - VLSKONTO) * tvsh;
    }
    if (cell === "Tvsh") {
      Tvsh = cellVal;
      tvsh = Tvsh / 100; //0.2
      VLSKONTO = (Sasia * Cmimi_Pa_Tvsh * Skonto) / 100;
      Cmimi = (Cmimi_Pa_Tvsh - VLSKONTO / Math.abs(Sasia)) * (1 + tvsh);
      Vlera = Sasia * Cmimi;
      Tvsh_Vlera = Vlera_Pa_Tvsh * tvsh;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    }
    Total = Vlera * 1;

    // Round values to 1 decimal place
    Vlera = parseFloat(Vlera.toFixed(1));
    Cmimi = parseFloat(Cmimi.toFixed(1));
    Total = parseFloat(Total.toFixed(1));
    Tvsh_Vlera = parseFloat(Tvsh_Vlera.toFixed(1));
    Skonto_Vlera = parseFloat(Skonto_Vlera.toFixed(1));
    Vlera_Pa_Tvsh = parseFloat(Vlera_Pa_Tvsh.toFixed(1));
    Cmimi_Pa_Tvsh = parseFloat(Cmimi_Pa_Tvsh.toFixed(1));
    Cmimi_me_skonto = parseFloat(Cmimi_me_skonto.toFixed(1));

    return {
      ...item,
      Tvsh,
      Cmimi_Pa_Tvsh,
      Cmimi_me_skonto,
      Sasia,
      Skonto,
      Cmimi,
      Tvsh_Vlera,
      Skonto_Vlera,
      Vlera_Pa_Tvsh,
      Vlera,
      Total,
    };
  } catch (error) {
    Vlera = 0;
    return item;
  }
};
export { shtoArtikull, calculateData };
