//Ploteson fushat e tjera nqs Zgjedh nje X-Furnitor
//Dmth nqs zgjedh F003 => Nipt (L61918004H)
//F003 => Furnitor (Ahmetaj )

const handleFurnitorType = async (
  event,
  value,
  setSearchString,
  furnitorRedux,
  setState
) => {
  setSearchString(value ? value.Kodi : "");
  if (value && value.Kodi) {
    try {
      const selectedFurnitor = furnitorRedux.find(
        (item) => item.Kodi === value.Kodi
      );

      if (selectedFurnitor) {
        setState((state) => ({
          ...state,
          KLFU_Kodi: selectedFurnitor.Kodi,
          KLFU_Pershkrim: selectedFurnitor.Pershkrim,
          NIPT: selectedFurnitor.Nipt,
          Qyteti_Kodi: selectedFurnitor.Qyteti_Kodi,
        }));
      } else {
        // If selectedFurnitor is not found, clear the text fields
        setState((state) => ({
          ...state,
          KLFU_Kodi: value,
          KLFU_Pershkrim: "",
          NIPT: "",
          Qyteti_Kodi: "",
        }));
      }
    } catch (err) {
      console.error(err);
    }
  } else {
    // If value is empty or value.Kodi is falsy, clear all fields
    setState((state) => ({
      ...state,
      KLFU_Kodi: "",
      KLFU_Pershkrim: "",
      NIPT: "",
      Qyteti_Kodi: "",
    }));
  }
};

export { handleFurnitorType };
