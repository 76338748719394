import React from "react";
import "./printSkontrinoXhiro.css";

function SkontrinoXhiroMain({ state, menyrapageses, nipt }) {
  // Find the Menyra Pageses entry by ID and return its description
  const pagesaPershkrim = menyrapageses?.find(
    (menyraP) => menyraP.Id === state.Menyra_Pageses_Id
  )?.Pershkrim;

  // Format the date to remove "T" from date-time strings
  const formatDate = (dateString) => {
    if (!dateString) return "";
    // Remove "T" and milliseconds
    return dateString.replace("T", " ").split(".")[0];
  };

  return (
    <div className="print_div">
      <table className="hide-border">
        <thead>
          <tr className="flex tr_header" style={{ flexDirection: "column" }}>
            <td className="title1" colSpan={4}>
              {state.NSLF ? "Fature Tatimore" : "Fature Porosi"}
            </td>
            <td className="tds-footer" colSpan={4}>
              {nipt}
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <hr className="dashed-line" />
            </td>
          </tr>
          <tr>
            <td className="columnsSkontrino">
              Data dhe Ora :{" "}
              {formatDate(
                state.UPDATED ||
                  state.Updated ||
                  state.Hapur ||
                  state.DATA ||
                  state.Data
              )}
            </td>
          </tr>
          {state?.TAVOLINA && (
            <tr>
              <td className="columnsSkontrino">Tavolina: {state?.TAVOLINA}</td>
            </tr>
          )}
          <tr>
            <td className="columnsSkontrino">
              Menyra e Pageses: {pagesaPershkrim}
            </td>
          </tr>
          <tr>
            <td className="columnsSkontrino" colSpan={4}>
              Operator:{" "}
              {state.fisOperatorCode
                ? state.fisOperatorCode
                : state.Operator || state.OPERATOR}
            </td>
          </tr>
          {state.KLFU_PERSHKRIM && (
            <tr>
              <td className="columnsSkontrino" colSpan={4}>
                Klienti: {state.KLFU_PERSHKRIM}
              </td>
            </tr>
          )}
        </thead>
      </table>
    </div>
  );
}

export default SkontrinoXhiroMain;
