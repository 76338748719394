import React, { useContext, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { useUser } from "../../../zustand/common";
import Clock from "../../POS/SuperMarket/NewSuperMarket/Clock/Clock";
import SettingsMenu from "./MenuSettings/Main";
import Buttons from "./Buttons/Buttons";
import Grid from "./Grid/Grid";
import Artikuj from "./Artikuj/Artikuj";
import Grupet from "./Grupet/Grupet";
import { Toast } from "primereact/toast";
import "./FatureThjeshtuar.scss";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";
import { useFtshContext } from "../../../components/context/FtshState";
import GridLista from "./Grid/GridLista";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FatureThjeshtuar({ open, onClose, isMobile }) {
  const toast = useRef(null);
  const { state, setState } = useFtshContext();
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [listaDisplay, setListaDisplay] = useState(false);
  const [rowsArtikuj, setRowsArtikuj] = useState([]);
  const [selectedKodi, setSelectedKodi] = useState(null);
  const [dataArt, setDataArt] = useState([]);
  const { user } = useUser();
  const operator = user.username;

  const [rows, setRows] = useState([]);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullscreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      });
    }
  };

  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 1000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: "#ececec",
            boxShadow: "none",
            color: "#000",
            height: "6%",
          }}
        >
          <Toolbar>
            <Typography
              sx={{
                ml: 2,
                display: "flex",
                flexDirection: "row",
                color: "#000",
                fontWeight: "600",
              }}
              component="div"
            >
              <div className="tavolina_Toolbar">Operator: {operator}</div>
              <ChevronRightIcon sx={{ color: "#B7B7B7" }} />
              <div className="tavolina_Toolbar">
                Fature ID: {state.Id || "Nuk keni asnje fature te selektuar"}
              </div>
              {/* <ChevronRightIcon sx={{ color: "#B7B7B7" }} />
              <div className="tavolina_Toolbar">Grupi: {operator}</div> */}
            </Typography>

            <Typography
              sx={{ ml: 2, mr: 2, flex: 2, color: "#000" }}
              component="div"
            ></Typography>
            <Clock />
            <SettingsMenu
              isMobile={isMobile}
              openSettings={openSettings}
              setOpenSettings={setOpenSettings}
            />
            {isMobile ? (
              ""
            ) : (
              <IconButton
                onClick={() => {
                  toggleFullscreen();
                }}
                sx={{ mr: 1 }}
              >
                {!isFullscreen ? (
                  <OpenInFullIcon
                    sx={{
                      width: 25,
                      height: 25,
                      color: "#000",
                      backgroundColor: "#dbdbdbaf",
                      borderRadius: "10%",
                    }}
                  />
                ) : (
                  <CloseFullscreenIcon
                    sx={{
                      width: 25,
                      height: 25,
                      color: "#000",
                      backgroundColor: "#dbdbdbaf",
                      borderRadius: "10%",
                    }}
                  />
                )}
              </IconButton>
            )}
            <IconButton
              edge={false}
              color="inherit"
              onClick={onClose}
              aria-label="close"
              sx={{
                width: 30,
                height: 30,
                color: "#000",
                backgroundColor: "#dbdbdbaf",
                borderRadius: "10%",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="mainComponent_fature_thjeshtuar">
          <Toast ref={toast} />
          <div className="main_fature_thjeshtuar">
            {listaDisplay ? (
              <GridLista
                showToast={showToast}
                setListaDisplay={setListaDisplay}
                setDtlState={setRows}
                setState={setState}
              />
            ) : (
              <>
                <div className="header_fature_thjeshtuar">
                  <Grupet setSelectedKodi={setSelectedKodi} />
                  <Artikuj
                    rows={rows}
                    setRows={setRows}
                    dataArt={dataArt}
                    selectedKodi={selectedKodi}
                    setRowsArtikuj={setRowsArtikuj}
                    hideLoading={hideLoading}
                    showLoading={showLoading}
                  />
                </div>
                <div className="table_fature_thjeshtuar">
                  <Grid
                    hideLoading={hideLoading}
                    showLoading={showLoading}
                    showToast={showToast}
                    rows={rows}
                    setRows={setRows}
                    setDataArt={setDataArt}
                    rowsArtikuj={rowsArtikuj}
                    state={state}
                    setState={setState}
                  />
                </div>{" "}
              </>
            )}
          </div>

          <div className="sidebar_btn_fature_thjeshtuar">
            <Buttons
              rows={rows}
              selectedKodi={selectedKodi}
              showToast={showToast}
              setRows={setRows}
              listaDisplay={listaDisplay}
              setListaDisplay={setListaDisplay}
            />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
