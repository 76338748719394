import React, { useContext, useRef } from "react";
import "../FatureThjeshtuar.scss";
import "./Buttons.scss";
import { LoadingOverlayContext } from "../../../../components/LoadingOverlay";
import mainAxios from "../../../../services/axios";
import { useFtshContext } from "../../../../components/context/FtshState";
import { Toast } from "primereact/toast";
import { formatDate } from "../../Function/formatDate";
import { useUser } from "../../../../zustand/common";
import PrintoFisSupermarket from "./PrintFis";
import { useSelector } from "react-redux";

const Buttons = ({
  setRows,
  setListaDisplay,
  listaDisplay,
  showToast,
  rows,
}) => {
  const { user } = useUser();
  const operator = user.username;
  const { setState, defaultState, state } = useFtshContext();
  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  const menyra = useSelector((state) => state.globalSlice.menyraPageses);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const toastRef = useRef(null);
  // const dispatch = useDispatch();

  const handleShtimClick = async () => {
    try {
      showLoading();
      const response = await mainAxios.get("/fsh/nextKodi");

      if (response.status === 200) {
        // Exclude Id from the state object
        const { Id, ...stateWithoutId } = state;
        const responseData = await mainAxios.post("/fature/shitje", {
          ...stateWithoutId,
          Kodi_Org: state.Kodi_Org,
          Tip_Cmimi: state.Tip_Cmimi,
          Flete_Dalje_KODI: Number(response.data.new_Kodi),
          Menyra_Pageses_ID: Number(state.Menyra_Pageses_ID),
          NrSerik: state.NrSerik,
          Veprimi: state.Veprimi,
          // Klasifikim4_ID: Number(state.Klasifikim4_ID),
          Shenim: state.Shenim,
          Kodi: Number(response.data.new_Kodi),
          Eksport: Number(state.Eksport),
          Magazina_Kodi: state.Magazina_Kodi,
          NIPT: state.NIPT,
          Afati_PagesesDite: Number(state.Afati_PagesesDite),
          // Klasifikim1_ID: Number(state.Klasifikim1_ID),
          Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
          Data: formatDate(state.Data, true),
          Mon: state.Mon,
          Kursi: Number(state.Kursi),
          Dep_Kodi: state.Dep_Kodi,
          KLFU_Kodi: state.KLFU_Kodi,
          Paguar: Number(state.Paguar),
          Arka_Banka_Kodi: String(state.Arka_Banka_Kodi),
          Paguar_ne: String(state.Menyra_Pageses_ID),
          Operator: String(operator),
          // Klasifikim2_ID: Number(state.Klasifikim2_ID),
          Qyteti_Kodi: state.Qyteti_Kodi,
          // Klasifikim3_ID: Number(state.Klasifikim3_ID),
          Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
          KLFU_Pershkrim: state.KLFU_Pershkrim,
          Transportuesi_Nipt: state.Transportuesi_Nipt,
          Targa_e_Mjetit: state.Targa_e_Mjetit,
          Transportuesi_Adresa: state.Transportuesi_Adresa,
          Transportuesi_Pershkrim: state.Transportuesi_Pershkrim,
          Transport_Shenim: state.Transport_Shenim,
          Transport_Data: formatDate(state.Transport_Data, true),
          fisDocumentTypeKodi: state.fisDocumentTypeKodi,
          fisPaymentMethodTypeID:
            state.fisPaymentMethodTypeID === null
              ? null
              : Number(state.fisPaymentMethodTypeID),
          FisInvoiceType: Number(state.FisInvoiceType),
          fisIsEinvoice: Number(state.fisIsEinvoice),
          fisProcessKodi: state.fisProcessKodi,
          fisIsCorrectiveInvoice: Number(state.fisIsCorrectiveInvoice),
          subsequentDelivery: Number(state.subsequentDelivery),
          fisCorrectiveInvTypeID:
            state.fisCorrectiveInvTypeID === null
              ? null
              : Number(state.fisCorrectiveInvTypeID),
          fisIsReverseCharge: Number(state.fisIsReverseCharge),
          fisTypeOfSelfIssID:
            state.fisTypeOfSelfIssID === null
              ? null
              : Number(state.fisTypeOfSelfIssID),

          subsequentDeliveryType:
            state.subsequentDeliveryType === null
              ? null
              : Number(state.subsequentDeliveryType),
        });

        if (responseData.status === 200) {
          setState(responseData.data);
          setRows([]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  const handleListaOpen = () => {
    setListaDisplay(!listaDisplay);
  };

  const handleFis = async (handlePrint2) => {
    showLoading();
    try {
      const response = await mainAxios(`/fature/shitje/fis/${state.Id}`);
      const firstItem = response.data;

      if (response.status === 200) {
        if (response.data?.ErrorCode !== "0") {
          const faultstring = firstItem.faultstring;
          showToast(faultstring, { severity: "error" });
        } else {
          const responseData = await mainAxios.get(
            `/fature/shitje/${state.Id}`
          );
          if (responseData.status === 200) {
            setState(responseData.data);
            showToast("Fatura u fiskalizua !", { severity: "success" });
            await handlePrint2();
          }
        }
      } else {
        showToast("Nuk u fiskalizua.", { severity: "error" });
      }
    } catch (error) {
      console.error("Error fetching new_kodi_fsh data:", error);
    } finally {
      setRows([]);
      setState(defaultState);
      hideLoading();
      // if error handle here to not make default state
    }
  };

  return (
    <div className="buttons_fature_thjeshtuar">
      <Toast ref={toastRef} position="top-center" />
      {/* <button
        onClick={handleFis}
        className="circle-button_fature_thjeshtuar fiskal_fature_thjeshtuar"
      >
        Fiskal
      </button> */}
      <PrintoFisSupermarket
        handleFiskalizimi={handleFis}
        posSupermarketListaFaturave={false}
        className="print"
        rows={rows}
        state={state}
        selectedId={state.Id}
        showToast={showToast}
        menyra={menyra}
        operator={operator}
        konfigurimeRedux={konfigurimeRedux}
      />
      <button
        onClick={handleListaOpen}
        className="circle-button_fature_thjeshtuar lista_fature_thjeshtuar"
      >
        Lista
      </button>
      <button
        onClick={handleShtimClick}
        className="circle-button_fature_thjeshtuar shto_btn_fature_thjeshtuar"
      >
        Shto
      </button>
    </div>
  );
};

export default Buttons;
