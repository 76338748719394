import React from "react";
import "../FatureThjeshtuar.scss";

const Pagesa = ({ paguar, setPaguar, setToggleState }) => {
  const handleClick = () => {
    setPaguar(!paguar);
    setToggleState(2);
  };

  return (
    <div
      className="button_paguar_fature_thjeshtuar"
      style={{
        color: paguar ? "#000" : "#fff",
        backgroundColor: paguar ? "#fff" : "#1976d2",
      }}
      onClick={handleClick}
    >
      {paguar ? "Mbyll Pagesen" : "Pagesa"}
    </div>
  );
};

export default Pagesa;
