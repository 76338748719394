import React from "react";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import Furnitore from "../../minimodal/Furnitore";
import Paper from "@mui/material/Paper";

const TabFurnitore = ({
  toggleState,
  disabled,
  state,
  handleChange,
  handleFurnitorType,
  qytetet,
  furnitor,
  setSearchString,
  searchString,
  qytetFurnitor,
}) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  return (
    <div className={toggleState ? "content  active-content" : "content"}>
      <div className="grid gap-2 grid-cols-2 " style={{ height: "155px" }}>
        <div className="rounded-tr-lg rounded-br-lg w-full flex justify-center items-center relative">
          {/* //////// */}
          <Autocomplete
            disabled={disabled}
            disablePortal
            id="combo-box-demo5"
            options={furnitor}
            getOptionLabel={(option) => (option.Kodi ? `${option.Kodi}` : "")}
            value={
              searchString
                ? furnitor.find((option) => option.Kodi === searchString)
                : (state &&
                    furnitor.find(
                      (option) => option.Kodi === state.KLFU_Kodi
                    )) ||
                  null
            }
            onChange={(event, newValue) => {
              setSearchString(newValue ? newValue.Kodi : "");
              handleFurnitorType(event, newValue);
            }}
            sx={{ width: "100%" }}
            size="small"
            filterOptions={filterOptions}
            renderInput={(params) => <TextField {...params} label="Furnitor" />}
            renderOption={(props, option) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
              </div>
            )}
            PaperComponent={(props) => <Paper {...props} />}
          />

          {/* //////MiniModal */}
          <div className="hover:scale-110 transition-all">
            <Furnitore furnitor={furnitor} qytetet={qytetet} />
          </div>
        </div>

        <TextField
          disabled={disabled}
          type="text"
          onChange={(e) => handleChange("NIPT", e.target.value)}
          label="Nipt"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.NIPT || ""}
        />

        <TextField
          disabled={disabled}
          onChange={(e) => handleChange("KLFU_Pershkrim", e.target.value)}
          label="Pershkrim"
          variant="outlined"
          size="small"
          style={{ width: "100%" }}
          value={state?.KLFU_Pershkrim || ""}
        />
        {qytetFurnitor ? (
          <div className="flex justify-start items-center w-full relative rounded-tr-lg rounded-br-lg">
            <Autocomplete
              disablePortal
              disabled={disabled}
              id="combo-box-qyteti7"
              options={qytetet}
              filterOptions={filterOptions}
              getOptionLabel={(option) => (option ? option.Kodi : "")}
              onChange={(e, newValue) =>
                handleChange("Qyteti_Kodi", newValue ? newValue.Kodi : "")
              }
              value={
                qytetet.find((qytet) => qytet.Kodi === state?.Qyteti_Kodi) ||
                null
              }
              size="small"
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Qytetet" />
              )}
              renderOption={(props, option) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  {...props}
                >
                  <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                  <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                </div>
              )}
            />
            <div className="hover:scale-110 transition-all">
              {/* <Qytet
                fetchMonedhat={fetchPost}
                qytetet={qytetetKodi}
                numQyt={numQyt}
              /> */}
            </div>
          </div>
        ) : null}
        <textarea
          disabled={disabled}
          cols="38"
          rows="3"
          value={state?.Shenim || ""}
          onChange={(e) => handleChange("Shenim", e.target.value)}
          placeholder="Shënim"
          className="shenim resize-none  bg-white rounded-md"
        ></textarea>
      </div>
    </div>
  );
};

export default TabFurnitore;
