const nodes = [
  {
    key: "1-1",
    label: "Deklarimi i Arkes",
    icon: "pi pi-wallet",
    buttonRaport: "Veprime me Arken",
    ArkaButtons: [
      // { key: "2-7" },
      { key: "2-8" },
      { key: "2-9" },
      // { key: "4-6" },
      // { key: "4-7" },
      { key: "3-0" },
    ],
  },
  {
    key: "1",
    label: "Dokumenta",
    items: [
      {
        key: "1-2",
        label: "Fature Blerje",
        icon: "pi pi-arrow-circle-right",
        path: "/fature-blerje",
      },
      {
        key: "1-3",
        label: "Fature Shitje",
        icon: "pi pi-arrow-circle-right",
        path: "/fature-shitje",
      },
      {
        key: "1-6",
        label: "Fature Thjeshtuar",
        icon: "pi pi-arrow-circle-right",
      },
      {
        key: "1-4",
        label: "Flete Hyrje",
        icon: "pi pi-arrow-circle-right",
        path: "/flete-hyrje",
      },
      {
        key: "1-5",
        label: "Flete Dalje",
        icon: "pi pi-arrow-circle-right",
        path: "/flete-dalje",
      },
    ],
  },
  {
    key: "2",
    label: "Celje",
    items: [
      {
        key: "2-1",
        label: "Artikuj",
        icon: "pi pi-arrow-circle-right",
        path: "/artikuj",
      },
      {
        key: "2-3",
        label: "Perdorues",
        icon: "pi pi-arrow-circle-right",
        path: "/celje/perdorues",
      },
      {
        key: "2-4",
        label: "Klient",
        icon: "pi pi-arrow-circle-right",
        path: "/celje/klienti",
      },
      {
        key: "2-5",
        label: "Furnitor",
        icon: "pi pi-arrow-circle-right",
        path: "/celje/furnitori",
      },
      {
        key: "2-6",
        label: "Transportues",
        icon: "pi pi-arrow-circle-right",
        path: "/celje/transportuesi",
      },
      {
        key: "2-6-1",
        label: "Magazina",
        icon: "pi pi-arrow-circle-right",
        path: "/celje/magazina",
      },

      {
        key: "2-7",
        label: "Lista e arkave",
        icon: "pi pi-arrow-circle-right",
        path: "/celje/arka",
      },
      {
        key: "2-8",
        label: "Lista e bankave",
        icon: "pi pi-arrow-circle-right",
        path: "/celje/banka",
      },
      {
        key: "2-9",
        label: "Agjent",
        icon: "pi pi-arrow-circle-right",
        path: "/celje/agjent",
      },
    ],
  },
  {
    key: "3",
    label: "POS",
    items: [
      {
        key: "3-1-3",
        label: "BAR",
        icon: "pi pi-arrow-circle-right",
        path: "/pos/bar",
      },
      {
        key: "3-2-3",
        label: "Market",
        icon: "pi pi-arrow-circle-right",
        path: "/pos/market",
      },
      {
        key: "3-2-4",
        label: "Xhiro",
        icon: "pi pi-arrow-circle-right",
      },
    ],
  },
  {
    key: "9",
    label: "Raporte",
    items: [
      {
        key: "9-0",
        label: "Artikuj",
        items: [
          {
            key: "9-1",
            label: "Artikulli ne Magazine",
            icon: "pi pi-arrow-circle-right",
            buttonRaport: "Gjendje_Artikulli_Magazine",
            text: "Raporti: Artikulli ne Magazine",
            RaportButtons: [
              { key: "1-0" },
              { key: "4-8" },
              { key: "4-9" },
              { key: "1-2" },
              { key: "1-3" },
              { key: "1-4" },
              { key: "1-5" },
              { key: "1-6-1" },
              { key: "1-7" },
              { key: "1-7-1" },
            ],
          },
          {
            key: "9-2",
            label: "Gjendje Magazine",
            icon: "pi pi-arrow-circle-right",
            buttonRaport: "Gjendje_Magazine",
            text: "Raporti: Gjendje Magazine",
            RaportButtons: [
              { key: "1-0-1" },
              { key: "1-2-1" },
              { key: "1-3-1" },
              // { key: "1-8" },
              // { key: "1-9" },
              { key: "1-4-1" },
              { key: "1-6" },
              // { key: "2-2" },
            ],
          },
        ],
      },
      {
        key: "9-3",
        label: "Arka",
        items: [
          {
            key: "9-4",
            label: "Ditari i Arkes",
            icon: "pi pi-arrow-circle-right",
            buttonRaport: "Ditari_I_Arkes",
            text: "Raporti: Ditari i Arkes",
            RaportButtons: [
              { key: "3-0" },
              { key: "2-3" },
              { key: "2-4" },
              { key: "2-5" },
              { key: "2-6" },
              { key: "2-7" },
              { key: "4-6" },
              { key: "4-7" },
              { key: "2-9" },
            ],
          },
          {
            key: "9-5",
            label: "Gjendje Arkave Fiskale",
            icon: "pi pi-arrow-circle-right",
            buttonRaport: "Gjendje_Arkave_Fiskal",
            text: "Raporti: Gjendje Arkave Fiskale",
            RaportButtons: [{ key: "3-0-1" }],
          },
        ],
      },
      {
        key: "9-6",
        label: "Furnitor",
        items: [
          {
            key: "9-7",
            label: "Gjendje Furnitoreve",
            icon: "pi pi-arrow-circle-right",
            buttonRaport: "Gjendje_E_Furnitorve_Analitik",
            text: "Raporti: Gjendje Furnitoreve ",
            RaportButtons: [{ key: "2-4" }, { key: "3-1" }, { key: "3-5" }],
          },
        ],
      },
      {
        key: "9-8",
        label: "Banka",
        items: [
          {
            key: "9-9",
            label: "Ditari i Bankes",
            icon: "pi pi-arrow-circle-right",
            buttonRaport: "Ditari_I_Bankes",
            text: "Raporti: Ditari i Bankes",
            RaportButtons: [
              { key: "3-2" },
              { key: "2-3" },
              { key: "2-6" },
              { key: "2-7-1" },
              { key: "4-6" },
              { key: "4-7" },
              { key: "2-9" },
            ],
          }, // const keyLabelMap = {
          //   "2-5": "KLIENT",
          //   "2-5-1": "KLIENTI",
          //   "1-2": "MAGAZINA_KODI",
          //   "1-2-1": "MAGAZINA",
          //   "1-0": "KODI",
          //   "1-0-1":"ARTIKULL",
          //   "1-0-1-1":"ARTIKULLI",
          //   "1-3": "ART_KLS01_KODI",
          //   "1-3-1": "KLASIFIKIM1",
          //   "1-4": "Skema_Kodi",
          //   "1-4-1": "SKEMA",
          //   "1-5": "Tarifa_Kodi",
          //   "1-6": "GJENDJE",
          //   "1-6-1": "Hvaing",
          //   "1-7": "AAM",
          //   "1-7-1":"Aktiv",
          //   "1-8": "ART_KLS02_KODI",
          //   "1-9": "ART_KLS03_KODI",
          //   "2-1": "GJENDJE",
          //   "2-2": "ART_KLS04_KODI",
          //   "2-3": "MONEDHA",
          //   "2-4": "FURNITORI",
          //   "2-6": "TIPI",
          //   "2-7": "SHENIM",
          //   "2-7-1": "SHENIMI",
          //   "2-8": "Vlera",
          //   "2-9": "VEPRIMI",
          //   "2-9-1": "VEPRIMI",
          //   "3-0": "ARKA",
          //   "3-0-1": "ARKA",
          //   "3-1": "QYTETI",
          //   "3-2": "BANKA",
          //   "3-3": "TRANSPORTUES",
          //   "3-5": "TIPI",
          //   "3-6": "MENYRA",
          //   "3-6-1": "MENYRA",
          //   "3-7": "PUNONJES",
          //   "3-8": "Nipt",
          //   "3-9": "Nr.Dok",
          //   "4-0": "Nr.Serial",
          //   "4-1": "Vl. Paguar",
          //   "4-2": "Tot. Fature",
          //   "4-3": "OPERATOR",
          //   "4-4": "Nr.Fat",
          //   "4-5": "Agjenti",
          //   "4-6": "START_VLERA",
          //   "4-7": "END_VLERA",
          //   "4-8": "BARKOD_DERI",
          //   "4-9": "BARKOD_NGA",
          //   "5-0": "VEPRIMI",
          //   "3-4": "TIPI",
          // };
        ],
      },
      {
        key: "10",
        label: "Blerje",
        items: [
          {
            key: "10-1",
            label: "Blerje sipas furnitoreve",
            icon: "pi pi-arrow-circle-right",
            buttonRaport: "Blerje_Sipas_Furnitorve",
            text: "Raporti: Blerje sipas furnitoreve",
            RaportButtons: [{ key: "2-4" }, { key: "1-0-1-1" }, { key: "2-3" }],
          },
          {
            key: "10-2",
            label: "Blerje Periodike-Artikuj",
            icon: "pi pi-arrow-circle-right",
            buttonRaport: "Blerje_Periodike_Artikuj",
            text: "Raporti: Blerje Periodike-Artikuj",
            RaportButtons: [
              { key: "2-4" },
              { key: "1-2-1" },
              { key: "1-0-1-1" },
              { key: "3-3" },
              { key: "2-9-1" },
              // { key: "3-4" },
              { key: "2-6" },
              { key: "3-6" },
            ],
          },
        ],
      },
      {
        key: "10-3",
        label: "Klient",
        items: [
          {
            key: "10-4",
            label: "Gjendje Klient analitik",
            icon: "pi pi-arrow-circle-right",
            buttonRaport: "Gjendje_Klienti_Analitik",
            text: "Raporti: Gjendje Klient analitik",
            RaportButtons: [{ key: "2-5-1" }],
          },
          // {
          //   key: "10-5",
          //   label: "Liste Klient CRM",
          //   text: "Liste Klient CRM",
          //   icon: "pi pi-arrow-circle-right",
          //   buttonRaport: "Liste_Klient_CRM",
          //   RaportButtons: [{ key: "2-5" }, { key: "1-3" }, { key: "4-5" }],
          // },
        ],
      },
      //     {
      //       key: "10-6",
      //       label: "Raport CRM",
      //       icon: "pi pi-arrow-circle-right",
      //       buttonRaport: "Raport CRM",
      //       RaportButtons: [{ key: "2-5" }],
      //     },
      //     {
      //       key: "10-7",
      //       label: "Klient CRM me shenime",
      //       icon: "pi pi-arrow-circle-right",
      //       buttonRaport: "Klient CRM me shenime",
      //       RaportButtons: [{ key: "2-5" }],
      //     },
      //     {
      //       key: "10-8",
      //       label: "Klient CRM Me/Pa TVSH",
      //       icon: "pi pi-arrow-circle-right",
      //       buttonRaport: "Klient CRM Me/Pa TVSH",
      //       RaportButtons: [{ key: "2-5" }, { key: "3-8" }],
      //     },
      //     {
      //       key: "10-9",
      //       label: "Gjendje klient analitik (Web)",
      //       icon: "pi pi-arrow-circle-right",
      //       buttonRaport: "Gjendje klient analitik (Web)",
      //       RaportButtons: [{ key: "2-5" }, { key: "3-7" }],
      //     },
      //   ],
      // },
      {
        key: "11",
        label: "Shitje",
        items: [
          {
            key: "11-1",
            label: "Shtije Periodike-Artikuj",
            text: "Shtije Periodike-Artikuj",
            icon: "pi pi-arrow-circle-right",
            buttonRaport: "Shitje_Periodike_Artikuj",
            RaportButtons: [
              { key: "2-5-1" },
              { key: "1-2-1" },
              { key: "1-0-1-1" },
              { key: "1-3-1" },
              { key: "3-3" },
              { key: "5-0" },
              { key: "3-4" },
              { key: "3-6-1" },
              { key: "4-3" },
            ],
          },
        ],
      },
      //     {
      //       key: "11-2",
      //       label: "Shitje Sipas",
      //       items: [
      //         {
      //           key: "11-3",
      //           label: "Sipas Agjenteve",
      //           icon: "pi pi-arrow-circle-right",
      //           buttonRaport: "Sipas Agjenteve",
      //           RaportButtons: [
      //             { key: "2-5" },
      //             { key: "4-5" },
      //             { key: "1-0" },
      //             { key: "2-3" },
      //             { key: "4-4" },
      //           ],
      //         },
      //         {
      //           key: "11-4",
      //           label: "Sipas Klienteve",
      //           icon: "pi pi-arrow-circle-right",
      //           buttonRaport: "Sipas Klienteve",
      //           RaportButtons: [
      //             { key: "2-5" },
      //             { key: "1-0" },
      //             { key: "2-3" },
      //             { key: "4-4" },
      //           ],
      //         },
      //         {
      //           key: "11-5",
      //           label: "Sipas Operatoreve dhe dates",
      //           icon: "pi pi-arrow-circle-right",
      //           buttonRaport: "Sipas Operatoreve dhe dates",
      //           RaportButtons: [{ key: "4-3" }, { key: "1-0" }],
      // },
      //   ],
      // },
    ],
    // },
    // {
    //   key: "11-6",
    //   label: "Libra Blerje/Shitje",
    //   items: [
    //     {
    //       key: "11-7",
    //       label: "Liber Blerje",
    //       icon: "pi pi-arrow-circle-right",
    //       buttonRaport: "Liber Blerje",
    //       RaportButtons: [{ key: "2-4" }, { key: "3-9" }, { key: "4-0" }],
    //     },
    //     {
    //       key: "11-8",
    //       label: "Liber Shitje",
    //       icon: "pi pi-arrow-circle-right",
    //       buttonRaport: "Liber Shitje",
    //       // index: "461",
    //       RaportButtons: [
    //         { key: "2-5" },
    //         { key: "3-9" },
    //         { key: "3-6" },
    //         { key: "4-1" },
    //         { key: "4-2" },
    //       ],
    //     },
    // ],
  },
  // ],
  // },
];

export default nodes;
