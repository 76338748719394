import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SideBarPos from "../SideBarPos/SideBarPos";
import { Button } from "@mui/material";

export default function BottomAppBar({
  rows,
  setCurrentPorosia,
  handleSearchChange,
  handleToggleLeftBarPrint,
  searchQuery,
  setSearchQuery,
  clickedItems,
  onItemClick,
  setClickedItems,
  setClickedNewItems,
  state,
  numTav,
  handleShtoArtikull,
  newId,
  totalCmimi,
  menyra,
  printPorosia,
  isMobile,
  toggleDataTable,
  isDataTableOpen,
  showLoading,
  mbylleTav,
  hideLoading,
  messageDisplay,
  aExistTable,
  konfigurimeRedux,
  setPrintPorosia,
  operator,
  nipt,
  ShtoKlient,
  PrintoFisPos,
  handleSearchInputChange,
  handleSearchClick,
  searchTerm,
}) {
  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer">
            <SideBarPos
              rows={rows}
              setCurrentPorosia={setCurrentPorosia}
              handleSearchChange={handleSearchChange}
              handleToggleLeftBarPrint={handleToggleLeftBarPrint}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              clickedItems={clickedItems}
              onItemClick={onItemClick}
              setClickedItems={setClickedItems}
              setClickedNewItems={setClickedNewItems}
              state={state}
              numTav={numTav}
              handleShtoArtikull={handleShtoArtikull}
              newId={newId}
              totalCmimi={totalCmimi}
              menyra={menyra}
              printPorosia={printPorosia}
              isMobile={isMobile}
              toggleDataTable={toggleDataTable}
              isDataTableOpen={isDataTableOpen}
              showLoading={showLoading}
              mbylleTav={mbylleTav}
              hideLoading={hideLoading}
              messageDisplay={messageDisplay}
              aExistTable={aExistTable}
              konfigurimeRedux={konfigurimeRedux}
              setPrintPorosia={setPrintPorosia}
              operator={operator}
              nipt={nipt}
              ShtoKlient={ShtoKlient}
              PrintoFisPos={PrintoFisPos}
              handleSearchInputChange={handleSearchInputChange}
              handleSearchClick={handleSearchClick}
              searchTerm={searchTerm}
              pos={true}
              posBar={true}
              fatureThjesht={true}
              className="print"
            />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TextField
              sx={{ m: 1, width: "25ch", backgroundColor: "#fff" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />

            <Button
              type="button"
              variant="contained"
              onClick={handleSearchClick}
              style={{
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Kerko
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
