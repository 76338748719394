import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import mainAxios from "../../../services/axios";

const DialogXhiro = ({
  onClose,
  open,
  functionPost,
  setDialogXhiro,
  ngaData,
  deriData,
  mainData,
  selectedOperator,
  dataPosBak,
}) => {
  const formatDateString = (dateString) => {
    if (!dateString) return ""; // Return empty string if no value

    const dateObject = new Date(dateString);

    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const mbyllXhiron = async () => {
    try {
      // Use formatDateString, which now handles empty values
      const formattedNgaDate = formatDateString(ngaData);
      const formattedDeriDate = formatDateString(deriData);
      const formattedMainDate = formatDateString(mainData);

      const response = await mainAxios.get(
        `/pos/fshngapos?nga='${formattedNgaDate}'&&deri='${formattedDeriDate}'&&o=${selectedOperator}&&voperator=${selectedOperator}&&dfsh='${formattedMainDate}'`
      );

      if (response.status === 200) {
        await functionPost();
        setDialogXhiro(false);
      } else {
        console.error("Response is null or status is not 200");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Confirmation</DialogTitle>

      <DialogContent>
        Deshironi te mbyllni xhiron per Operatorin: {selectedOperator}
      </DialogContent>

      <div className="buttons_confirmation">
        <Button onClick={mbyllXhiron} color="primary" variant="contained">
          PO
        </Button>

        <Button
          onClick={() => {
            setDialogXhiro(false);
          }}
          color="primary"
          variant="contained"
        >
          JO
        </Button>
      </div>
    </Dialog>
  );
};

export default DialogXhiro;
