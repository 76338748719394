import React, { useRef } from "react";
import {
  Autocomplete,
  Button,
  TextField,
  createFilterOptions,
} from "@mui/material";
import Magazina from "../../minimodal/Magazina";
import { useSelector } from "react-redux";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate } from "react-router-dom";
import mainAxios from "../../../../services/axios";
import { Toast } from "primereact/toast";

const TabMagazina = ({
  disabled,
  toggleState,
  state,
  handleChange,
  fetchPost,
  sec,
  formatDate,
  fleteHyrjeBtn,
  fleteDaljeBtn,
  buttonClicked,
}) => {
  const toast = useRef(null);

  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 3000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });
  // redux magazina (get requesti i Magazines)
  const magazinaRedux = useSelector((state) => state.globalSlice.magazina);
  const magazina = magazinaRedux;

  let numMag = magazina.length;

  const navigate = useNavigate();

  // this function is to navigate on main Component "Flete Hyrje" which is generated from Fature Blerje
  const handleClickFH = async () => {
    const response = await mainAxios.get(
      `/rownum/Flete_Hyrje/${state.Flete_Hyrje_ID}`
    );

    if (response.status === 200) {
      navigate("/flete-hyrje", {
        state: {
          nmrFB: response.data.row_num,
          fromFatureBlerje: true,
        },
      });
    } else {
      showToast("Ndodhi problem!");
    }
  };

  // this function is to navigate on main Component "Flete Dalje" which is generated from Fature Shitje
  const handleClickFD = async () => {
    const response = await mainAxios.get(
      `/rownum/Flete_Dalje/${state.Flete_Dalje_ID}`
    );

    if (response.status === 200) {
      navigate("/flete-dalje", {
        state: {
          nmrFSh: response.data.row_num,
          fromFatureShitje: true,
        },
      });
    } else {
      showToast("Ndodhi problem!");
    }
  };

  return (
    <div className={toggleState ? "content  active-content" : "content"}>
      <div className="grid gap-4">
        <Toast ref={toast} />
        <div className="grid w-72 gap-2 grid-cols-1">
          <div className="rounded-tr-lg rounded-br-lg flex justify-center items-center">
            {/* //////////// */}
            <Autocomplete
              disablePortal
              disabled={disabled}
              id="combo-box-demo13"
              options={magazina}
              filterOptions={filterOptions}
              getOptionLabel={(option) =>
                option ? option.Kodi + " - " + option.Pershkrim : ""
              }
              onChange={(e, newValue) =>
                handleChange("Magazina_Kodi", newValue ? newValue.Kodi : "")
              }
              value={
                magazina.find((qytet) => qytet.Kodi === state?.Magazina_Kodi) ||
                null
              }
              size="small"
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Magazina" />
              )}
              renderOption={(props, option) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  {...props}
                >
                  <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                  <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                </div>
              )}
            />
            {/* ///////// MiniModal */}
            <div>
              <Magazina
                fetchMonedhat={fetchPost}
                numMag={numMag}
                magazina={magazina}
              />
            </div>
          </div>
          <TextField
            disabled
            type="date"
            label="Date"
            InputLabelProps={{
              shrink: true,
            }}
            value={formatDate(state?.MagazinaData + "")}
            onChange={(e) => handleChange("MagazinaData", e.target.value + sec)}
            size="small"
          />
          {fleteHyrjeBtn && (
            <div>
              <Button
                variant="contained"
                disabled={buttonClicked || !state.Flete_Hyrje_ID}
                onClick={handleClickFH}
              >
                FH <LinkIcon />
              </Button>
            </div>
          )}

          {fleteDaljeBtn && (
            <div>
              <Button
                variant="contained"
                disabled={buttonClicked || !state.Flete_Dalje_ID}
                onClick={handleClickFD}
              >
                FD <LinkIcon />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TabMagazina;
