import React, { useRef } from "react";
import Form from "react-bootstrap/Form";
import Paper from "@mui/material/Paper";
import { Autocomplete, Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import "../MainTab/MainTab.scss";
import { useNavigate } from "react-router-dom";
import mainAxios from "../../../../services/axios";
import { Toast } from "primereact/toast";
import LinkIcon from "@mui/icons-material/Link";

const divStyle = {
  display: "inline-flex",
  alignItems: "center",
  padding: "0 14px",
  height: "40px",
  fontSize: "16px",
  lineHeight: "1.4375em",
  letterSpacing: "0.00938em",
  borderRadius: "4px",
  backgroundColor: "transparent",
  color: "rgba(0, 0, 0, 0.87)",
};

const disabledDivStyle = {
  ...divStyle,
  color: "rgba(0, 0, 0, 0.38)",
};
const MainTabArka = ({
  disabled,
  state,
  handleChange,
  handleKursiType,
  includeDateField,
  includeKursiField,
  includeMonedhaSelect,
  includeKodiField,
  includeVleraSelectArka,
  includeSerialNumerField,
  includeTotal,
  formatDate,
  fleteHyrje,
  fromArka,
}) => {
  const monedhat = useSelector((state) => state.globalSlice.monedha);

  // redux skema-veprimi (get requesti i veprimit)
  const veprimiRedux = useSelector((state) => state.globalSlice.veprimi);
  const filteredData = veprimiRedux.filter((item) => {
    if (fromArka) {
      return item.Tabela === "ARKA";
    } else {
      return item.Tabela === "BANKA";
    }
  });

  const toast = useRef(null);

  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 3000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };

  const navigate = useNavigate();

  // this function is to navigate on main Component "Fature Blerje" or "Fature Shitje" from Banka or Arka.
  const handleClickFT = async () => {
    try {
      const fatureType =
        state.Veprimi === "FTSH" ? "Fature_Shitje" : "Fature_Blerje";
      const fatureId =
        state.Veprimi === "FTSH"
          ? state.Fature_Shitje_Id
          : state.Fature_Blerje_Id;

      const fatureUrl =
        state.Veprimi === "FTSH" ? "fature-shitje" : "fature-blerje";

      const response = await mainAxios.get(`/rownum/${fatureType}/${fatureId}`);

      if (response.status === 200) {
        navigate(`/${fatureUrl}`, {
          state: {
            nmrFB: response.data.row_num,
            fromArka: true,
          },
        });
      } else {
        showToast("Ndodhi problem!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="grid gap-2 grid-cols-2" style={{ height: "125px" }}>
      <Toast ref={toast} />
      <Button variant="contained" onClick={() => handleClickFT()}>
        Origjina{" "}
        {state.Veprimi === "FTSH"
          ? "FTSH ID: " + state.Fature_Shitje_Id
          : "FBTL ID: " + state.Fature_Blerje_Id}{" "}
        <LinkIcon />
      </Button>
      <div className="drop_down_list">
        <Autocomplete
          disabled={disabled}
          id="combo-box-demo14"
          options={filteredData}
          getOptionLabel={(option) => option.Pershkrim && option.Kodi}
          value={
            filteredData.find((ArkaP) => ArkaP.Kodi === state?.Veprimi) || null
          }
          onChange={(_, newValue) => {
            handleChange("Veprimi", newValue?.Kodi || "");
          }}
          sx={{ width: "100%" }}
          size="small"
          renderInput={(params) => <TextField {...params} label="Veprimi" />}
          renderOption={(props, option) => (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              {...props}
            >
              <div style={{ textAlign: "start" }}>{option.Pershkrim}</div>
              <div style={{ textAlign: "end" }}>{option.Kodi}</div>
            </div>
          )}
          PaperComponent={(props) => (
            <Paper {...props} /> // Adjust the width as needed
          )}
        />
        {/* <div className=" hover:scale-110 transition-all">
            <Monedhat fetchMonedhat={fetchPost} />
          </div> */}
      </div>

      <TextField
        disabled={disabled}
        value={state?.Vlera || ""}
        type="number"
        variant="outlined"
        label="Vlera"
        onChange={(e) => {
          handleChange("Vlera", e.target.value);
        }}
        size="small"
        className="relative"
      />

      {includeDateField && (
        <TextField
          fullWidth
          disabled={disabled}
          type="date"
          label="Date"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={formatDate(state?.Data)}
          onChange={(e) => handleChange("Data", e.target.value)}
          size="small"
        />
      )}
      {includeMonedhaSelect && (
        <div className="drop_down_list">
          <Form.Select
            style={{ backgroundColor: "#fff" }}
            disabled={disabled}
            value={state?.Mon || ""}
            onChange={(e) => {
              handleKursiType(e);
            }}
          >
            <option label="Monedha"></option>
            {monedhat.map((monedha) => {
              return (
                <option key={monedha.Kodi} value={monedha.Kodi}>
                  {monedha.Pershkrim}
                </option>
              );
            })}
          </Form.Select>
        </div>
      )}
      {includeKursiField && (
        <TextField
          disabled={disabled || state?.Mon === "ALL"}
          type="number"
          label="Kursi"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: { min: 0 },
          }}
          value={state?.Kursi || ""}
          onChange={(e) =>
            e.target.value < 0
              ? (e.target.value = 0)
              : handleChange("Kursi", e.target.value)
          }
          variant="outlined"
          size="small"
          className="relative"
        />
      )}
      {includeTotal && (
        <TextField
          disabled={disabled}
          value={state.Kursi * state.Monedha}
          type="number"
          label="Total"
          variant="outlined"
          onChange={(e) => handleChange("Total", e.target.value)}
          size="small"
        />
      )}
      {includeKodiField && (
        <TextField
          disabled={disabled}
          value={state?.Kodi || ""}
          type="number"
          label="Nr"
          variant="outlined"
          onChange={(e) => handleChange("Kodi", e.target.value)}
          size="small"
        />
      )}

      {fleteHyrje && <div style={disabled ? disabledDivStyle : divStyle}></div>}
      {includeVleraSelectArka && (
        <div className="drop_down_list">
          <TextField
            disabled={disabled}
            value={state.Vlera || ""}
            type="number"
            label="Vlera"
            variant="outlined"
            onChange={(e) => handleChange("Vlera", e.target.value)}
            size="small"
          />
        </div>
      )}
      {includeSerialNumerField && (
        <TextField
          disabled={disabled}
          value={state?.NrSerik || ""}
          type="number"
          label="Serial numer"
          onChange={(e) => handleChange("NrSerik", e.target.value)}
          variant="outlined"
          size="small"
        />
      )}
      {fleteHyrje && <div></div>}
    </div>
  );
};

export default MainTabArka;
