import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
// import Klasifikim1 from "../../minimodal/Klasifikim";
// import Klasifikim2 from "../../../paneliIartikujve/MiniModalArtikuj/Klasifikime/Klasifikim2";
// import Klasifikim3 from "../../../paneliIartikujve/MiniModalArtikuj/Klasifikime/Klasifikim3";
// import Klasifikim4 from "../../../paneliIartikujve/MiniModalArtikuj/Klasifikime/Klasifikim4";
import { useSelector } from "react-redux";

const TabKLS = ({ toggleState, disabled, state, handleChange, fetchPost }) => {
  // redux klasifikim1 (get requesti i Klasifikimi1)
  const klasifikim1 = useSelector((state) => state.globalSlice.klasifikim1);

  // redux klasifikim2 (get requesti i Klasifikimi2)
  const klasifikim2 = useSelector((state) => state.globalSlice.klasifikim2);

  // redux klasifikim3 (get requesti i Klasifikimi3)
  const klasifikim3 = useSelector((state) => state.globalSlice.klasifikim3);

  // redux klasifikim4 (get requesti i Klasifikimi4)
  const klasifikim4 = useSelector((state) => state.globalSlice.klasifikim4);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);
  return (
    <div className={toggleState === 4 ? "content  active-content" : "content"}>
      <div
        className={
          !isMobile ? "grid w-96 gap-2 grid-cols-2" : "grid  gap-2 grid-cols-2"
        }
      >
        <div className="bg-gray-100 rounded-tr-lg rounded-br-lg flex justify-center items-center ">
          <Autocomplete
            disablePortal
            id="combo-box-demo9"
            options={klasifikim1}
            getOptionLabel={(option) => option.Pershkrim}
            value={
              klasifikim1.find((kls) => kls.Id === state?.Klasifikim1_ID) ||
              null
            }
            onChange={(_, newValue) => {
              handleChange("Klasifikim1_ID", newValue?.Id || "");
            }}
            sx={{ width: "100%" }}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Klasifikim1" />
            )}
          />
          {/* /////// MiniModal */}
          {/* <div className="hover:scale-110 transition-all">
            <Klasifikim1 fetchMonedhat={fetchPost} />
          </div> */}
        </div>

        <div className="bg-gray-100 rounded-tr-lg rounded-br-lg flex justify-center items-center ">
          <Autocomplete
            disablePortal
            id="combo-box-demo10"
            options={klasifikim2}
            getOptionLabel={(option) => option.Pershkrim}
            value={
              klasifikim2.find((kls) => kls.Id === state?.Klasifikim2_ID) ||
              null
            }
            onChange={(_, newValue) => {
              handleChange("Klasifikim2_ID", newValue?.Id || "");
            }}
            sx={{ width: "100%" }}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Klasifikim2" />
            )}
          />
          {/* /////// MiniModal */}
          {/* <div className="hover:scale-110 transition-all">
            <Klasifikim2 fetchMonedhat={fetchPost} />
          </div> */}
        </div>
        <div className="bg-gray-100 rounded-tr-lg rounded-br-lg flex justify-center items-center ">
          <Autocomplete
            disablePortal
            id="combo-box-demo11"
            options={klasifikim3}
            getOptionLabel={(option) => option.Pershkrim}
            value={
              klasifikim3.find((kls) => kls.Id === state?.Klasifikim3_ID) ||
              null
            }
            onChange={(_, newValue) => {
              handleChange("Klasifikim3_ID", newValue?.Id || "");
            }}
            sx={{ width: "100%" }}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Klasifikim3" />
            )}
          />{" "}
          {/* ////// MiniModal */}
          {/* <div className="hover:scale-110 transition-all">
            <Klasifikim3 fetchMonedhat={fetchPost} />
          </div> */}
        </div>
        <div className="bg-gray-100 rounded-tr-lg rounded-br-lg flex justify-center items-center ">
          <Autocomplete
            disablePortal
            id="combo-box-demo12"
            options={klasifikim4}
            getOptionLabel={(option) => option.Pershkrim}
            value={
              klasifikim4.find((kls) => kls.Id === state?.Klasifikim4_ID) ||
              null
            }
            onChange={(_, newValue) => {
              handleChange("Klasifikim4_ID", newValue?.Id || "");
            }}
            sx={{ width: "100%" }}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Klasifikim4" />
            )}
          />
          {/* /////// MiniModal */}
          {/* <div className="hover:scale-110 transition-all">
            <Klasifikim4 fetchMonedhat={fetchPost} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TabKLS;
