import React, { createContext, useContext, useState, useEffect } from "react";
import { useUser } from "../../zustand/common";

// Create a context
const MyContext = createContext();

// Create a provider for the context
export const MyProvider = ({ children }) => {
  const { user } = useUser();

  // Get the date part of the current date
  const getFormattedDate = () => {
    const date = new Date();
    const formattedDate = date.toISOString().split("T")[0]; // Get the date part (YYYY-MM-DD)
    return `${formattedDate}T00:00:00`; // Append "T00:00:00" to the date
  };

  // Get formatted date and time
  const formattedDateTime = new Date().toISOString();
  const [dtlState, setDtlState] = useState([]);
  const [currentAction, setCurrentAction] = useState("");
  const [loadingLeftBar, setLoadingLeftBar] = useState(false);
  const [dtlNum, setDtlNum] = useState(dtlState.length);
  const [gjeneroFatureAnulluese, setGjeneroFatureAnulluese] = useState(false);
  const [open, setOpen] = useState(false);
  const [aExistTable, setTableExist] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // let defaultState = {
  //   Aktiv: 1,
  //   E_Paguar: 0,
  //   Kodi: 1,
  //   Menyra_Pageses_Id: 6,
  //   Pike_Fitim: 0,
  //   Tip_Cmimi: "CMIMI",
  //   Operator: `${operator}`,
  //   Tipi: "FTSH",
  //   Nipt: `${nipt}`,
  //   KLFU_Pershkrim: "",
  //   KLFU_Kodi: "",
  // };

  let defaultState = {
    Aktiv: 1,
    Operator: String(user.username),
    Nipt: String(user.nipt),
    E_Paguar: 0,
    // Kodi: 1,
    Hapur: formattedDateTime,
    Updated: formattedDateTime,
    Data: getFormattedDate(),
    KLFU_Kodi: null,
    Nipt: null,
    KLFU_Pershkrim: "",
    Menyra_Pageses_Id: 6,
    Pike_Fitim: 0,
    Tavolina: null,
    Tip_Cmimi: "CMIMI",
    Tipi: "FTSH",
  };

  const [state, setState] = useState(defaultState);

  // New useEffect to update dtlNum whenever dtlState changes
  useEffect(() => {
    setDtlNum(dtlState.length); // Update dtlNum when dtlState changes
  }, [dtlState]);

  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  return (
    <MyContext.Provider
      value={{
        state,
        setState,
        currentAction,
        setCurrentAction,
        updateState,
        dtlState,
        setDtlState,
        loadingLeftBar,
        setLoadingLeftBar,
        open,
        setOpen,
        aExistTable,
        setTableExist,
        gjeneroFatureAnulluese,
        setGjeneroFatureAnulluese,
        dtlNum,
        setDtlNum,
        defaultState,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

// Custom hook to use the context
export const useMyContext = () => useContext(MyContext);
