import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Navbar/Footer";
import "./Ndermarrje.scss";
import { Button, ButtonGroup } from "@mui/material";
import SideBar from "../../components/Navbar/SideBar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Panel } from "primereact/panel";
import Arka from "../fature-blerje/minimodal/ArkaPopUp";
import mainAxios from "../../services/axios";
import Checkbox from "@mui/material/Checkbox";
import { getKonfigurime } from "../../store/globalSlice";
import { useDispatch, useSelector } from "react-redux";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Ndermarrje = ({ isMobile, currentAction }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  // const [age, setAge] = React.useState("");

  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  // const handleChangeDropDown = (event) => {
  //   setAge(event.target.value);
  // };

  const currentId = {
    Id: konfigurimeRedux[0]?.Id || "",
  };

  const [formData, setFormData] = useState({
    Nipt: konfigurimeRedux[0]?.Nipt || "",
    Pershkrim: konfigurimeRedux[0]?.Pershkrim || "",
    NrAmzes: konfigurimeRedux[0]?.NrAmzes || "",
    Adresa1: konfigurimeRedux[0]?.Adresa1 || "",
    Tel: konfigurimeRedux[0]?.Tel || "",
    Cel: konfigurimeRedux[0]?.Cel || "",
    Fax: konfigurimeRedux[0]?.Fax || "",
    www: konfigurimeRedux[0]?.www || "",
    Email: konfigurimeRedux[0]?.Email || "",
    Mon: konfigurimeRedux[0]?.Mon || "",
    Metvsh: konfigurimeRedux[0]?.Metvsh || false,
    PrintAccent: konfigurimeRedux[0]?.PrintAccent || false,
  });

  const handleChangeNdermarrje = async () => {
    const payload = {
      ...formData,
    };

    try {
      const response = await mainAxios.put(
        `/konfigurim/${currentId.Id}`,
        payload
      );

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "U dergua me sukses",
          life: 2000,
        });
        dispatch(getKonfigurime("/konfigurim"));
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Gabim ne dergimin e te dhenave",
        life: 2000,
      });
    }
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="mainDiv_ftsh">
      <Toast ref={toast}></Toast>
      <NavBar currentAction={currentAction} />

      <div className={isMobile ? null : "template_konfig"}>
        <div className="p-2 main-container">
          <div className="top_panel_konfig">
            <Panel>
              <Grid container direction="row" sx={{ marginTop: 2 }}>
                <Box sx={{ flexGrow: 2 }}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        label="Pershkrim"
                        variant="outlined"
                        size="large"
                        style={{ width: 470 }}
                        value={formData.Pershkrim}
                        onChange={(e) =>
                          handleChange("Pershkrim", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        label="NIPT"
                        variant="outlined"
                        size="large"
                        style={{ width: 470 }}
                        value={formData.Nipt}
                        onChange={(e) => handleChange("Nipt", e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        label="Nr.Amzes"
                        variant="outlined"
                        size="large"
                        style={{ width: 470 }}
                        value={formData.NrAmzes}
                        onChange={(e) =>
                          handleChange("NrAmzes", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        label="Adresa"
                        variant="outlined"
                        size="large"
                        style={{ width: 470 }}
                        value={formData.Adresa1}
                        onChange={(e) =>
                          handleChange("Adresa1", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        label="tel"
                        variant="outlined"
                        size="large"
                        style={{ width: 470 }}
                        value={formData.Tel}
                        onChange={(e) => handleChange("Tel", e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        label="cel"
                        variant="outlined"
                        size="large"
                        style={{ width: 470 }}
                        value={formData.Cel}
                        onChange={(e) => handleChange("Cel", e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        required
                        label="fax"
                        variant="outlined"
                        size="large"
                        style={{ width: 470 }}
                        value={formData.Fax}
                        onChange={(e) => handleChange("Fax", e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        required
                        label="web"
                        variant="outlined"
                        size="large"
                        style={{ width: 470 }}
                        value={formData.www}
                        onChange={(e) => handleChange("www", e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        required
                        label="email"
                        variant="outlined"
                        size="large"
                        style={{ width: 470 }}
                        value={formData.Email}
                        onChange={(e) => handleChange("Email", e.target.value)}
                      />

                      <div className="flex">
                        Biznes me tvsh{" "}
                        <Checkbox
                          {...label}
                          checked={formData.Metvsh} // Bind to the state value
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              Metvsh: e.target.checked,
                            })
                          } // Update state on change
                        />
                      </div>

                      <div className="flex">
                        Printo me 58mm{" "}
                        <Checkbox
                          {...label}
                          checked={formData.PrintAccent} // Bind to the state value
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              PrintAccent: e.target.checked,
                            })
                          } // Update state on change
                        />
                      </div>

                      {/* <FormControl style={{ width: "200px", height: "30px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Age
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={age}
                          label="Age"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Grid>

                    <Grid item xs={8}>
                      <ButtonGroup
                        sx={!isMobile ? { marginTop: 5 } : { marginTop: 5 }}
                      >
                        <Button
                          sx={{ marginRight: 6 }}
                          variant="contained"
                          component="span"
                          onClick={handleChangeNdermarrje}
                        >
                          Ruaje
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Arka open={open} handleClose={handleClose} />
            </Panel>
          </div>
        </div>
        {!isMobile && <SideBar />}
      </div>
      {!isMobile && <Footer className="footer-position" />}
    </div>
  );
};

export default Ndermarrje;
