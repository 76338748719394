// src/components/DropdownMenu/DropdownMenu.js

import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const DropdownMenu = ({
  anchorEl,
  open,
  handleClose,
  username,
  nipt,
  onSignOut,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          width: 300,
          maxHeight: 500,
          "& .username-nipt": {
            padding: "10px",
            alignSelf: "center",
            textAlign: "center",
          },
          "& .view-profile-button": {
            width: "100%",
            textAlign: "center",
          },
          "& .MuiMenuItem-root": {
            textAlign: "left",
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <div className="flex" style={{ flexDirection: "column" }}>
          <Avatar sx={{ width: 38, height: 38, alignSelf: "center" }} />
          <div className="username-nipt">
            <span>{username}</span>
            <br />
            <span>{nipt}</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <Button
            style={{
              width: "90%",
              border: "1px solid #EEEDEB",
              borderRadius: "5px",
              color: "#000",
              textTransform: "none",
              fontSize: "12px",
            }}
            component={Link}
            to="/celje/perdorues"
          >
            <span style={{ color: "#000" }}>View Profile</span>
          </Button>
        </div>
      </div>
      <MenuItem onClick={onSignOut}>Sign Out</MenuItem>
      <Divider />
      <h6
        className="switch-accounts"
        style={{ textAlign: "left", marginLeft: "15px", marginTop: "15px" }}
      >
        Switch Accounts
      </h6>
      <MenuItem component={Link} to="/celje/perdorues">
        <Avatar
          style={{
            width: 42,
            height: 42,
            marginRight: "10px",
            backgroundColor: "#EEEDEB",
          }}
        >
          <AddIcon fontSize="small" style={{ color: "#000" }} />
        </Avatar>
        <span style={{ color: "black" }}>Add account</span>
      </MenuItem>
    </Menu>
  );
};

export default DropdownMenu;
