import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import mainAxios from "../../../../../services/axios";
import { useUser } from "../../../../../zustand/common";

export default function PopupMenu({
  shikoTotalin,
  getDtl,
  totaliFatura,
  totalBtnDisabled,
}) {
  const { user } = useUser();
  const operator = user.username;
  const niptCurrent = user.nipt;
  const [open, setOpen] = React.useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [username, setUsername] = React.useState(operator);
  const [password, setPassword] = React.useState("");
  const [nipt, setNipt] = React.useState(niptCurrent); // State for NIPT
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      event &&
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
  };

  const handleLoginSubmit = async () => {
    try {
      const response = await mainAxios.post("/login", {
        username,
        nipt,
        password,
      });
      if (response.status === 200) {
        if (response.data.automatikisht === 1) {
          getDtl();
          handleLoginClose();
        } else {
          alert("Duhet te jeni Admin");
        }
      } else {
        alert("Invalid username, NIPT, or password");
      }
    } catch (error) {
      alert("Login failed. Please try again.");
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <div>
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <MoreVertIcon style={{ color: "white", fontSize: "40px" }} />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        disabled={totalBtnDisabled}
                        onClick={() => {
                          shikoTotalin();
                          handleClose();
                        }}
                      >
                        {!totaliFatura ? "Shiko Totalin" : "Shiko Aktualen"}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setLoginOpen(true); // Open the login modal
                          handleClose();
                        }}
                      >
                        Me nis nje Anulluese
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>

      {/* Login Dialog */}
      <Dialog open={loginOpen} onClose={handleLoginClose}>
        <DialogTitle>Login Required</DialogTitle>
        <DialogContent>
          <form autoComplete="off">
            {" "}
            {/* Disable autocomplete */}
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="Username"
              type="username"
              fullWidth
              variant="standard"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="new-password"
            />
            <TextField
              margin="dense"
              id="nipt"
              label="NIPT"
              type="text"
              fullWidth
              variant="standard"
              value={nipt}
              onChange={(e) => setNipt(e.target.value)}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLoginClose}>Cancel</Button>
          <Button onClick={handleLoginSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
