import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Input } from "@mui/material";
import { MultiSelect } from "primereact/multiselect";
import useStorage from "../../hooks/useStorage";
import { Dropdown } from "primereact/dropdown";
import * as XLSX from "xlsx";
import "../../components/primeReact/PrimeGrid.scss";

const PrimeGridKF = ({
  disabled,
  columns,
  setColumns,
  rows,
  isMobile,
  data,
  setRows,
  isEditable = true,
  showHideColumns = true,
  gridKey,
  calculateData,
  state,
  defaultState,
  setState,
  Total,
  setdata,
  fromAgjenti,
  defaultColumnsStorage,
  setLastData,
  currentAction,
  ...props
}) => {
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [filters1, setFilters1] = useState(null);

  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonat" + gridKey
  );

  const [first2, setFirst2] = useState(0);
  const [rows2, setRows2] = useState(10);

  const onCustomPage2 = (event) => {
    setFirst2(event.first);
    setRows2(event.rows);
  };

  const template2 = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Rrjeshta
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const clearFilter1 = () => {
    initFilters1();
  };

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      columns: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue1("");
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event?.value;
    let orderedSelectedColumns = columns
      ?.filter((col) =>
        selectedColumns?.some((sCol) => sCol?.field === col?.field)
      )
      .map((col) => col.field);
    setselectedcolumns(orderedSelectedColumns);
  };

  const filterdColumns = showHideColumns
    ? columns?.filter((column) =>
        selectedColumns?.some((field) => field === column?.field)
      )
    : columns;

  const header = (
    <>
      <div className="flex justify-between">
        <span style={{ width: "15rem" }} className="p-input-icon-left ">
          <i className="pi pi-search" />
          <InputText
            style={{ height: "2em" }}
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Kërko me pershkrim"
            onClick={clearFilter1}
          />
        </span>

        <div className="flex file-choosen">
          {showHideColumns && (
            <MultiSelect
              value={filterdColumns}
              options={columns}
              optionLabel="title"
              onChange={onColumnToggle}
              style={{ width: "3em", height: "2em", marginRight: "1em" }}
            />
          )}
          <Input
            accept=".xlsx, .xls"
            style={{ display: "none" }}
            id="file-input"
            type="file"
            onChange={(e) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
          />
        </div>
      </div>
    </>
  );

  const dynamicColumns = filterdColumns?.map((col, i) => {
    return (
      <Column
        // filter
        style={{ height: "2.6rem" }}
        key={col?.field}
        field={col?.field}
        header={col?.title}
        calculateData={calculateData}
        decimalPlaces={col?.decimalPlaces || 0}
        body={(rowData) => {
          const value = rowData[col?.field];
          let formattedValue;
          if (typeof value === "number") {
            if (Number.isInteger(value)) {
              formattedValue = value.toLocaleString();
            } else {
              formattedValue = value.toLocaleString();
              formattedValue = value.toFixed(2);
            }
          } else {
            formattedValue = value;
          }
          return <span>{formattedValue}</span>;
        }}
      />
    );
  });

  const cm = useRef(null);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        // Update the state with the imported data
        setdata(data);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise
      .then((d) => {
        setdata(d);
      })
      .catch((error) => {
        console.error("Error reading Excel file:", error);
      });
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [selectedRowData, setSelectedRowData] = useState(null);

  useEffect(() => {
    if (data && data.length > 0) {
      handleRowClick(data[0]);
    }
  }, [data]);

  const handleRowClick = (selectedRowData) => {
    if (currentAction === "add" || currentAction === "modify") {
      alert("Klikoni Regjistro ose Anullim per te selektuar.");
      return;
    } else {
      setLastData(selectedRowData);
      setState(selectedRowData);
      setSelectedRowData(selectedRowData);
    }
  };

  return (
    <div className="card">
      <div className="prime-react">
        <DataTable
          editMode="cell"
          className={"editable-cells-table artikuj data-table-ftsh"}
          paginator
          paginatorTemplate={template2}
          first={first2}
          rows={rows2}
          onPage={onCustomPage2}
          paginatorClassName="justify-content-end"
          reorderableColumns
          cellSelection
          scrollHeight={isMobile ? null : "80%"}
          inline
          showGridlines
          scrollable
          columnResizeMode="fit"
          resizableColumns
          rowClassName={(rowData) =>
            selectedRowData &&
            (fromAgjenti
              ? selectedRowData.ID === rowData.ID
              : selectedRowData.Id === rowData.Id)
              ? "selected-row"
              : ""
          }
          onSelectionChange={(e) => {
            const selectedRowData =
              e.value.length > 0 ? e.value[0].rowData : null;
            handleRowClick(selectedRowData);
          }}
          // responsiveLayout="stack"
          contextMenuSelection={selectedProduct}
          contextselection={selectedProduct}
          onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
          onContextMenu={(e) => cm.current.show(e.originalEvent)}
          size="small"
          value={data}
          stripedRows
          dataKey="Id"
          filters={filters1}
          header={header}
          globalFilterFields={[
            "BarKod",
            "Pershkrim",
            "Njesi_Kodi",
            "Sasia_Print",
            "Sasia",
          ]}
          responsiveLayout="scroll"
          filterDisplay="menu"
          emptyMessage="Nuk ka te dhena"
          {...props}
        >
          {dynamicColumns}
        </DataTable>
      </div>
    </div>
  );
};

export default PrimeGridKF;
