import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../print.scss";
import TotalFooterFD from "./TotalFooter";

const formatNumber = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    return (
      number % 1 === 0 ? number.toFixed(0) : number.toFixed(2),
      number.toLocaleString()
    );
  }

  return "";
};

const DynamicTableFD = ({ rows, state }) => {
  const DisplayData = rows.map((info, index) => {
    const rowNumber = index + 1;
    return (
      <tr key={info.Id}>
        <td className="solid-border">{rowNumber}</td>
        <td className="pershkrimArtikull">{info.Kodi}</td>
        <td className="pershkrimArtikull">{info.Pershkrim}</td>
        <td>{info.Sasia}</td>
        <td className="pershkrimArtikull">{info.Njesi_Kodi}</td>
        <td>{formatNumber(info.Cmimi_Ftsh)}</td>
        <td>{formatNumber(info.Cmimi) - formatNumber(info.Cmimi_Ftsh)}</td>
        <td>{formatNumber(info.Vlera_Eksport)}</td>
        <td>{formatNumber(info.Cmimi)}</td>
        <td>{formatNumber(info.Vlera)}</td>
      </tr>
    );
  });

  const sums = {
    Vlera: formatNumber(rows.reduce((total, info) => total + info.Vlera, 0)),
  };
  return (
    <table className="HeaderTable border">
      <thead>
        <tr>
          <td className="title" colSpan={8}>
            <b>Flete Dalje</b>
          </td>
        </tr>
      </thead>
      <table className="table table-striped bordered table-body">
        <thead className="thead thead-striped ">
          <tr>
            <th>Nr</th>
            <th>Kodi</th>
            <th>Artikulli</th>
            <th>Sasia</th>
            <th>Njesi</th>
            <th>Cmimi nga Fat.Shitje</th>
            <th>Shpenzime per njesi</th>
            <th>Eksporti per njesi</th>
            <th>Cmimi</th>
            <th>Vlera</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td className="footer-gjeneruar" colSpan={8}>
              Gjeneruar nga Parid Smart Solution
            </td>
          </tr>
        </tfoot>
        <tbody className="tbody-print">
          {DisplayData}
          <tr>
            <td colSpan={8}>Vlera</td>

            <td colSpan={10}>{sums.Vlera}</td>
          </tr>
        </tbody>
      </table>
      <div className="footer-bleresi ">
        <TotalFooterFD state={state} className="m-2" />
      </div>
    </table>
  );
};

export default DynamicTableFD;
