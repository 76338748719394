import React, { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import MainComponent from "./MainComponent/MainComponent";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { useUser } from "../../../../zustand/common";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({
  open,
  handleClose,
  mbylleTav,
  numTav,
  newId,
  state,
  setState,
  formatDate,
  setError,
  showToast,
  fetchPos,
  isMobile,
  error,
  kls01,
  arka,
  menyra,
  setOpen,
}) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { user } = useUser();
  const operator = user.username;

  // Function to extract and format the time from the updated date string
  const formatTime = (dateString) => {
    let date;

    // Check if the date string is provided
    if (dateString) {
      // Try parsing the date string
      date = new Date(dateString);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        return "Invalid Date"; // Handle invalid date format
      }
    } else {
      return "No Date Provided"; // Handle missing date string
    }

    // Get hours, minutes, and seconds
    let hours = date.getHours(); // Get local hours
    const minutes = date.getMinutes(); // Get minutes
    const seconds = date.getSeconds(); // Get seconds

    // Determine AM or PM suffix
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format hours, minutes, and seconds to ensure two digits
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`; // Return time in HH:MM:SS AM/PM format
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullscreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      });
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: "#ececec",
            boxShadow: "none",
            color: "#000",
            height: "6%",
          }}
        >
          <Toolbar>
            <Typography
              sx={{
                ml: 2,
                display: "flex",
                flexDirection: "row",
                color: "#000",
                fontWeight: "600",
              }}
              component="div"
            >
              <div className="tavolina_Toolbar">Operator: {operator}</div>
              <ChevronRightIcon sx={{ color: "#B7B7B7" }} />
              <div className="tavolina_Toolbar">Tavolina: {state.Tavolina}</div>
              <ChevronRightIcon sx={{ color: "#B7B7B7" }} />
              <div
                className="ora_Toolbar"
                style={{
                  marginLeft: "5px",
                  color: "#454545",
                  backgroundColor: "#dbdbdbaf",
                  borderRadius: "10%",
                }}
              >
                Ora: {formatTime(state.Updated)}
              </div>
            </Typography>
            <Typography
              sx={{ ml: 2, mr: 2, flex: 2, color: "#000" }}
              component="div"
            ></Typography>
            {isMobile ? (
              ""
            ) : (
              <IconButton
                onClick={() => {
                  toggleFullscreen();
                }}
                sx={{ mr: 2 }}
              >
                {!isFullscreen ? (
                  <OpenInFullIcon
                    sx={{
                      width: 25,
                      height: 25,
                      color: "#000",
                      backgroundColor: "#dbdbdbaf",
                      borderRadius: "10%",
                    }}
                  />
                ) : (
                  <CloseFullscreenIcon
                    sx={{
                      width: 25,
                      height: 25,
                      color: "#000",
                      backgroundColor: "#dbdbdbaf",
                      borderRadius: "10%",
                      marginRight: "5px",
                    }}
                  />
                )}
              </IconButton>
            )}
            <IconButton
              edge={false}
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{
                width: 30,
                height: 30,
                color: "#000",
                backgroundColor: "#dbdbdbaf",
                borderRadius: "10%",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MainComponent
          newId={newId}
          isMobile={isMobile}
          state={state}
          setState={setState}
          numTav={numTav}
          handleClose={handleClose}
          mbylleTav={mbylleTav}
          formatDate={formatDate}
          showToast={showToast}
          fetchPos={fetchPos}
          setError={setError}
          error={error}
          kls01={kls01}
          arka={arka}
          menyra={menyra}
          setOpen={setOpen}
        />
      </Dialog>
    </React.Fragment>
  );
}
