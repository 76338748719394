import React from "react";

const HandleInputCmimiChange = ({ item }) => {
  return (
    <div className="number_leftbar">
      <div
        style={{
          width: "100%",
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          alignItems: "center", // Center the text vertically
          height: "100%", // Adjust height as needed
        }}
      >
        {Number.isInteger(item.Cmimi) ? item.Cmimi : item.Cmimi.toFixed(2)}
      </div>
    </div>
  );
};

export default HandleInputCmimiChange;
