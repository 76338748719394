// ColorUtility.js
const getColor = (theme, word) => {
  const value = word || "";
  const firstWord = value.trim().split(/\s+/)[0].toUpperCase();
  const alphabetColors = {
    A: "#ADC4CE",
    B: "#D4E2D4",
    C: "#FF9B9B",
    D: "#ECE5C7",
    E: "#AFC8AD",
    F: "#FFE382",
    G: "#D2E9E9",
    H: "#E3F4F4",
    I: "#B6C4B6",
    J: "#D5B4B4",
    K: "#EBE3D5",
    L: "#CCD5AE",
    M: "#AEC2B6",
    N: "#FFDEB4",
    O: "#E4DCCF",
    P: "#DBDFEA",
    Q: "#86C8BC",
    R: "#E5EBB2",
    S: "#B7C4CF",
    T: "#E9DAC1",
    U: "#E4E9BE",
    V: "#DEB6AB",
    W: "#92BA92",
    X: "#7FFFD4",
    Y: "#CA965C",
    Z: "#E9DAC1",
  };

  return (
    alphabetColors[firstWord.charAt(0)] ||
    (theme.palette.mode === "dark" ? "#1A2027" : "#fff")
  );
};

export default getColor;
