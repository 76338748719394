import React, { useState, useRef, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import Slide from "@mui/material/Slide";
import { TextField } from "@mui/material";
import mainAxios from "../../services/axios";
import DataTable from "./DataTableTurni/DataTable";
import DialogConfirm from "./ConfirmDelete/DialogConfirm";
import "./Xhiro.scss";
import { Toast } from "primereact/toast";
import { LoadingOverlayContext } from "../../components/LoadingOverlay";
import PrintoTurnin from "../../components/ReactPDF/PrintoTurnin";
import DialogXhiro from "./DialogXhiro/DialogXhiro";
import PrintoFisPos from "../../components/ReactPDF/PrintoFisPos";
import DeleteFature from "./DeleteFature";
import ButtonMbyllXhiro from "./ButtonMbyllXhiro";
import CalcXhiro from "./CalcXhiro/CalcXhiro";
import HeaderLeft from "./FaturatEPambyllura/HeaderLeft";
import XhiroDyForma from "../../components/ReactPDF/XhiroDyForma";
import SkontrinoXhiroMain from "./FaqaPrintimitMain/SkontrinoXhiroMain";
import DynamicXhiroMain from "./FaqaPrintimitMain/DynamicXhiroMain";
import AnullimFature from "./AnullimFature/AnullimFature";
import { useSelector } from "react-redux";
import { useUser } from "../../zustand/common";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Xhiro({
  open,
  onClose,
  isFullscreen,
  setIsFullscreen,
}) {
  const { user } = useUser();
  const nipt = user.nipt;
  const [selectedId, setSelectedId] = useState("");
  const [dataCheck, setDataCheck] = useState([]);
  const [data, setData] = useState([]);
  // Redux: Get client data from global slice
  const perdoruesRedux = useSelector((state) => state.globalSlice.perdorues);
  const [operators, setOperators] = useState([]);
  const [totalRows, setTotalRow] = useState("");
  const [state, setState] = useState([]);
  const [currentFatura, setCurrentFatura] = useState({});
  const [faturaNIVF, setFaturaNIVF] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogXhiro, setDialogXhiro] = useState(false);
  const [loading, setLoading] = useState(false);
  const [faturatEPambyllura, setFaturatEPambyllura] = useState(false);
  const [faturatEPafiskalizuara, setFaturatEPafiskalizuara] = useState(false);
  const [searchWithKlient, setSearchWithKlient] = useState(false);
  const [selectedKlient, setSelectedKlient] = useState("");
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [totalVlera, setTotalVlera] = useState(0);
  const [totalVleraJoFiskale, setTotalVleraJoFiskale] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [mainData, setMainData] = useState(currentDate);
  const [ngaData, setNgaData] = useState(currentDate);
  const [deriData, setDeriData] = useState(currentDate);
  const [selectedOperator, setSelectedOperator] = useState(
    perdoruesRedux[0].Operator
  );
  const [data2, setData2] = useState([]);
  const [dataPosBak, setDataPosBak] = useState([]);
  const toast = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const perPage = 100;

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      // Move to the previous page
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    functionPost();
  }, [currentPage]);

  // Redux: Get client data from global slice
  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  // Redux: Get klient data from global slice
  const klientRedux = useSelector((state) => state.globalSlice.klienti);

  const [klients, setKlients] = useState([]);

  // Redux: Get client data from global slice
  const menyraRedux = useSelector((state) => state.globalSlice.menyraPageses);
  const [menyra, setMenyra] = useState([]);

  const handleOperatorSelection = (selectedOperatorId) => {
    setSelectedOperator(selectedOperatorId);
  };

  const handleKlientSelection = (selectedKlient) => {
    setSelectedKlient(selectedKlient);
  };

  const fetchData = async () => {
    try {
      if (perdoruesRedux && perdoruesRedux.length > 0) {
        setOperators(perdoruesRedux);

        // setSelectedOperator(perdoruesRedux[0]);
        // handleOperatorSelection(perdoruesRedux[0]);
      }
      if (klientRedux && klientRedux.length > 0) {
        setKlients(klientRedux);
        // setSelectedKlient(klientRedux[0]);
        // handleKlientSelection(klientRedux[0]);
      }
      if (menyraRedux && menyraRedux.length > 0) {
        setMenyra(menyraRedux);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = data.filter(
      (item) => item.TIPI === "" || item.TIPI === null
    );
    setDataPosBak(filteredData);
  }, [data]);

  useEffect(() => {
    if (data) {
      const selectedObject = data.find((item) => item.ID === selectedId);
      setCurrentFatura(selectedObject);
      if (data.length === 0) {
        setSelectedId("");
      }
    }
  }, [selectedId, data]);

  const showToast = (message, options) => {
    toast.current.show({
      severity: options?.severity || "info",
      summary: options?.summary || "Info",
      detail: message,
      life: 1500,
    });
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullscreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      });
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1450);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  useEffect(() => {
    const calculateTotalVlera = () => {
      const filteredDataFiskale = data.filter(
        (item) => item.TipPrintimi !== "Jo Fiskale"
      );

      const filteredDataJoFiskale = data.filter(
        (item) => item.TipPrintimi !== "Fiskale"
      );

      const total = filteredDataFiskale.reduce(
        (acc, curr) => acc + curr.VLERA,
        0
      );

      const totalJoFiskale = filteredDataJoFiskale.reduce(
        (acc, curr) => acc + curr.VLERA,
        0
      );
      setTotalVleraJoFiskale(totalJoFiskale);
      setTotalVlera(total);
    };

    calculateTotalVlera();
  }, [data]);

  const functionPost = async () => {
    try {
      showLoading();

      const operator = selectedOperator || "";
      const klient = selectedKlient || "";
      const page = currentPage;
      const perPage = 100;
      setTotalPages(perPage);
      const startDate = ngaData || "";
      const endDate = deriData || "";

      // Set flags
      let include_dates = ngaData && deriData ? 1 : 0;
      let include_tipi = faturatEPambyllura ? 1 : 0;
      let include_nivf_null = faturatEPafiskalizuara ? 1 : 0;

      // Logic for resetting or keeping date fields
      if (faturatEPambyllura) {
        setCurrentPage(1);
        // If 'faturatEPambyllura' is true, reset dates and disable date filtering
        include_dates = 0;
      } else {
        // Otherwise, preserve the selected dates
        setNgaData(startDate);
        setDeriData(endDate);
      }

      if (faturatEPafiskalizuara) {
        setCurrentPage(1);
        include_tipi = 1;
      }

      const params = {
        klient,
        operator,
        page,
        per_page: perPage,
        start_date: startDate,
        end_date: endDate,
        include_tipi,
        include_nivf_null,
        include_operator: selectedOperator ? 1 : 0,
        include_dates, // Final value of include_dates
        include_klfu_pershkrim: selectedKlient ? 1 : 0,
      };

      const response = await mainAxios.get("/pos/faturat/turni", { params });

      // Ensure the response data is an array before setting it
      if (Array.isArray(response.data.Data)) {
        setData(response.data.Data);
        setTotalRow(response.data.TotalCount);
      } else {
        console.warn("Response data is not an array:", response.data);
        setData([]); // Set to an empty array if not an array
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      hideLoading();
    }
  };

  const handleFiskalizim = async (handlePrint2) => {
    if (dataCheck.TIPI === "_BAK") {
      toast.current.show({
        severity: "info",
        summary: "Fatura eshte mbyllur",
      });
      return;
    }

    if (!selectedId) {
      toast.current.show({
        severity: "error",
        summary: "Selekto nje Fature",
      });
      return;
    }

    if (state.NIVF) {
      handlePrint2();
      return;
    }
    try {
      showLoading();
      const response = await mainAxios.post(`/pos/${selectedId}`);
      if (response.status === 200) {
        try {
          const firstItem = response.data;
          setState(firstItem);
          if (response.data?.ErrorCode !== "0") {
            const faultstring = firstItem.faultstring;

            showToast(faultstring, { severity: "error", summary: "Error" });
          }
          if (response.data?.ErrorCode === "0") {
            const response = await mainAxios(`/pos/${selectedId}`);

            if (response.status === 200) {
              setState(response.data);

              handlePrint2();
            }
          }
        } catch (error) {
          console.error(error);
          toast.current.show({
            severity: "error",
            summary: "Fatura nuk u fiskalizua",
          });
        } finally {
          functionPost();
        }
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Fatura nuk u fiskalizua",
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <Toast ref={toast} />

        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
              Mbyllje Turni
            </Typography>
            {!isMobile && (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="close"
                onClick={() => {
                  toggleFullscreen();
                }}
                sx={{ mr: 2 }}
              >
                {isFullscreen ? (
                  <ZoomInMapIcon sx={{ color: "white" }} />
                ) : (
                  <ZoomOutMapIcon sx={{ color: "white" }} />
                )}
              </IconButton>
            )}

            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              sx={{ ml: 2 }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogConfirm
          selectedId={selectedId}
          faturaNIVF={faturaNIVF}
          onClose={() => setDialogOpen(false)}
          setData={setData}
          open={dialogOpen}
          setDialogOpen={setDialogOpen}
          loading={loading}
          setLoading={setLoading}
          functionPost={functionPost}
          setSelectedId={setSelectedId}
          setData2={setData2}
          data={data}
        />

        <DialogXhiro
          nClose={() => setDialogXhiro(false)}
          open={dialogXhiro}
          functionPost={functionPost}
          setDialogXhiro={setDialogXhiro}
          ngaData={ngaData}
          deriData={deriData}
          mainData={mainData}
          selectedOperator={selectedOperator}
          dataPosBak={dataPosBak}
        />
        <div
          className="top_div_turni flex"
          style={{
            justifyContent: "space-between",
          }}
        >
          <HeaderLeft
            functionPost={functionPost}
            setSelectedOperator={setSelectedOperator}
            selectedOperator={selectedOperator}
            operators={operators}
            faturatEPambyllura={faturatEPambyllura}
            setFaturatEPambyllura={setFaturatEPambyllura}
            faturatEPafiskalizuara={faturatEPafiskalizuara}
            searchWithKlient={searchWithKlient}
            setFaturatEPafiskalizuara={setFaturatEPafiskalizuara}
            setSearchWithKlient={setSearchWithKlient}
            deriData={deriData}
            setKlients={setKlients}
            klients={klients}
            setDeriData={setDeriData}
            ngaData={ngaData}
            setNgaData={setNgaData}
            setSelectedKlient={setSelectedKlient}
            selectedKlient={selectedKlient}
            handleOperatorSelection={handleOperatorSelection}
            handleKlientSelection={handleKlientSelection}
          />
          {!isMobile && (
            <CalcXhiro
              totalVlera={totalVlera}
              totalVleraJoFiskale={totalVleraJoFiskale}
              selectedId={selectedId}
            />
          )}
        </div>

        <div className="main_div_turni">
          <div className="data_table_main_div">
            <DataTable
              data={data}
              showLoading={showLoading}
              hideLoading={hideLoading}
              setData={setData}
              setState={setState}
              setSelectedId={setSelectedId}
              totalRows={totalRows}
              setDataCheck={setDataCheck}
              handleNextPage={handleNextPage}
              currentPage={currentPage}
              totalPages={totalPages}
              perPage={perPage}
              handlePreviousPage={handlePreviousPage}
              setFaturaNIVF={setFaturaNIVF}
              ngaData={ngaData}
              deriData={deriData}
              selectedOperator={selectedOperator}
              setData2={setData2}
              data2={data2}
              loading={loading}
              selectedId={selectedId}
              state={state}
            />
          </div>

          <div className="below_component">
            <div className="data_table_main_div_second">
              <SkontrinoXhiroMain
                state={state}
                menyrapageses={menyra}
                nipt={nipt}
              />
              <DynamicXhiroMain rows={data2} state={state} />
            </div>
            <div className="right_div_mbyllje_turni">
              <List>
                <div className="flex" style={{ flexDirection: "column" }}>
                  <PrintoFisPos
                    handleFiskalizim={handleFiskalizim}
                    rows={data2}
                    state={state}
                    operator={selectedOperator}
                    menyra={menyra}
                    nipt={nipt}
                    xhiro={true}
                    showToast={showToast}
                    konfigurimeRedux={konfigurimeRedux}
                  />

                  <XhiroDyForma
                    handleFiskalizim={handleFiskalizim}
                    rows={data2}
                    state={state}
                    nipt={nipt}
                    selectedId={selectedId}
                    showToast={showToast}
                    menyra={menyra}
                    xhiro={true}
                    konfigurimeRedux={konfigurimeRedux}
                  />

                  <PrintoTurnin
                    className="print"
                    state={data}
                    rows={data2}
                    dataNga={ngaData}
                    dataDeri={deriData}
                    // rows2={rows2}
                    operator={selectedOperator}
                  />
                  <AnullimFature
                    functionPost={functionPost}
                    selectedId={selectedId}
                    toast={toast}
                    currentFatura={currentFatura}
                  />
                  <DeleteFature
                    setDialogOpen={setDialogOpen}
                    selectedId={selectedId}
                    toast={toast}
                    currentFatura={currentFatura}
                  />

                  <TextField
                    // disabled={false}
                    type="date"
                    label="Dt.Gjenerimi"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={mainData}
                    onChange={(e) => setMainData(e.target.value)}
                    style={{ margin: "5px" }}
                  />
                  <ButtonMbyllXhiro
                    setDialogXhiro={setDialogXhiro}
                    mainData={mainData}
                    ngaData={ngaData}
                    deriData={deriData}
                    operator={selectedOperator}
                    dataPosBak={dataPosBak}
                  />
                </div>

                <Divider />
              </List>
            </div>
          </div>
          {/* {isMobile ? (
            <CalcXhiro
              totalVlera={totalVlera}
              totalVleraJoFiskale={totalVleraJoFiskale}
              selectedId={selectedId}
            />
          ) : (
            ""
          )} */}
        </div>
      </Dialog>
    </React.Fragment>
  );
}
