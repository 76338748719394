import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import TePergjithshme from "./TePergjithshme";
// import Cmime from "./Cmime";
// import Klasifikime from "./Klasifikime";
import "./Artikuj.scss";

const PaneliArtikuj = ({
  disabled,
  state,
  setState,
  formatDate,
  isMobile,
  handleChangeTarifa,
  isEditingAllowed,
  updateRowData,
}) => {
  const [selektGrup, setSelektGrup] = useState([]);
  const [selektSkema, setSelektSkema] = useState([]);
  // const [selektOrigjina, setSelekOrigjina] = useState([]);
  // const [selektMarka, setSelektMarka] = useState([]);
  // const [selektGjinia, setSelektGjinia] = useState([]);
  // const [selektSezoni, setSelektSezoni] = useState([]);
  // const [selektLlojiMallit, setSelektLlojiMallit] = useState([]);
  // const [selektCilesia, setSelektCilesia] = useState([]);
  const [selektNjesia, setSelektNjesia] = useState([]);
  // const [selektNgjyra, setSelektNgjyra] = useState([]);
  // const [selektMasa, setSelektMasa] = useState([]);
  // const [selektMateriali, setSelektMateriali] = useState([]);

  // const [selektKls2, setSelektKls2] = useState([]);
  // const [selektKls3, setSelektKls3] = useState([]);
  // const [selektKls4, setSelektKls4] = useState([]);
  // const [selektKls5, setSelektKls5] = useState([]);
  // const [selektKls6, setSelektKls6] = useState([]);

  // const [selektVlereShitje, setSelektVlereShitje] = useState([]);
  // const [selektVlereBlerje, setSelektVlereBlerje] = useState([]);
  // const [selektVlereImport, setSelektVlereImport] = useState([]);

  return (
    <div className="card_artikuj">
      <TabView className="tabview_div_artikuj">
        <TabPanel header="Te Pergjithshme" className="tabpanel_div_artikuj">
          <TePergjithshme
            isEditingAllowed={isEditingAllowed}
            updateRowData={updateRowData}
            handleChangeTarifa={handleChangeTarifa}
            isMobile={isMobile}
            state={state}
            setState={setState}
            disabled={disabled}
            selektSkema={selektSkema}
            setSelektSkema={setSelektSkema}
            setSelektGrup={setSelektGrup}
            selektGrup={selektGrup}
            selektNjesia={selektNjesia}
            setSelektNjesia={setSelektNjesia}
          />
        </TabPanel>

        {/* <TabPanel header="Cmime">
          <Cmime
            state={state}
            setState={setState}
            handleChange={handleChange}
            formatDate={formatDate}
            disabled={disabled}
          />
        </TabPanel>

        <TabPanel header="Klasifikime">
          <Klasifikime
            state={state}
            setState={setState}
            handleChange={handleChange}
            disabled={disabled}
            selektKls2={selektKls2}
            setSelektKls2={setSelektKls2}
            selektKls3={selektKls3}
            setSelektKls3={setSelektKls3}
            selektKls4={selektKls4}
            setSelektKls4={setSelektKls4}
            selektKls5={selektKls5}
            setSelektKls5={setSelektKls5}
            selektKls6={selektKls6}
            setSelektKls6={setSelektKls6}
            selektVlereShitje={selektVlereShitje}
            setSelektVlereShitje={setSelektVlereShitje}
            selektOrigjina={selektOrigjina}
            setSelekOrigjina={setSelekOrigjina}
            selektVlereBlerje={selektVlereBlerje}
            setSelektVlereBlerje={setSelektVlereBlerje}
            selektVlereImport={selektVlereImport}
            setSelektVlereImport={setSelektVlereImport}
            selektMarka={selektMarka}
            setSelektMarka={setSelektMarka}
            selektGjinia={selektGjinia}
            setSelektGjinia={setSelektGjinia}
            selektSezoni={selektSezoni}
            setSelektSezoni={setSelektSezoni}
            selektLlojiMallit={selektLlojiMallit}
            setSelektLlojiMallit={setSelektLlojiMallit}
            selektCilesia={selektCilesia}
            setSelektCilesia={setSelektCilesia}
            selektNgjyra={selektNgjyra}
            setSelektNgjyra={setSelektNgjyra}
            selektMasa={selektMasa}
            setSelektMasa={setSelektMasa}
            selektMateriali={selektMateriali}
            setSelektMateriali={setSelektMateriali}
          />
        </TabPanel> */}
      </TabView>
    </div>
  );
};

export default PaneliArtikuj;
