import mainAxios from "../../../../../services/axios";
import { formatDate } from "../DateUtils";

// Function to handle updating all items
const handleUpdate = async (
  handlePrint4,
  {
    fromRegister,
    dtlState,
    currentID,
    showLoading,
    hideLoading,
    showToast,
    setOpen,
    mbylleTav,
    deletedRowIds,
    setDeletedRowIds,
  }
) => {
  showLoading();
  try {
    if (fromRegister) {
      if (deletedRowIds.length > 0) {
        for (const id of deletedRowIds) {
          await mainAxios.delete(`/pos/dtl/${id}`);
        }
        // Clear deletedRowIds after deletion
        setDeletedRowIds([]);
      }
    }
    if (fromRegister && dtlState.length === 0) {
      await handlePrint4();
      mbylleTav("Tavolina u mbyll", "info");
      return;
    } else {
      handlePrint4();
      // Prepare updated items data
      const updatedItems = dtlState.map((item) => {
        let tvsh = (item.Tvsh + 100) / 100;
        const skontoVlera =
          (item.Sasia * (item.Cmimi / tvsh) * item.Skonto) / 100;
        const vlera = item.Sasia * item.Cmimi;
        const vleraPaTvsh = item.Sasia * (item.Cmimi / tvsh) - skontoVlera;
        const tvshVlera = vlera - vleraPaTvsh;

        return {
          Master_Id: currentID,
          Id: item.Id,
          Pershkrim: item.Pershkrim,
          Sasia: item.Sasia.toFixed(2),
          Tvsh: item.Tvsh,
          Vlera: vlera.toFixed(2),
          Vlera_Pa_Tvsh: vleraPaTvsh.toFixed(2),
          Cmimi: item.Cmimi.toFixed(2),
          Tvsh_Vlera: tvshVlera.toFixed(2),
          Printuar: 1,
          Tipi: "AR",
          Kodi: item.Kodi,
          Koeficient: 1,
          BarKod: item.BarKod,
          Cmimi_Kosto: item.Cmimi_Kosto,
          Skonto: item.Skonto,
          Total: item.Total,
          Njesi_Kodi: item.Njesi_Kodi,
          Koeficient: item.Koeficient,
          NrLL: 0,
          Porosia: item.Porosia,
          Operator: item.Operator,
          Inserted: formatDate(new Date(), true),
          Updated: formatDate(new Date(), true),
          Tarifa_Kodi: item.Tarifa_Kodi,
        };
      });

      // Sending a single PUT request for all updated items
      const response = await mainAxios.put(`/pos/dtl/bulk`, updatedItems);

      if (response.status === 200) {
        // If the request was successful, update the clicked items state
        setOpen(false);
      }
    }
  } catch (error) {
    console.error("Error updating items:", error);
    showToast("Problem during updating items.");
  } finally {
    hideLoading();
  }
};

const handlePost = async (
  handlePrint2,
  {
    rows,
    setFilteredItems,
    dtlState,
    currentID,
    showLoading,
    hideLoading,
    showToast,
    mbylleTav,
  }
) => {
  if (rows.length === 0) {
    const message = `Nuk keni asnje artikull ne fature !`;
    showToast(message, { severity: "warn" });
    return;
  }
  showLoading();

  try {
    // Prepare updated items data
    const updatedItems = dtlState.map((item) => {
      let tvsh = (item.Tvsh + 100) / 100;
      const skontoVlera =
        (item.Sasia * (item.Cmimi / tvsh) * item.Skonto) / 100;
      const vlera = item.Sasia * item.Cmimi;
      const vleraPaTvsh = item.Sasia * (item.Cmimi / tvsh) - skontoVlera;
      const tvshVlera = vlera - vleraPaTvsh;

      return {
        Id: item.Id,
        Master_Id: currentID,
        Kodi: item.Kodi,
        BarKod: item.BarKod,
        Tvsh: item.Tvsh,
        Cmimi_Kosto: item.Cmimi_Kosto,
        Skonto: item.Skonto,
        Total: item.Total,
        Njesi_Kodi: item.Njesi_Kodi,
        Koeficient: item.Koeficient,
        Tipi: "AR",
        Koeficient: 1,
        NrLL: 0,
        Porosia: item.Porosia,
        Operator: item.Operator,
        Inserted: formatDate(new Date(), true),
        Updated: formatDate(new Date(), true),
        Tarifa_Kodi: item.Tarifa_Kodi,
        Pershkrim: item.Pershkrim,
        Sasia: item.Sasia.toFixed(2),
        Skonto_Vlera: skontoVlera.toFixed(2),
        Cmimi_Kosto: item.Cmimi_Kosto,
        Vlera: vlera.toFixed(2),
        Vlera_Pa_Tvsh: vleraPaTvsh.toFixed(2),
        Cmimi: item.Cmimi.toFixed(2),
        Tvsh_Vlera: tvshVlera.toFixed(2),
        Printuar: 1,
      };
    });

    // Sending a single PUT request for all updated items
    const response = await mainAxios.put(`/pos/dtl/bulk`, updatedItems);

    if (response.status === 200) {
      // grupo artikujt

      const responseSecond = await mainAxios(
        `/posdtl/consolidate/master/${response.data[0].Master_Id}`
      );

      // If the request was successful, update the clicked items state
      setFilteredItems(responseSecond.data);

      handlePrint2();
      mbylleTav("Tavolina u mbyll", "info");
    }
  } catch (error) {
    console.error("Error updating items:", error);
    showToast("Ndodhi problem.");
  } finally {
    hideLoading();
  }
}; // Return the functions for external use

export { handleUpdate, handlePost };
