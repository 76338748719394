import React from "react";
import "../FatureThjeshtuar.scss";
import "./Grupet.scss";
import { useSelector } from "react-redux";

const Grupet = ({ setSelectedKodi }) => {
  // Get grupi data from Redux
  const grupiRedux = useSelector((state) => state.globalSlice.grupi);
  const grupi = grupiRedux;

  // Handle selection and log the ID
  const handleSelect = (kodi) => {
    setSelectedKodi(kodi);
  };

  return (
    <div className="grupet_fature_thjeshtuar">
      {grupi && grupi.length > 0 ? (
        grupi.map((item) => (
          <div
            key={item.ID}
            className="grupi-item"
            onClick={() => handleSelect(item.Kodi)}
          >
            {item.Pershkrim}
          </div>
        ))
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default Grupet;
