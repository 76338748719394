import React from "react";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";

const TabTjera = ({
  toggleState,
  disabled,
  state,
  handleChange,
  formatDate,
}) => {
  return (
    <div className={toggleState ? "content  active-content" : "content"}>
      <div className={"grid grid-cols-2 gap-2"} style={{ height: "155px" }}>
        <div className=" relative w-full items-center justify-center rounded-tr-lg rounded-br-lg">
          <TextField
            fullWidth
            disabled={disabled}
            type="text"
            label="Kodi"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={state?.Check_Kodi || ""}
            onChange={(e) => handleChange("Check_Kodi", e.target.value)}
            size="small"
          />
        </div>

        <TextField
          fullWidth
          disabled={disabled}
          type="text"
          label="Routing number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={state?.Check_RoutingNumber || ""}
          onChange={(e) => handleChange("Check_RoutingNumber", e.target.value)}
          size="small"
        />

        <TextField
          fullWidth
          disabled={disabled}
          type="text"
          label="Account number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={state?.Check_AccountNumber || ""}
          onChange={(e) => handleChange("Check_AccountNumber", e.target.value)}
          size="small"
        />

        <TextField
          fullWidth
          disabled={disabled}
          type="text"
          label="Emer"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={state?.Check_Emri || ""}
          onChange={(e) => handleChange("Check_Emri", e.target.value)}
          size="small"
        />

        <TextField
          fullWidth
          disabled={disabled}
          type="text"
          label="Mbiemer"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={state?.Check_Mbiemri || ""}
          onChange={(e) => handleChange("Check_Mbiemri", e.target.value)}
          size="small"
        />

        <TextField
          fullWidth
          disabled={disabled}
          type="date"
          label="Data"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={formatDate(state?.Check_Data)}
          onChange={(e) => handleChange("Check_Data", e.target.value)}
          size="small"
        />
        <TextField
          fullWidth
          disabled={disabled}
          type="text"
          label="Banka"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={state?.Check_Banka_Pershkrim || ""}
          onChange={(e) =>
            handleChange("Check_Banka_Pershkrim", e.target.value)
          }
          size="small"
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              type="checkbox"
              size="small"
              checked={state?.Check_Eshte === 1}
              onChange={(e) =>
                handleChange("Check_Eshte", e.target.checked ? 1 : 0)
              }
            />
          }
          label="Aktiv"
        />
      </div>
    </div>
  );
};

export default TabTjera;
