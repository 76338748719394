import React, { useRef, useEffect } from "react";
import { Button } from "@mui/material";
import PopupState from "material-ui-popup-state";
import { useReactToPrint } from "react-to-print";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import "./Buttons.scss";
import SkontrinoThjeshtur from "../../../../components/ReactPDF/SkontrinoThjeshtuar";
import DynamicThjeshtuar from "../../../../components/ReactPDF/DynamicThjeshtuar";

const PrintoFisSupermarket = ({
  rows,
  selectedUserData,
  posSupermarketListaFaturave,
  nipt,
  selectedId,
  showToast,
  handleFiskalizimi,
  menyra,
  konfigurimeRedux,
  operator,
  state,
}) => {
  const componentsRef2 = useRef(null);
  const handlePrint2 = useReactToPrint({
    content: () => componentsRef2.current,
  });

  let fromLista = posSupermarketListaFaturave;

  // Function to handle F11 key press
  const handleKeyPress = (event) => {
    if (event.key === "F11") {
      event.preventDefault(); // Prevent default behavior of F11 (full screen)
      if (!selectedId) {
        showToast("Nuk ka fature te selektuar.");
      } else if (!state.NIVF) {
        if (rows.length === 0) {
          showToast("Nuk ka artikuj.");
          return;
        }
        handleFiskalizimi(handlePrint2, fromLista);
      } else if (state.NIVF) {
        handlePrint2();
      }
    }
  };

  useEffect(() => {
    // Add event listener for keydown events
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedId, state.NIVF, handleFiskalizimi, handlePrint2, fromLista]);

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {() => (
          <>
            {/* <Button
              variant="contained"
              className="btn_fiskal flex"
             
              style={{
                justifyContent: "space-between",
                backgroundColor: "green",
              }}
            >
              <PointOfSaleIcon />
              FISKALIZO
            </Button> */}

            <button
              onClick={() => {
                if (rows.length === 0) {
                  showToast("Nuk ka artikuj.");
                  return;
                }
                if (!selectedId) {
                  showToast("Nuk ka fature te selektuar.");
                  return;
                }
                if (!state.NIVF) {
                  handleFiskalizimi(handlePrint2);
                } else if (state.NIVF) {
                  handlePrint2();
                }
              }}
              className="circle-button_fature_thjeshtuar fiskal_fature_thjeshtuar"
            >
              Fiskal
            </button>
          </>
        )}
      </PopupState>
      <div ref={componentsRef2} className="print">
        <SkontrinoThjeshtur
          konfigurimeRedux={konfigurimeRedux}
          selectedUserData={selectedUserData}
          state={state}
          posSupermarketListaFaturave={posSupermarketListaFaturave}
          nipt={nipt}
          menyra={menyra}
          operator={operator}
        />

        <DynamicThjeshtuar rows={rows} state={state} />
      </div>
    </>
  );
};

export default PrintoFisSupermarket;
