import React, { useState, useEffect } from "react";
import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Checkbox } from "primereact/checkbox";

const TabTjeraCelje = ({
  toggleState,
  setState,
  disabled,
  handleChange,
  fromCeljeMagazina,
  state,
  TextCel,
  TextAdresa,
  TextEmail,
  TextEmerKontakti,
  TextQyteti,
  TextShenime,
  TextShteti,
  TextTel,
  TextTipCmimi,
  isTransport,
  TextAgjenti,
  isFurnitor,
  isAgjent,
  TextSkonto,
  AktivM,
  AktivBarPos,
  TextOperator,
  Aktiv1,
  Aktiv2,
  Aktiv3,
  Aktiv4,
  Aktiv5,
  Aktiv6,
  Aktiv7,
}) => {
  // shtetet redux
  const shtetRedux = useSelector((state) => state.globalSlice.shtetet);
  const shtetet = shtetRedux;

  // qyteti redux
  const qytetetRedux = useSelector((state) => state.globalSlice.qytetet);
  const qytetet = qytetetRedux;

  // agjenti redux
  const agjentiRedux = useSelector((state) => state.globalSlice.agjenti);
  const agjenti = agjentiRedux;

  // tipCmimi redux
  // const tipCmimiRedux = useSelector((state) => state.globalSlice.tipCmimi);
  // const tipCmimi = tipCmimiRedux;
  const tipCmimi = Array.from(
    { length: 10 },
    (_, index) => `CMIMI_${index + 1}`
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  const handleChangeAktiv = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: key === "Aktiv" ? (value ? true : false) : value,
      };
    });
  };

  return (
    <div className={toggleState ? "content  active-content" : "content"}>
      <div
        className={
          !isMobile
            ? isTransport
              ? "grid gap-2 grid-cols-2"
              : isFurnitor
              ? "grid gap-2 grid-cols-2"
              : isAgjent
              ? "grid gap-2 grid-cols-2"
              : "grid gap-2 grid-cols-2"
            : "grid gap-2 grid-cols-2"
        }
      >
        {fromCeljeMagazina ? (
          <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
            <Autocomplete
              disablePortal
              disabled={disabled}
              id="combo-box-qyteti"
              options={qytetet}
              filterOptions={filterOptions}
              getOptionLabel={(option) => (option ? option.Kodi : "")}
              onChange={(e, newValue) => {
                handleChange("Qyteti_Kodi", newValue ? newValue.Kodi : "");
                handleChange("Qyteti", newValue ? newValue.Pershkrim : "");
              }}
              value={
                qytetet.find((item) => item.Kodi === state?.Qyteti_Kodi) || null
              }
              size="small"
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Qyteti" />}
              renderOption={(props, option) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  {...props}
                >
                  <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                  <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                </div>
              )}
            />
          </div>
        ) : (
          ""
        )}
        {TextQyteti ? (
          <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
            <Autocomplete
              disablePortal
              disabled={disabled}
              id="combo-box-qyteti"
              options={qytetet}
              filterOptions={filterOptions}
              getOptionLabel={(option) => (option ? option.Kodi : "")}
              onChange={(e, newValue) =>
                handleChange("Qyteti_Kodi", newValue ? newValue.Kodi : "")
              }
              value={
                qytetet.find((item) => item.Kodi === state?.Qyteti_Kodi) || null
              }
              size="small"
              style={{ width: "100%", backgroundColor: "#fff" }}
              renderInput={(params) => (
                <TextField {...params} label="Qytetet" />
              )}
              renderOption={(props, option) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  {...props}
                >
                  <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                  <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                </div>
              )}
            />
          </div>
        ) : (
          ""
        )}
        {TextTel ? (
          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            inputProps={{ maxLength: 9 }}
            label="Tel"
            value={state?.Tel || ""}
            onChange={(e) => handleChange("Tel", e.target.value)}
            size="small"
            style={{ width: "100%" }}
          />
        ) : (
          ""
        )}

        {TextOperator ? (
          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            inputProps={{ maxLength: 9 }}
            label="Fis.Operator"
            value={state?.fisOperator || ""}
            onChange={(e) => handleChange("fisOperator", e.target.value)}
            size="small"
            style={{ width: "100%" }}
          />
        ) : (
          ""
        )}

        {TextCel ? (
          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            inputProps={{ maxLength: 12 }}
            label="Cel"
            value={state.Cel || ""}
            onChange={(e) => handleChange("Cel", e.target.value)}
            size="small"
            style={{ width: "100%" }}
          />
        ) : (
          ""
        )}
        {TextShteti ? (
          <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
            <Autocomplete
              disablePortal
              disabled={disabled}
              id="combo-box-shteti"
              options={shtetet}
              filterOptions={filterOptions}
              getOptionLabel={(option) => (option ? option.Kodi : "")}
              onChange={(e, newValue) =>
                handleChange("Shteti_Kodi", newValue ? newValue.Kodi : "")
              }
              value={
                shtetet.find((item) => item.Kodi === state?.Shteti_Kodi) || null
              }
              size="small"
              style={{ width: "100%", backgroundColor: "#fff" }}
              renderInput={(params) => (
                <TextField {...params} label="Shtetet" />
              )}
              renderOption={(props, option) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  {...props}
                >
                  <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                  <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                </div>
              )}
            />{" "}
          </div>
        ) : (
          ""
        )}
        {TextEmerKontakti ? (
          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            label="Emer Kontakti"
            value={state?.EmerKontakti || ""}
            onChange={(e) => handleChange("EmerKontakti", e.target.value)}
            size="small"
            style={{ width: "100%" }}
          />
        ) : (
          ""
        )}

        {TextEmail ? (
          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            label="Email"
            value={state?.Email || ""}
            onChange={(e) => handleChange("Email", e.target.value)}
            size="small"
            style={{ width: "100%" }}
          />
        ) : (
          ""
        )}

        {TextSkonto ? (
          <TextField
            disabled={disabled}
            onChange={(e) => handleChange("Skonto", e.target.value)}
            label="Apliko Skonto"
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={state?.Skonto || ""}
          />
        ) : (
          ""
        )}
        {TextAdresa ? (
          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            label="Adresa"
            value={state.Adresa || ""}
            onChange={(e) => handleChange("Adresa", e.target.value)}
            size="small"
            style={{ width: "100%" }}
          />
        ) : (
          ""
        )}
        {TextShenime ? (
          <TextField
            disabled={disabled}
            onChange={(e) => handleChange("Shenime", e.target.value)}
            label="Shenime"
            variant="outlined"
            size="small"
            style={{ width: "100%" }}
            value={state?.Shenime || ""}
          />
        ) : (
          ""
        )}
        {TextTipCmimi ? (
          <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
            <Autocomplete
              disablePortal
              disabled={disabled}
              id="combo-box-cmimi"
              options={tipCmimi}
              filterOptions={filterOptions}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) =>
                handleChange("Tip_Cmimi", newValue || "")
              }
              value={state?.Tip_Cmimi || ""}
              size="small"
              style={{ width: "100%", backgroundColor: "#fff" }}
              renderInput={(params) => (
                <TextField {...params} label="Tip_Cmimi" />
              )}
              renderOption={(props, option) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  {...props}
                >
                  <div style={{ textAlign: "start" }}>{option}</div>
                </div>
              )}
            />
          </div>
        ) : (
          ""
        )}

        {TextAgjenti ? (
          <div className="flex justify-start items-center w-full relative bg-gray-100 rounded-tr-lg rounded-br-lg">
            <Autocomplete
              disablePortal
              disabled={disabled}
              id="combo-box-agjenti"
              options={agjenti}
              filterOptions={filterOptions}
              getOptionLabel={(option) => (option ? option.Pershkrim : "")}
              onChange={(e, newValue) =>
                handleChange("Agjent_Kodi", newValue ? newValue.Kodi : "")
              }
              value={agjenti.find((item) => item.Kodi === state?.Agjent_Kodi)}
              size="small"
              style={{ width: "100%", backgroundColor: "#fff" }}
              renderInput={(params) => (
                <TextField {...params} label="Agjent %" />
              )}
              renderOption={(props, option) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  {...props}
                >
                  <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                  <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                </div>
              )}
            />
          </div>
        ) : (
          ""
        )}

        {AktivM ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.MobileActiv === true}
              onChange={(e) =>
                handleChangeAktiv(
                  "MobileActiv",
                  e.target.checked ? true : false
                )
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Aktiv Mob
            </label>
          </div>
        ) : (
          ""
        )}

        {AktivBarPos ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.mPOS === true}
              onChange={(e) =>
                handleChangeAktiv("mPOS", e.target.checked ? true : false)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              BAR/ POS User
            </label>
          </div>
        ) : (
          ""
        )}

        {/* aktive check for Web Rights */}
        {Aktiv1 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.AktivM === 1}
              onChange={(e) =>
                handleChangeAktiv("AktivM", e.target.checked ? 1 : 0)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Fature Shitje
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv2 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.AktivM === 1}
              onChange={(e) =>
                handleChangeAktiv("AktivM", e.target.checked ? 1 : 0)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Fature Blerje
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv3 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.AktivM === 1}
              onChange={(e) =>
                handleChangeAktiv("AktivM", e.target.checked ? 1 : 0)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Flete Hyrje
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv4 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.AktivM === 1}
              onChange={(e) =>
                handleChangeAktiv("AktivM", e.target.checked ? 1 : 0)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Flete Dalje
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv5 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.AktivM === 1}
              onChange={(e) =>
                handleChangeAktiv("AktivM", e.target.checked ? 1 : 0)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Artikuj
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv6 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.AktivM === 1}
              onChange={(e) =>
                handleChangeAktiv("AktivM", e.target.checked ? 1 : 0)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Bar / Restorant
            </label>
          </div>
        ) : (
          ""
        )}
        {Aktiv7 ? (
          <div className="flex items-center">
            <Checkbox
              disabled={disabled}
              checked={state?.AktivM === 1}
              onChange={(e) =>
                handleChangeAktiv("AktivM", e.target.checked ? 1 : 0)
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Raporte
            </label>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TabTjeraCelje;
