import React, { useState, useEffect, useRef, useContext } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import useStorage from "../../../../hooks/useStorage";
import mainAxios from "../../../../services/axios";
import { Toast } from "primereact/toast";
import "../../../../components/ShikoFaturat/SearchFaturat.scss";
import "../../../../components/ListaArtikujve/ModalList.scss";
import { LoadingOverlayContext } from "../../../../components/LoadingOverlay";
import HeaderMobile from "./HeaderMobile";
import HeaderDesc from "./HeaderDesc";
import ButtonGroup from "./ButtonGroup";

const GridList = ({ showToast, setListaDisplay, setState, setDtlState }) => {
  const fatureshitje = true;
  const gridKey = "FatureShitje";
  const contextKey = "fature/shitje";
  const columns = [
    { field: "Id", title: "Id", maxWidth: "100px" },
    { field: "Data", title: "Data", maxWidth: "150px" },
    { field: "Kodi", title: "Kodi", maxWidth: "150px" },
    { field: "KLFU_Kodi", title: "Klient Kodi", maxWidth: "150px" },
    { field: "KLFU_Pershkrim", title: "Klient", maxWidth: "150px" },
    { field: "Vlera", title: "Vlera", maxWidth: "150px" },
    { field: "Vlera_pa_tvsh", title: "Vlera pa TVSH", maxWidth: "150px" },
    { field: "Mon", title: "Mon", maxWidth: "150px" },
    { field: "Kursi", title: "Kursi", maxWidth: "150px" },
    { field: "Total", title: "Total", maxWidth: "150px" },
    { field: "NIVF", title: "NIVF", maxWidth: "150px" },
    { field: "NSLF", title: "NSLF", maxWidth: "150px" },
    { field: "Paguar", title: "Paguar", maxWidth: "150px" },
    { field: "Shenim", title: "Shenim", maxWidth: "150px" },
    { field: "Operator", title: "Operator", maxWidth: "150px" },
  ];
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1450);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1450);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  const onTestiChange = async (newTesti) => {
    try {
      //gjendet numri i fatures me Id = x
      const indexResponse = await mainAxios.get(
        `/fature/shitje/${newTesti.Id}`
      );

      if (indexResponse.status === 200) {
        setState(indexResponse.data);
        showToast("Fatura u zgjodh", { hideProgressBar: true });
        const responseDtl = await mainAxios.get(
          `/fature/shitje/dtl/${newTesti.Id}`
        );
        if (responseDtl.status === 200) {
          setDtlState(responseDtl.data);
        }
      }
    } catch (error) {
      showToast("Error ne zgjedhjen e fatures.", { severity: "error" });
      console.error("Error fetching data:", error);
    } finally {
      setListaDisplay(false);
    }
  };

  const [dataNga, setDataNga] = useState("");
  const [dataDeri, setDataDeri] = useState("");
  const [dataNgaOn, setDataNgaOn] = useState("");
  const [dataDeriOn, setDataDeriOn] = useState("");
  const [rows, setRows] = useState([]);
  const toast = useRef(null);
  const [nmrPage, setNmrPage] = useState(1);
  const [properties, setProperties] = useState([]);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);

  useEffect(() => {
    const currentDate = new Date();
    setDataDeri(currentDate.toISOString().split("T")[0]);
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    const formattedDate = sevenDaysAgo.toISOString().split("T")[0];
    setDataNga(formattedDate);
  }, []);

  const performSearch = async () => {
    showLoading();

    try {
      setNmrPage(1);
      const response = await mainAxios(
        `/${contextKey}/${dataNga}/${dataDeri}?page=1&&per_page=20`
      );

      const modifiedDataAR = response.data.map((item) => ({
        ...item,

        Data: item.Data ? item.Data.split("T")[0] : "",
      }));
      setRows(modifiedDataAR);
      setDataNgaOn(dataNga);
      setDataDeriOn(dataDeri);
      setProperties(response.data);
    } catch (err) {
      console.error(err);
      setRows([]);
      toast.current.show({
        severity: "error",
        summary: "Kerkimi deshtoi.",
      });
    } finally {
      hideLoading();
    }
  };

  const [selectedColumns, setselectedcolumns] = useStorage(
    columns,
    `kolonatEfaturave_${gridKey}`
  );

  const dynamicColumns = selectedColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.title}
        className={`truncate-text ${col.field}`} // Add a class name for each column
        sortable
        style={{
          maxWidth: col.maxWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      />
    );
  });

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setselectedcolumns(orderedSelectedColumns);
  };

  const fontOverlay = {
    fontSize: "11px",
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="card_shiko_faturat">
        <div className="datatable_faturat_main">
          <DataTable
            size="small"
            className="datatable_faturat_inside_main"
            gridKey="FaturatListe"
            header={
              isMobile ? (
                <HeaderMobile
                  dataNga={dataNga}
                  setDataNga={setDataNga}
                  dataDeri={dataDeri}
                  setDataDeri={setDataDeri}
                  performSearch={performSearch}
                  selectedColumns={selectedColumns}
                  columns={columns}
                  onColumnToggle={onColumnToggle}
                />
              ) : (
                <HeaderDesc
                  dataNga={dataNga}
                  setDataNga={setDataNga}
                  dataDeri={dataDeri}
                  setDataDeri={setDataDeri}
                  performSearch={performSearch}
                  selectedColumns={selectedColumns}
                  columns={columns}
                  onColumnToggle={onColumnToggle}
                />
              )
            }
            selectionMode="single"
            onSelectionChange={(e) => onTestiChange(e.value)}
            scrollable
            // virtualScrollerOptions={{ itemSize: 45 }}
            value={rows}
            responsiveLayout="scroll"
            style={fontOverlay}
          >
            {dynamicColumns}
          </DataTable>
        </div>

        <ButtonGroup
          fatureshitje={fatureshitje}
          nmrPage={nmrPage}
          properties={properties}
          setNmrPage={setNmrPage}
          setProperties={setProperties}
          setRows={setRows}
          showLoading={showLoading}
          hideLoading={hideLoading}
          toast={toast}
          contextKey={contextKey}
          dataNgaOn={dataNgaOn}
          dataDeriOn={dataDeriOn}
        />
      </div>
    </>
  );
};

export default GridList;
