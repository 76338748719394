import React, { useState, useContext } from "react";
import "./Buttons.scss";
import { Button } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ArkaPos from "../../../Restaurant/DialogButtons/ArkaPos";
import mainAxios from "../../../../../services/axios";
import "react-toastify/dist/ReactToastify.css";
import { LoadingOverlayContext } from "../../../../../components/LoadingOverlay";
import ShtimButton from "./Shtim";
import PrintoSupermarket from "../../../../../components/ReactPDF/PrintoSupermarket";
import PrintoFisSupermarket from "../../../../../components/ReactPDF/PrintoFisSupermarket";
import Typography from "@mui/material/Typography";
import { getPosByOperator } from "../../../../../store/globalPos";
import { useDispatch } from "react-redux";

const ButtonsSup = ({
  idNumber,
  handleFiskalizimi,
  setCurrentId,
  updateItemDetails,
  itemDetails,
  setRows,
  setItemDetails,
  state,
  setState,
  rows,
  operator,
  nipt,
  showToast,
  menyra,
  arka,
  konfigurimeRedux,
  handleShtim,
  fetchDataByOperator,
}) => {
  const dispatch = useDispatch();
  const [isArkaPosOpen, setIsArkaPosOpen] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleArkaButtonClick = () => {
    setIsArkaPosOpen(true);
  };

  const handleClose = () => {
    setIsArkaPosOpen(false);
  };

  const handleDelete = async () => {
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (state.NIVF) {
      const message = `Fatura eshte e fiskalizuar, nuk mund te fshihet !`;
      showToast(message, { severity: "info" });
      setIsDeleteDialogOpen(false);
      return;
    }
    showLoading();
    try {
      const deleteResponse = await mainAxios.delete(`/pos/${idNumber}`);
      if (deleteResponse.status === 200) {
        const message = `U fshi !`;
        showToast(message, { severity: "info" });
        const response = await dispatch(
          getPosByOperator(`/pos/filter?operator=${operator}`)
        );

        if (response.payload.status === 204) {
          handleShtim();
        }
        setIsDeleteDialogOpen(false);
      }
    } catch (error) {
      console.error(error);
      const message = `Ndodhi problem me fshirjen !`;
      showToast(message, { severity: "error" });
    } finally {
      hideLoading();
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <div className="buttonat_supermarket">
      <PrintoFisSupermarket
        handleFiskalizimi={handleFiskalizimi}
        posSupermarketListaFaturave={false}
        className="print"
        rows={rows}
        state={state}
        selectedId={idNumber}
        showToast={showToast}
        menyra={menyra}
        operator={operator}
        konfigurimeRedux={konfigurimeRedux}
      />

      <Button
        disabled={!idNumber}
        variant="contained"
        className="btn_fshi_fature"
        style={{ backgroundColor: "#FB6D48", justifyContent: "space-between" }}
        onClick={handleDelete} //Delete faturen
      >
        <ClearIcon />
        Fshi Fature
      </Button>

      <Button
        variant="contained"
        className="btn_arka_Supermarket"
        style={{ backgroundColor: "#FB8B24", justifyContent: "space-between" }}
        onClick={handleArkaButtonClick} // Open ArkaPos dialog
      >
        <MonetizationOnIcon />
        Arka
      </Button>

      <PrintoSupermarket
        fetchDataByOperator={fetchDataByOperator}
        posBar={true}
        hideLoading={hideLoading}
        showLoading={showLoading}
        className="print"
        rows={rows}
        state={state}
        showToast={showToast}
        selectedId={idNumber}
        menyra={menyra}
        operator={operator}
        konfigurimeRedux={konfigurimeRedux}
      />

      <ShtimButton
        setCurrentId={setCurrentId}
        setRows={setRows}
        setItemDetails={setItemDetails}
        updateItemDetails={updateItemDetails}
        idNumber={idNumber}
        itemDetails={itemDetails}
        state={state}
        setState={setState}
        operator={operator}
        nipt={nipt}
      />

      <ArkaPos open={isArkaPosOpen} onClose={handleClose} arka={arka} />

      <Dialog open={isDeleteDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Konfirmo Fshirjen</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            <h2>
              A jeni i sigurtë që dëshironi të fshini këtë faturë id: {state.Id}
              ?
            </h2>
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            onClick={handleConfirmDelete}
            color="error"
            autoFocus
          >
            Fshije
          </Button>
          <Button
            variant="contained"
            onClick={handleCancelDelete}
            color="primary"
          >
            Anulo
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ButtonsSup;
