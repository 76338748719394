import React, { useState, useRef } from "react";
import { Checkbox } from "primereact/checkbox";
import AddIcon from "@mui/icons-material/Add";
import { TextField } from "@mui/material";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import mainAxios from "../../../../services/axios";
import { Toast } from "primereact";
import { getTarifa } from "../../../../store/globalSlice";
import { useDispatch } from "react-redux";
import SmallButtonGroup from "../../../fature-blerje/Function/SmallButton";

const Tarifat = ({ numTarifa, tarifa }) => {
  const defaultState = {
    Aktiv: "",
    // "Def": "",
    // "DefArt": "",
    // "DefLl": "",
    //"DefSh": "",
    Kodi: "",
    // "NrLL_FTBL": "",
    //"NrLL_FTSH": "",
    Pershkrim: "", // "Tvsh 20 %",
    Tvsh: "", //num 20,
    // "eicType": "S",
    // "fisType": "VAT"
  };
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState(defaultState);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProduct1, setSelectedProduct1] = useState(null);
  const [check] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const toast = useRef(null);
  const dispatch = useDispatch();
  const submitHanlder = async () => {
    if (!state.Kodi.trim() || !state.Pershkrim.trim() || !state.Tvsh.trim()) {
      toast.current.show({
        severity: "error",
        summary:
          "Plotesoni fushat e kerkuara: " +
          (!state.Kodi.trim() ? "Kodi, " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim, " : "") +
          (!state.Tvsh.trim() ? "Tvsh " : ""),
      });
      return;
    }
    try {
      const responseTar = await mainAxios.post(`/tarifa`, {
        Pershkrim: state.Pershkrim,
        Kodi: state.Kodi,
        Tvsh: Number(state.Tvsh),
        Aktiv: state.Aktiv,
      });

      if (responseTar.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getTarifa("/tarifa"));

        toast.current.show({
          severity: "success",
          summary: "Tarifa u regjistrua.",
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Nuk u regjistrua. Kodi duhet te kete 2 karaktere.",
      });
    }
  };

  const deleteMethod = async (selectedRowId) => {
    try {
      const resDelete = await mainAxios.delete(`/tarifa/${selectedRowId}`);
      if (resDelete.status === 200) {
        setSelectedRowId(null);
        toast.current.show({
          severity: "info",
          summary: "U fshi.",
        });
        dispatch(getTarifa("/tarifa"));
      }
    } catch (err) {
      console.error(err);

      toast.current.show({
        severity: "error",
        summary: "Nuk mund te fshihet.",
      });
    }
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const handleChangeAktiv = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: key === "Aktiv" ? (value ? 1 : 0) : value,
      };
    });
  };

  const [columns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
  ]);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case columns:
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        if (newValue.length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
  };

  const cellEditor = (options, col) => {
    const column = options?.column;
    if (column?.props?.editColumn === false) {
      return <Column key={column.field} colSpan={1}></Column>;
    }

    return textEditor(options, col);
  };

  const handleRowClick = (rowData) => {
    setState({
      ...defaultState,
      Kodi: rowData.Kodi,
      Pershkrim: rowData.Pershkrim,
      Tvsh: rowData.Tvsh,
      Aktiv: rowData.Aktiv,
    });
    setSelectedRowId(rowData.Id);
    setDisabled(false);
  };

  const textEditor = (options, col) => {
    <InputText
      disabled={() => setDisabled(true)}
      className="cursor-pointer inp"
      type={col?.fieldType ?? "text"}
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}
    />;
  };

  //  useState i modaleve brenda kategorive ne fletblerje
  const [displayResponsive, setDisplayResponsive] = useState(false);

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };

  const onClick = (Pershkrim, position) => {
    dialogFuncMap[`${Pershkrim}`](true);
  };

  const onHide = (Pershkrim) => {
    dialogFuncMap[`${Pershkrim}`](false);
  };

  const cm = useRef(null);

  const dynamicColumns = columns.map((col, i) => {
    return check ? (
      <Column
        editor={(options) => cellEditor(options, col)}
        onCellEditComplete={onCellEditComplete}
        key={col.field}
        field={col.field}
        header={col.title}
      />
    ) : (
      <Column key={col.field} field={col.field} header={col.title} />
    );
  });

  return (
    <div>
      <form>
        <AddIcon
          fontSize="small"
          className="cursor-pointer"
          onClick={() => onClick("displayResponsive")}
        >
          Open
        </AddIcon>

        <Dialog
          header=" Tarifat"
          visible={displayResponsive}
          onHide={() => onHide("displayResponsive")}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "24.5vw" }}
        >
          <div className=" mt-2">
            <SmallButtonGroup
              buttonClicked={buttonClicked}
              deleteMethod={deleteMethod}
              setButtonClicked={setButtonClicked}
              setDisabled={setDisabled}
              disabled={disabled}
              setState={setState}
              defaultState={defaultState}
              submitHandler={submitHanlder}
              selectedRowId={selectedRowId}
            />
          </div>

          <div className="border flex flex-col p-2 relative mt-3">
            {/* <span className='absolute z-20' style={{top:-15,color:"#1971c2",backgroundColor:"white",fontWeight:500}}>Skema</span> */}

            <TextField
              disabled={disabled}
              type="text"
              variant="outlined"
              label="Kodi"
              inputProps={{ maxLength: "10" }}
              size="small"
              value={state?.Kodi}
              onChange={(e) => handleChange("Kodi", e.target.value)}
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              variant="outlined"
              label="Pershkrim"
              type="text"
              value={state?.Pershkrim}
              onChange={(e) => handleChange("Pershkrim", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />

            <div className="flex justify-between">
              <TextField
                disabled={disabled}
                type="text"
                variant="outlined"
                label="Tvsh"
                size="small"
                value={state?.Tvsh}
                onChange={(e) => handleChange("Tvsh", e.target.value)}
                className="mt-2 w-7/12"
              />
              <div className="flex items-center">
                <Checkbox
                  disabled={disabled}
                  checked={state?.Aktiv === 1}
                  onChange={(e) =>
                    handleChangeAktiv("Aktiv", e.target.checked ? 1 : 0)
                  }
                />
                {/* <Checkbox
                  disabled={disabled}
                  checked={state.Aktiv}
                  onChange={(e) => handleChangeAktiv("Aktiv", e.target.checked)}
                /> */}

                <label
                  label
                  style={{ marginLeft: "5px", fontWeight: 500 }}
                  htmlFor="cb2"
                  className="p-checkbox-label"
                >
                  Aktiv
                </label>
              </div>
            </div>

            <div className="border p-2 flex flex-col mt-2">
              <div className="flex items-center">
                <DataTable
                  editMode="cell"
                  selectionMode="single"
                  selection={selectedProduct1}
                  onSelectionChange={() => setSelectedProduct1(defaultState)}
                  size="small"
                  scrollHeight="10rem"
                  value={tarifa}
                  responsiveLayout="scroll"
                  contextMenuSelection={selectedProduct}
                  contextselection={selectedProduct}
                  onContextMenuSelectionChange={(e) =>
                    setSelectedProduct(defaultState)
                  }
                  onContextMenu={(e) => cm.current.show(e.originalEvent)}
                  onRowClick={(e) => handleRowClick(e.data)}
                >
                  {dynamicColumns}
                </DataTable>
              </div>
              <Toast ref={toast} />
            </div>
          </div>
          <span>
            <b>{numTarifa}</b>
          </span>
        </Dialog>
      </form>
    </div>
  );
};

export default Tarifat;
