import React from "react";
import mainAxios from "../../../../../services/axios";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useMyContext } from "../../../../../components/context/MyStatePOSContext";

const TableHandler = ({
  tableNumber,
  tavolinaObjects,
  operator,
  currentDate,
  currentDateAndTime,
  isOnline,
  showToast,
  setNewId,
  setNumTav,
  setDivs,
  tavolinaFundit,
  handleClose,
  setLoading,
  setError,
  setOpen,
  setDataOffline,
  divs,
  openedTable,
  setOpenedTable,
  tableData,
  isMobile,
  mbylleTav,
}) => {
  const {
    setCurrentAction,
    setLoadingLeftBar,
    setTableExist,
    setDtlState,
    setGjeneroFatureAnulluese,
    state,
    defaultState,
    setState,
  } = useMyContext();
  const divWidth = isMobile ? "125px" : "150px";
  const divHeight = isMobile ? "125px" : "150px";
  const spaceBetween = "5px";
  const handleButtonClick = async () => {
    setLoading(true);
    const tableExists = tavolinaObjects.some(
      (item) => item.Tavolina === tableNumber
    );

    const openedTableItem = tavolinaObjects.find(
      (item) => item.Tavolina === tableNumber
    );

    try {
      if (tableExists) {
        setCurrentAction("Modify");
        // setDtlState([]);
        setGjeneroFatureAnulluese(false);
        setTableExist(true);
        if (openedTableItem.Operator.toLowerCase() !== operator.toLowerCase()) {
          showToast("User ndryshe");
          setLoading(false);
          return;
        }
        const Id = openedTableItem.Id;
        setNewId(Id);
        try {
          setLoadingLeftBar(true);
          const response = await mainAxios(`/pos/${Id}`);
          const responseDtl = await mainAxios(`/posdtl/master/${Id}`);

          if (response.status === 200) {
            setState(response.data);
            setDtlState(responseDtl.data);
            // if (responseDtl.status === 200) {
            //   if (responseDtl.data.length === 0) {
            //     await mbylleTav("Ju lutem, provojeni serisht", "info");
            //   } else {

            //   }
            // }
          }
        } catch (err) {
          console.error(err);
          setDtlState([]);

          showToast("Ndodhi problem me marrjen e te dhenave", {
            severity: "error",
          });
        } finally {
          setLoading(false);
          setOpen(true);
          setLoadingLeftBar(false);
        }
      } else {
        const { Id, ...postDataWithoutId } = {
          ...defaultState,
          Tavolina: tableNumber,
        };

        // Store data offline
        // if (!isOnline) {
        //   const requestData = {
        //     url: "/pos",
        //     body: postDataWithoutId,
        //   };

        //   setDataOffline(requestData);
        //   showToast("Tavolina u ruajt për dërgesë pasi të keni internet");
        //   setDtlState([]);

        //   setNumTav(tableNumber);
        //   setOpen(true);
        //   setLoading(false);
        //   return;
        // }

        try {
          setCurrentAction("Add");
          setDtlState([]);
          setGjeneroFatureAnulluese(false);
          setTableExist(false);

          const response = await mainAxios.post("/pos", postDataWithoutId, {
            headers: { "Content-Type": "application/json" },
          });

          if (response.status === 200) {
            const newEntryID = response.data.Id;
            setNewId(newEntryID);
            setState(response.data);
          } else {
            setOpen(false);
            showToast("Ndodhi problem me hapjen e tavolines");
          }
        } catch (err) {
          showToast("Ndodhi problem me hapjen e tavolines");
          setError(true);
          console.error(err);
          setOpen(false);
          handleClose(true);
        } finally {
          setLoading(false);
        }
      }

      setNumTav(tableNumber);
      setOpenedTable((prevOpenedTable) => {
        if (prevOpenedTable) return [...prevOpenedTable, tableNumber];
      });

      const updatedDivs = divs.map((div) =>
        div.id === tableNumber ? { ...div, backgroundColor: "green" } : div
      );
      setDivs(updatedDivs);
      tavolinaFundit(tableNumber);
      setOpen(true);
    } catch (err) {
      console.error(err);
      showToast("Ndodhi problem me hapjen e tavolines", {
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      key={tableNumber}
      className="tavolinat"
      id={openedTable.includes(tableNumber) ? "tavolineHapur" : ""}
      style={{
        width: divWidth,
        height: divHeight,
        margin: spaceBetween,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
        position: "relative",
      }}
      onClick={handleButtonClick}
    >
      {tableNumber}
      {tableData?.Operator && (
        <div style={{ position: "relative" }}>
          <span
            style={{
              position: "absolute",
              top: -30,
              right: 30,
              fontSize: "16px",
              width: "30px",
              height: "30px",
              color: "#fff",
              textAlign: "center",
              backgroundColor: "#D92027",
              borderRadius: "5px",
              alignSelf: "self-start",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
          >
            {tableData.Operator}
          </span>
        </div>
      )}
      {tableData?.Vlera && (
        <div className="price_table">
          <span>
            <AttachMoneyIcon /> {tableData.Vlera}
          </span>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: divWidth,
          height: "30px",
          backgroundColor: tableData ? "#D92027" : "#B6F7C1",
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      />
    </div>
  );
};
export default TableHandler;
